<template>
  <FinancingGridLayout>
    <h3 :class="$style['grid-header']">
      {{ $t('spendPage.spend') }}
    </h3>
    <section :class="$style['grid-item-a']">
      <UseFundsPanel />
    </section>
    <section :class="$style['grid-item-b']">
      <h4>{{ $t('spendPage.recentTransactions') }}</h4>
      <SpendTransactionsPreview />
      <div>
        <UiButton
          size="small"
          :text="$t('spendPage.seeTransactions')"
          @click="seeAllTransactions"
        />
      </div>
    </section>
  </FinancingGridLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { useMeta } from 'vue-meta';
import { UiButton } from '@clearbanc/design-components/src/components';
import { WAYFINDING_ROUTES } from '@/data/wayfinding';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import UseFundsPanel from '@/components/UseFundsPanelFinancing';
import SpendTransactionsPreview from '@/components/SpendTransactionsPreview';
import FinancingGridLayout from '../grid-layout-financing';

export default {
  setup() {
    useMeta({
      title: 'Spend my funds | Clearco',
    });
  },
  components: {
    UseFundsPanel,
    SpendTransactionsPreview,
    FinancingGridLayout,
    UiButton,
  },
  data() {
    return {
      WAYFINDING_ROUTES,
    };
  },
  computed: {
    ...mapGetters(['userHasEverHadActiveAdvance', 'activeAdvances']),
    PAYMENTS_ROUTE_NAMES: () => PAYMENTS_ROUTE_NAMES,
  },
  async created() {
    await this.$store.dispatch('refreshAdvances');

    const { ADVANCES } = this.WAYFINDING_ROUTES;

    if (
      this.activeAdvances.length &&
      this.activeAdvances.every((a) => a.feesVersionId === 5)
    )
      this.$router.push(ADVANCES);
  },
  methods: {
    seeAllTransactions() {
      this.$router.push({ name: 'spend-transactions' });
    },
  },
};
</script>

<style lang="less" module>
.grid-banner {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 13;
  padding-bottom: @spacing-16;

  span[class*='p-message-icon'] {
    margin-top: 5px;
  }
}

.grid-header {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 13;
  padding-bottom: @spacing-16;
  padding-left: @spacing-24;
  font-family: @gerstner-font;
}

.grid-item-a {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
  margin-bottom: @spacing-32;
}

.grid-item-b {
  grid-column-start: 5;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 4;
  margin-bottom: @spacing-24;
  overflow-x: auto;

  & > h4 {
    font-family: @gerstner-font;
    padding-bottom: @spacing-22;
    padding-left: @spacing-24;
  }

  & > div:last-child {
    padding-top: @spacing-24;
    display: flex;
    justify-content: center;
  }
}

// tablet + mobile will have a stacked layout
@media only screen and (max-width: 1024px) {
  .grid-item-a {
    grid-column-end: 13;
  }

  .grid-item-b {
    grid-column-start: 1;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}
</style>
