<template>
  <LayoutForm>
    <VeeForm
      v-slot="{ setValues, values, validate, setFieldValue }"
      class="c-form"
      :validation-schema="schema"
      :initial-values="initialValues"
      data-cy="form"
      @submit="onSubmit"
    >
      <div class="c-group" data-cy="registeredAddress">
        <div v-if="showMailingAddressFields" class="c-group__header">
          <h3 class="c-group__header__title">
            {{
              $t('components.formRegisteredAddress.registeredAddressHeading')
            }}
          </h3>
        </div>
        <div class="c-form__row">
          <AutoCompleteAddress
            name="line1"
            data-cy="line1"
            :label="$t('common.address.companyAddress')"
            @addressSelected="
              addressSelect($event, setValues, values, validate, 'registered')
            "
            @blur="(e) => updateBusinessAddressParams(e.target.value, 'line1')"
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="line2"
            :label="$t('common.address.companyAddressLine2')"
            @blur="(e) => updateBusinessAddressParams(e.target.value, 'line2')"
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="city"
            :label="$t('common.address.city')"
            @blur="(e) => updateBusinessAddressParams(e.target.value, 'city')"
          />
        </div>
        <div
          v-if="stateOptionsForCountry(values.country?.value)"
          class="c-form__row"
        >
          <DropdownGroup
            name="state"
            :label="stateLabel(values.country)"
            :filter="true"
            :reset-filter-on-hide="true"
            option-label="nameString"
            :options="states(values.country?.value)"
            :placeholder="$t('common.selectOption')"
            append-to="self"
            @change="(e) => updateBusinessAddressParams(e.value.value, 'state')"
          />
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6">
            <DropdownGroup
              name="country"
              :label="$t('common.address.country')"
              :reset-filter-on-hide="true"
              :filter="true"
              option-label="nameString"
              :options="countries"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                ({ value }) => {
                  setValues({
                    ...values,
                    state: null,
                    postalCode: null,
                  });

                  updateBusinessAddressParams(value.value, 'country');

                  if (value.value === 'US') {
                    setFieldValue('marketingEmailsCheckbox', [
                      'subscribeToMarketingEmails',
                    ]);
                  } else {
                    setFieldValue('marketingEmailsCheckbox', []);
                  }
                }
              "
            />
          </div>
          <div class="col-span-6">
            <InputTextGroup
              v-show="
                values.country && isCountryWithPostalCode(values.country?.value)
              "
              name="postalCode"
              :label="postalCodeLabel(values.country?.value)"
              @blur="
                (e) => updateBusinessAddressParams(e.target.value, 'postalCode')
              "
            />
          </div>
        </div>
        <div class="c-form__row">
          <CheckboxGroup
            v-if="!initialSubscribedToMarketingEmails"
            :options="checkboxOptions"
            name="marketingEmailsCheckbox"
          />
        </div>
      </div>
      <div
        v-if="showMailingAddressFields"
        class="c-group"
        data-cy="mailingAddress"
      >
        <div class="c-group__header">
          <h3 class="c-group__header__title">
            {{ $t('components.formRegisteredAddress.mailingAddressHeading') }}
          </h3>
        </div>
        <div class="c-form__row">
          <CheckboxGroup
            name="shippingAddressSame"
            :options="[
              {
                value: 'shippingAddressSame',
                label: $t(
                  'components.formRegisteredAddress.sameAsRegisteredAddress',
                ),
                attributes: {
                  onChange: (e) => {
                    updateBusinessParams(
                      !!values.shippingAddressSame?.length,
                      'shippingAddressSame',
                    );

                    setValues({
                      ...values,
                      line1Shipping: null,
                      line2Shipping: null,
                      countryShipping: null,
                      stateShipping: null,
                      cityShipping: null,
                      postalCodeShipping: null,
                    });
                  },
                },
              },
            ]"
          />
        </div>
        <div class="c-form__row"></div>
        <template v-if="!values.shippingAddressSame?.length">
          <div class="c-form__row">
            <AutoCompleteAddress
              name="line1Shipping"
              data-cy="line1Shipping"
              :label="$t('common.address.companyAddress')"
              @addressSelected="
                addressSelect(
                  $event,
                  setValues,
                  values,
                  validate,
                  'shipping',
                  'Shipping',
                )
              "
              @blur="
                (e) =>
                  updateBusinessShippingAddressParams(e.target.value, 'line1')
              "
            />
          </div>
          <div class="c-form__row">
            <InputTextGroup
              name="line2Shipping"
              :label="$t('common.address.companyAddressLine2')"
              @blur="
                (e) =>
                  updateBusinessShippingAddressParams(e.target.value, 'line2')
              "
            />
          </div>
          <div class="c-form__row">
            <InputTextGroup
              name="cityShipping"
              :label="$t('common.address.city')"
              @blur="
                (e) =>
                  updateBusinessShippingAddressParams(e.target.value, 'city')
              "
            />
          </div>
          <div
            v-if="stateOptionsForCountry(values.countryShipping?.value)"
            class="c-form__row"
          >
            <DropdownGroup
              name="stateShipping"
              :label="stateLabel(values.countryShipping)"
              :filter="true"
              :reset-filter-on-hide="true"
              option-label="nameString"
              :options="states(values.countryShipping?.value)"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                (e) => {
                  updateBusinessShippingAddressParams(e.value.value, 'state');
                }
              "
            />
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <DropdownGroup
                name="countryShipping"
                :label="$t('common.address.country')"
                :reset-filter-on-hide="true"
                :filter="true"
                option-label="nameString"
                :options="countries"
                :placeholder="$t('common.selectOption')"
                append-to="self"
                @change="
                  ({ value }) => {
                    setValues({
                      ...values,
                      countryShipping: value,
                      stateShipping: null,
                      postalCodeShipping: null,
                    });

                    updateBusinessShippingAddressParams(value.value, 'country');
                  }
                "
              />
            </div>
            <div class="col-span-6">
              <InputTextGroup
                v-show="
                  values.countryShipping &&
                  isCountryWithPostalCode(values.countryShipping?.value)
                "
                name="postalCodeShipping"
                :label="postalCodeLabel(values.countryShipping?.value)"
                @blur="
                  (e) =>
                    updateBusinessShippingAddressParams(
                      e.target.value,
                      'postalCode',
                    )
                "
              />
            </div>
          </div>
        </template>
      </div>
      <div class="c-form__controls">
        <DSButton
          :label="$t('account.pages.pageVendors.goBack')"
          class="p-button-link mr-auto"
          :class="$style['back-button']"
          :disabled="formIsLoading"
          @click="$emit('goBack')"
        />
        <DSButton
          type="submit"
          label="Next"
          :loading="formIsLoading"
          :class="$style.button"
        />
      </div>
    </VeeForm>
  </LayoutForm>
</template>

<script>
import {
  isCountryWithPostalCode,
  countryOptionsWithPriority,
} from '@/data/country-code-with-names';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import {
  postalCodeLabel,
  stateLabel,
  stateOptionsForCountry,
  getPostalCodeRegexAccordingToCountry,
} from '@/utils/local';
import analytics from '@/utils/analytics';
import LayoutForm from '@/components/layouts/LayoutForm';

import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import AutoCompleteAddress from '@/components/AutoCompleteAddress';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    LayoutForm,
    VeeForm,
    InputTextGroup,
    AutoCompleteAddress,
    DropdownGroup,
    CheckboxGroup,
    DSButton,
  },
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    showMailingAddressFields: { type: Boolean, default: true },
  },
  data() {
    return {
      formIsLoading: false,
      initialValues: {},
      schema: object({
        line1: string().typeError('').required(this.$t('common.required')),
        line2: string().nullable(),
        city: string().required(this.$t('common.required')),
        state: object()
          .nullable()
          .when('country', (country, schema) => {
            if (country) {
              const countryHasStates = this.stateOptionsForCountry(
                country.value,
              );
              if (countryHasStates) {
                return schema.required(this.$t('common.required'));
              }
            }
            return schema;
          }),
        country: object().nullable().required(this.$t('common.required')),
        postalCode: string()
          .nullable()
          .when('country', (country, schema) => {
            const postalRegex = this.postalCodeRegexAccordingToCountry(
              country?.value,
            );

            if (country && postalRegex) {
              return schema
                .matches(
                  postalRegex,
                  this.$t('common.address.invalidPostalCode'),
                )
                .required(this.$t('common.required'));
            }

            if (this.isCountryWithPostalCode(country?.value)) {
              return schema.required(this.$t && this.$t('common.required'));
            }

            return schema;
          }),
        line1Shipping: string()
          .nullable()
          .when('shippingAddressSame', (shippingAddressSame, schema) => {
            if (!shippingAddressSame?.length) {
              return schema
                .typeError('')
                .required(this.$t && this.$t('common.required'));
            }

            return schema;
          }),
        cityShipping: string()
          .nullable()
          .when('shippingAddressSame', (shippingAddressSame, schema) => {
            if (!shippingAddressSame?.length) {
              return schema.required(this.$t && this.$t('common.required'));
            }

            return schema;
          }),
        stateShipping: object()
          .nullable()
          .when(
            ['shippingAddressSame', 'countryShipping'],
            (shippingAddressSame, countryShipping, schema) => {
              if (!shippingAddressSame?.length) {
                if (countryShipping) {
                  const countryHasStates = this.stateOptionsForCountry(
                    countryShipping.value,
                  );
                  if (countryHasStates) {
                    return schema.required(
                      this.$t && this.$t('common.required'),
                    );
                  }
                }
              }

              return schema;
            },
          ),
        countryShipping: object().when(
          'shippingAddressSame',
          (shippingAddressSame, schema) => {
            if (!shippingAddressSame?.length) {
              return schema
                .nullable()
                .required(this.$t && this.$t('common.required'));
            }

            return schema;
          },
        ),
        postalCodeShipping: string().when(
          'shippingAddressSame',
          (shippingAddressSame, schema) => {
            if (!shippingAddressSame?.length) {
              return string()
                .nullable()
                .when('countryShipping', (countryShipping, innerSchema) => {
                  const postalRegex = this.postalCodeRegexAccordingToCountry(
                    countryShipping?.value,
                  );

                  if (countryShipping && postalRegex) {
                    return innerSchema
                      .matches(
                        postalRegex,
                        this.$t('common.address.invalidPostalCode'),
                      )
                      .required(this.$t('common.required'));
                  }

                  if (this.isCountryWithPostalCode(countryShipping?.value)) {
                    return innerSchema.required(
                      this.$t && this.$t('common.required'),
                    );
                  }

                  return innerSchema;
                });
            }

            return schema;
          },
        ),
      }),
      checkboxOptions: [
        {
          label: 'Send me offer updates and helpful info',
          value: 'subscribeToMarketingEmails',
        },
      ],
      initialSubscribedToMarketingEmails: null,
    };
  },
  computed: {
    ...mapGetters(['business', 'user']),
    ...mapRequestStatuses({
      updateBusinessRequest: 'UPDATE_USER_BUSINESS',
    }),
    countries() {
      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      return countryOptionsWithPriority().map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });
    },
  },
  beforeMount() {
    let storedBusiness = localStorage.getItem('business');
    if (storedBusiness) {
      storedBusiness = JSON.parse(storedBusiness);
      this.$store.commit(
        'UPDATE_BUSINESS_ADDRESS_PARAMS',
        storedBusiness.address,
      );
      this.$store.commit(
        'UPDATE_BUSINESS_SHIPPING_ADDRESS_PARAMS',
        storedBusiness.shippingAddress,
      );
      this.$store.commit('UPDATE_BUSINESS_PARAMS', {
        shippingAddressSame: storedBusiness.shippingAddressSame,
      });
    } else if (!this.business?.shippingAddress?.country) {
      this.updateBusinessShippingAddressParams(
        this.business.country,
        'country',
      );
    }

    this.updateInitialValues(storedBusiness || this.business);

    this.initialSubscribedToMarketingEmails =
      this.user?.subscribedToMarketingEmails;
  },
  methods: {
    countryOptionsWithPriority,
    stateOptionsForCountry,
    isCountryWithPostalCode,
    postalCodeLabel,
    stateLabel,
    postalCodeRegexAccordingToCountry(country) {
      return getPostalCodeRegexAccordingToCountry(country);
    },
    updateBusinessParams(val, name) {
      if (val === undefined) return;

      this.$store.commit('UPDATE_BUSINESS_PARAMS', {
        [name]: val,
        type: 'ecom',
      });
      this.trackEvent('fe_mailing_address_different');
    },
    updateBusinessAddressParams(val, name) {
      if (val === undefined) return;

      this.$store.commit('UPDATE_BUSINESS_ADDRESS_PARAMS', {
        [name]: val,
      });
    },
    updateBusinessShippingAddressParams(val, name) {
      if (val === undefined) return;

      this.$store.commit('UPDATE_BUSINESS_SHIPPING_ADDRESS_PARAMS', {
        [name]: val,
      });
    },
    trackEvent(eventName) {
      analytics.track(eventName, {
        ...this.eventTrackingFields,
      });
    },
    async onSubmit(values) {
      this.formIsLoading = true;

      localStorage.removeItem('business', this.business);

      await this.$store.dispatchApiAction('UPDATE_USER_BUSINESS', {
        address: {
          line1: values.line1,
          line2: values.line2,
          country: values.country?.value,
          state: values.state?.value,
          city: values.city,
          postalCode: values.postalCode,
        },
        shippingAddress: {
          line1: values.line1Shipping,
          line2: values.line2Shipping,
          country: values.countryShipping?.value,
          state: values.stateShipping?.value,
          city: values.cityShipping,
          postalCode: values.postalCodeShipping,
        },
        shippingAddressSame: !!values.shippingAddressSame?.length,
      });

      if (!this.user?.subscribedToMarketingEmails) {
        await this.$store.dispatchApiAction('UPDATE_USER', {
          subscribedToMarketingEmails: values.marketingEmailsCheckbox?.includes(
            'subscribeToMarketingEmails',
          ),
        });
      }

      if (values.state) {
        analytics.identify(this.business.userId, {
          businessAddressState: values.state.value,
        });
      }

      if (this.updateBusinessRequest.isError) {
        this.$emit('validationFailure');
        this.formIsLoading = false;
        return;
      }

      this.$emit('validationSuccess');
    },
    addressSelect(
      autocomplete,
      setValues,
      values,
      validate,
      addressType,
      suffix = '',
    ) {
      const addressComponents = {};
      const obj = {
        [`line1${suffix}`]: null,
        [`line2${suffix}`]: null,
        [`country${suffix}`]: null,
        [`state${suffix}`]: null,
        [`city${suffix}`]: null,
        [`postalCode${suffix}`]: null,
      };

      // Convert the raw API data into something more useable
      // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
      autocomplete.address_components.forEach((component) => {
        addressComponents[component.types[0]] = {
          long_name: component.long_name,
          short_name: component.short_name,
        };
      });

      // Populate our form fields using the API data

      // Not all addresses from the Google API are complete and formatted equally
      // Although we can't cover them all, the combination of if statements below cover the most common variations
      if (addressComponents.street_number || addressComponents.route) {
        obj[`line1${suffix}`] = `${
          addressComponents.street_number?.long_name || ''
        } ${addressComponents.route?.long_name || ''}`;
      } else if (addressComponents.neighborhood?.long_name) {
        obj[`line1${suffix}`] = addressComponents.neighborhood?.long_name;
      } else if (addressComponents.sublocality?.long_name) {
        obj[`line1${suffix}`] = addressComponents.sublocality?.long_name;
      } else if (addressComponents.sublocality_level_1?.long_name) {
        obj[`line1${suffix}`] =
          addressComponents.sublocality_level_1?.long_name;
      } else if (addressComponents.locality?.long_name) {
        obj[`line1${suffix}`] = addressComponents.locality?.long_name;
      }

      if (addressComponents.subpremise) {
        obj[`line2${suffix}`] = `#${addressComponents.subpremise.long_name}`;
      }

      if (addressComponents.postal_town) {
        obj[`city${suffix}`] = addressComponents.postal_town.long_name;
      } else if (addressComponents.locality) {
        obj[`city${suffix}`] = addressComponents.locality.long_name;
      } else if (addressComponents.administrative_area_level_2) {
        obj[`city${suffix}`] =
          addressComponents.administrative_area_level_2.long_name;
      }

      if (addressComponents.administrative_area_level_1) {
        const states = this.states(addressComponents.country?.short_name);
        let selectedState;

        if (states) {
          selectedState = states.find((state) => {
            return (
              state.value ===
              addressComponents.administrative_area_level_1.short_name
            );
          });
        }

        obj[`state${suffix}`] =
          selectedState ||
          addressComponents.administrative_area_level_1.short_name;
      }

      if (addressComponents.country) {
        obj[`country${suffix}`] = this.countries.find(
          (country) => country.value === addressComponents.country.short_name,
        );
      }

      if (addressComponents.postal_code) {
        obj[`postalCode${suffix}`] = addressComponents.postal_code.long_name;
      }

      setValues({ ...values, ...obj });

      if (obj[`country${suffix}`].value === 'US') {
        setValues({
          ...values,
          marketingEmailsCheckbox: ['subscribeToMarketingEmails'],
        });
      } else {
        setValues({
          ...values,
          marketingEmailsCheckbox: [],
        });
      }

      if (addressType === 'registered') {
        for (const [name, val] of Object.entries(obj)) {
          this.updateBusinessAddressParams(val?.value || val, name);
        }
        this.trackEvent('fe_registered_address_capture');
      } else if (addressType === 'shipping') {
        for (const [name, val] of Object.entries(obj)) {
          if (val?.value || val) {
            this.updateBusinessShippingAddressParams(
              val?.value || val,
              name.replace('Shipping', ''),
            );
          }
        }
        this.trackEvent('fe_mailing_address_capture');
      }

      this.$nextTick(() => {
        validate();
      });
    },
    states(country) {
      const states = stateOptionsForCountry(country);

      if (!states) return null;

      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = states.map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
    updateInitialValues(payload) {
      if (payload.address?.country) {
        const presetCountry = this.countries.find(
          (country) => country.value === payload.address?.country,
        );
        if (presetCountry) {
          this.initialValues.country = presetCountry;
        }

        if (payload.address?.state) {
          const states = this.states(payload.address?.country);
          let presetState;
          if (states) {
            presetState = states.find(
              (state) => state.value === payload.address?.state,
            );
          }
          if (presetState) {
            this.initialValues.state = presetState;
          }
        }
      }

      if (payload.address?.line1) {
        this.initialValues.line1 = payload.address?.line1;
      }

      if (payload.address?.line2) {
        this.initialValues.line2 = payload.address?.line2;
      }

      if (payload.address?.city) {
        this.initialValues.city = payload.address?.city;
      }

      if (payload.address?.postalCode) {
        this.initialValues.postalCode = payload.address?.postalCode;
      }

      if (payload.shippingAddressSame) {
        this.initialValues.shippingAddressSame = ['shippingAddressSame'];
      } else {
        if (payload.shippingAddress?.country) {
          const presetCountry = this.countries.find(
            (country) => country.value === payload.shippingAddress?.country,
          );
          if (presetCountry) {
            this.initialValues.countryShipping = presetCountry;
          }

          if (payload.shippingAddress?.state) {
            const states = this.states(payload.shippingAddress?.country);
            let presetState;
            if (states) {
              presetState = states.find(
                (state) => state.value === payload.shippingAddress?.state,
              );
            }
            if (presetState) {
              this.initialValues.stateShipping = presetState;
            }
          }
        }

        if (payload.shippingAddress?.line1) {
          this.initialValues.line1Shipping = payload.shippingAddress?.line1;
        }

        if (payload.shippingAddress?.line2) {
          this.initialValues.line2Shipping = payload.shippingAddress?.line2;
        }

        if (payload.shippingAddress?.city) {
          this.initialValues.cityShipping = payload.shippingAddress?.city;
        }

        if (payload.shippingAddress?.postalCode) {
          this.initialValues.postalCodeShipping =
            payload.shippingAddress?.postalCode;
        }
      }

      if (payload.address?.country === 'US') {
        this.initialValues.marketingEmailsCheckbox = [
          'subscribeToMarketingEmails',
        ];
      }
    },
  },
};
</script>

<style module>
.button {
  min-width: 186px;
  justify-content: center;
}

button.back-button {
  padding-left: 0;
  transition: color 0.2s;
}

button.back-button:disabled {
  border: 0;
  background: none;
}
</style>
