import { keyBy } from 'lodash';
import { buildApiActions } from '@/utils/vuex-api-utils';
import { paymentsRequest } from '@/utils/payments-request';

export default {
  state: {
    contracts: [],
  },
  getters: {
    contracts: (state) => state.contracts,
    bankAccountContracts: (state) => {
      const bankAccountContracts = state.contracts.filter(
        (contract) => contract.type === 'ach',
      );
      return keyBy(bankAccountContracts, 'bankAccountsId') || {};
    },
    billContracts: (state) => {
      return state.contracts.filter(
        (contract) => contract.type === 'bnpl_bill',
      );
    },
    advanceContracts: (state) => {
      const advanceContracts = state.contracts.filter(
        (contract) => contract.type === 'advance',
      );
      return keyBy(advanceContracts, 'advanceId') || {};
    },
    isPrimaryBankAccountContractSigned: (
      state,
      getters,
      rootState,
      rootGetters,
    ) => {
      const bankAccountContracts = keyBy(
        state.contracts.filter((contract) => contract.type === 'ach'),
        'bankAccountsId',
      );
      const primaryBankAccount = rootGetters.primaryBankAccount;

      const combinedBankAccountAndBillContracts = state.contracts.filter(
        (contract) => contract.type === 'bnpl_bill',
      );

      const combinedContractForPrimaryBankAccount =
        combinedBankAccountAndBillContracts?.find(
          (contract) => contract.bankAccountsId === primaryBankAccount?.id,
        );

      return (
        !!primaryBankAccount &&
        (!!bankAccountContracts[primaryBankAccount.id]?.userSignedAt ||
          !!combinedContractForPrimaryBankAccount?.userSignedAt)
      );
    },
    isAdvanceContractSigned: (state, getters, rootState, rootGetters) => {
      const advanceContracts = keyBy(
        state.contracts.filter((contract) => contract.type === 'advance'),
        'advanceId',
      );

      const advance = rootGetters.advanceInNegotiation;
      return !!advance && !!advanceContracts[advance.id]?.userSignedAt;
    },
  },
  ...buildApiActions({
    FETCH_USER_CONTRACTS: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/contracts`,
        params: { businessId: ctx.rootGetters.businessId },
      }),
      mutation: (state, { response }) => {
        state.contracts = [...response.contracts];
      },
    },
    GET_DOCUSIGN_CONTRACT_DOWNLOAD: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/contracts/${payload.contractId}/download-url`,
        returnResponse: true,
      }),
      mutation: () => {},
    },
    REFRESH_BNPL_CONTRACT: {
      action: (ctx, payload) => ({
        method: 'patch',
        interceptor: paymentsRequest,
        url: `/bills/${payload.billId}/contract/refresh`,
        options: { timeout: 15000 },
        returnResponse: true,
      }),
      mutation: (state, { response }) => {
        const index = state.contracts.findIndex(
          (contract) => contract.id === response.billContract.id,
        );
        if (index < 0) {
          state.contracts.push(response.billContract);
        } else {
          state.contracts[index] = response.billContract;
        }
      },
    },
  }),
};
