// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file_pNJug{display:flex;align-items:center;font-size:18px;justify-content:space-between;margin:0 auto;max-width:350px}.file-text_n3CWt{cursor:pointer;text-decoration:underline}.file-text_n3CWt.blue-link_nNXfD{color:#0d3685}.file-text_n3CWt.closable-list_Z8m3L{text-align:left}.file-text_n3CWt.list-only_uZJwT{position:relative;z-index:1;margin:0 0 10px;text-align:right;max-width:290px}@media screen and (max-width:500px){.file-text_n3CWt.list-only_uZJwT{text-align:left}}.file-button_dbn52{cursor:pointer;width:18px;height:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": "file_pNJug",
	"file-text": "file-text_n3CWt",
	"blue-link": "blue-link_nNXfD",
	"closable-list": "closable-list_Z8m3L",
	"list-only": "list-only_uZJwT",
	"file-button": "file-button_dbn52"
};
export default ___CSS_LOADER_EXPORT___;
