import i18n from '@/plugins/i18n';
import { JobTitles, CountryCodes } from '@clearbanc/data-common-types';

// job titles for CA, US, GB
export const defaultJobTitles = [
  {
    value: JobTitles.C_SUITE,
    label: JobTitles.C_SUITE,
    name: () => i18n.t('data.ownerJobTitles.defaultJobTitles.founder'),
  },
  {
    value: JobTitles.VICE_PRESIDENT,
    label: JobTitles.VICE_PRESIDENT,
    name: () => i18n.t('data.ownerJobTitles.defaultJobTitles.vicePresident'),
  },
  {
    value: JobTitles.TREASURER,
    label: JobTitles.TREASURER,
    name: () => i18n.t('data.ownerJobTitles.defaultJobTitles.treasurer'),
  },
  {
    value: JobTitles.CONTROLLER,
    label: JobTitles.CONTROLLER,
    name: () => i18n.t('data.ownerJobTitles.defaultJobTitles.controller'),
  },
  {
    value: JobTitles.OFFICER,
    label: JobTitles.OFFICER,
    name: () => i18n.t('data.ownerJobTitles.defaultJobTitles.officer'),
  },
  {
    value: JobTitles.OTHER,
    label: JobTitles.OTHER,
    name: () => i18n.t('data.ownerJobTitles.defaultJobTitles.other'),
  },
];

// job titles for AU & SG
export const AUSGJobTitles = [
  {
    value: JobTitles.DIRECTOR,
    label: JobTitles.DIRECTOR,
    name: () => i18n.t('data.ownerJobTitles.ausgJobTitles.director'),
  },
  {
    value: JobTitles.SECRETARY,
    label: JobTitles.SECRETARY,
    name: () => i18n.t('data.ownerJobTitles.ausgJobTitles.secretary'),
  },
  {
    value: JobTitles.DIRECTOR_AND_SECRETARY,
    label: JobTitles.DIRECTOR_AND_SECRETARY,
    name: () =>
      i18n.t('data.ownerJobTitles.ausgJobTitles.directorAndSecretary'),
  },
  {
    value: JobTitles.OTHER,
    label: JobTitles.OTHER,
    name: () => i18n.t('data.ownerJobTitles.ausgJobTitles.other'),
  },
];

export const JobTitlesByCorpCountry = (countryCode) => {
  if ([CountryCodes.AU, CountryCodes.SG].includes(countryCode))
    return AUSGJobTitles;
  return defaultJobTitles;
};

export const EMPTY_OWNER = {
  id: '',
  email: '',
  phoneNumber: '',
  roles: [],
  jobTitle: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  },
  birthday: '',
  lastName: '',
  firstName: '',
  citizenship: '',
  agreedToKyc: false,
  personalTaxId: '',
  passportNumber: '',
  ownershipPercent: '',
};
