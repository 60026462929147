/*
  Helpers to deal with showing appropriate labels and options for selected
  country and state.
*/

import _ from 'lodash';
import provincesByCountry from '@/data/states-by-country';
import {
  PERSONAL_TAX_ID_CONFIG,
  BUSINESS_TAX_ID_CONFIG,
} from '@/data/tax-id-config';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import jurisdictionsByCountry from '@/data/jurisdictions-by-country';
import i18n from '@/plugins/i18n';

export const countriesWithStateOptions = Object.keys(provincesByCountry);
export function stateOptionsForCountry(countryCode) {
  if (!countryCode) return null;
  return provincesByCountry[countryCode];
}

export function jurisdictionsOptionsForCountry(countryCode) {
  if (!countryCode) return null;
  return jurisdictionsByCountry[countryCode];
}

export function postalCodeLabel(countryCode) {
  return (
    {
      AU: i18n.t('common.address.postalCodeLabel.postCode'),
      CA: i18n.t('common.address.postalCodeLabel.postalCode'),
      GB: i18n.t('common.address.postalCodeLabel.postCode'),
      NL: i18n.t('common.address.postalCodeLabel.postCode'),
      SG: i18n.t('common.address.postalCodeLabel.postCode'),
      US: i18n.t('common.address.postalCodeLabel.zipCode'),
      IE: i18n.t('common.address.postalCodeLabel.eirCode'),
    }[countryCode] || i18n.t('common.address.postalCodeLabel.postalCode')
  );
}

export const postalCodeRegexByCountry = {
  /* Not country specific since we use Object.values,
  for the future: https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b */
  AT: /^\d{4}$/,
  BE: /^\d{4}$/,
  CH: /^\d{4}$/,
  DE: /^\d{5}$/,
  DK: /^[1-9]{1}[0-9]{2,3}$/,
  US: /\d{5}([ -]\d{4})?$/,
  SE: /^(s-|S-){0,1}[0-9]{3}[0-9]{2}$/,
  CA: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
  GB: /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}$/i,
  AU: /^\d{4}$/,
  NL: /\d{4}[ ]?[A-Z]{2}$/i,
  SG: /^\d{6}$/,
  FI: /^\d{5}$/,
  IE: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i,
};

export function isValidPostalCode(postalCode, specialRegex) {
  if (specialRegex) return specialRegex.test(postalCode);
  let regexMatched = false;
  Object.values(postalCodeRegexByCountry).forEach((regex) => {
    regexMatched = regexMatched || regex.test(postalCode);
  });
  return regexMatched;
}

export function getPostalCodeRegexAccordingToCountry(country) {
  return country && postalCodeRegexByCountry.hasOwnProperty(country)
    ? postalCodeRegexByCountry[country]
    : null;
}

export function stateLabel(countryCode) {
  return (
    {
      AU: i18n.t('common.address.region'),
      CA: i18n.t('common.address.province'),
      GB: i18n.t('common.address.region'),
      NL: i18n.t('common.address.province'),
      SG: i18n.t('common.address.region'),
      US: i18n.t('common.address.state'),
      DE: i18n.t('common.address.region'),
      IE: i18n.t('common.address.county'),
      FI: i18n.t('common.address.region'),
    }[countryCode] || i18n.t('common.address.stateOrProvince')
  );
}

export function jurisdictionLabel(countryCode) {
  return (
    {
      AU: i18n.t('common.address.region'),
      CA: i18n.t('common.address.province'),
      GB: i18n.t('common.address.region'),
      NL: i18n.t('common.address.province'),
      SG: i18n.t('common.address.region'),
      US: i18n.t('common.address.state'),
      DE: i18n.t('common.address.jurisdiction'),
    }[countryCode] || i18n.t('common.address.stateOrProvince')
  );
}

export function cityLabel(countryCode) {
  return (
    {
      AU: i18n.t('common.address.locality'),
      CA: i18n.t('common.address.city'),
      GB: i18n.t('common.address.city'),
      NL: i18n.t('common.address.cityOrTown'),
      SG: i18n.t('common.address.cityOrTown'),
      US: i18n.t('common.address.city'),
      DE: i18n.t('common.address.cityOrTown'),
      IE: i18n.t('common.address.cityOrTown'),
      BE: i18n.t('common.address.cityOrTown'),
      AT: i18n.t('common.address.cityOrTown'),
      FI: i18n.t('common.address.cityOrTown'),
    }[countryCode] || i18n.t('common.address.city')
  );
}

export function stateLabelAdjective(countryCode) {
  return (
    {
      CA: i18n.t('common.address.provincial'),
      GB: i18n.t('common.address.regional'),
      SG: i18n.t('common.address.regional'),
      US: i18n.t('common.address.state'),
    }[countryCode] || i18n.t('common.address.provincial')
  );
}

export function stateTaxLabel(countryCode) {
  return (
    {
      CA: i18n.t('utils.local.provincialTaxId'),
      GB: i18n.t('utils.local.regionalTaxId'),
      SG: i18n.t('utils.local.regionalTaxId'),
      US: i18n.t('utils.local.stateTaxId'),
      DE: 'VAT ID (Umsatzsteuer-ID)',
      BE: 'VAT ID (TVA/BTW)',
      AT: 'VAT ID (Umsatzsteuer-ID)',
      FI: 'VAT ID',
      IE: 'VAT ID',
    }[countryCode] || i18n.t('utils.local.provincialTaxId')
  );
}

// Personal Tax ID (SSN, SIN, etc)
export function personalTaxIdLabel(countryCode) {
  return (
    _.get(PERSONAL_TAX_ID_CONFIG, `${countryCode}.label`)() ||
    i18n.t('utils.local.federalTaxId')
  );
}

export function businessNameLabel(countryCode) {
  return (
    _.get(BUSINESS_TAX_ID_CONFIG, `${countryCode}.businessNameLabel`)() ||
    i18n.t('common.registeredBusinessName')
  );
}

export function businessNameInstructions(countryCode) {
  return (
    _.get(
      BUSINESS_TAX_ID_CONFIG,
      `${countryCode}.businessNameInstructions`,
    )() || i18n.t('common.shouldMatchIncorporationDocuments')
  );
}

export function personalTaxIdLabelShort(countryCode) {
  return _.get(
    PERSONAL_TAX_ID_CONFIG,
    `${countryCode}.shortLabel`,
    'Fed. ID',
  )();
}
export function personalTaxIdRegex(countryCode) {
  return _.get(PERSONAL_TAX_ID_CONFIG, `${countryCode}.regex`);
}

// Business Federal Tax ID (EIN, BN, etc)
export function federalTaxIdLabel(countryCode) {
  return _.get(BUSINESS_TAX_ID_CONFIG, `${countryCode}.federalTaxIdLabel`);
}

export function federalTaxIdShortLabel(countryCode) {
  return _.get(BUSINESS_TAX_ID_CONFIG, `${countryCode}.shortLabel`);
}

export function registrationNumberLabel(countryCode) {
  return (
    _.get(BUSINESS_TAX_ID_CONFIG, `${countryCode}.registrationNumberLabel`)() ||
    i18n.t(
      'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.corporationNumber',
    )
  );
}

export function registrationNumberInstructions(countryCode) {
  return _.get(
    BUSINESS_TAX_ID_CONFIG,
    `${countryCode}.registrationNumberInstructions`,
    '',
  );
}
export function supportingDocumentsLabel(countryCode) {
  return _.get(
    BUSINESS_TAX_ID_CONFIG,
    `${countryCode}.supportingDocumentsLabel`,
    () => i18n.t('utils.local.incorporationDocuments'),
  );
}

export function corpTypesForCountry(countryCode) {
  return _.get(BUSINESS_TAX_ID_CONFIG, `${countryCode}.corpTypes`, {
    corp: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.corp'),
    sole: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.sole'),
    nonprofit: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.nonprofit'),
    other: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.other'),
  });
}

// Business State Tax Id (Delaware Corp Number, etc)
export const countriesWithTaxIdLabels = Object.keys(BUSINESS_TAX_ID_CONFIG);
export function stateTaxIdLabel(countryCode, stateCode) {
  return _.get(
    BUSINESS_TAX_ID_CONFIG,
    `${countryCode}.stateLabels.${stateCode}`,
  );
}

export function idRequirementsByCountry(countryCode) {
  switch (countryCode) {
    case COUNTRY_CODES.NL:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportLicenseIdentityCard',
      );
    case COUNTRY_CODES.DE:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportLicenseIdentityCard',
      );
    case COUNTRY_CODES.AT:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportLicenseIdentityCard',
      );
    case COUNTRY_CODES.BE:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportIdentityCard',
      );
    case COUNTRY_CODES.FI:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportLicenseIdentityCard',
      );
    case COUNTRY_CODES.IE:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportLicenseIdentityCard',
      );
    default:
      return i18n.t(
        'utils.local.idRequirementsByCountry.uploadCopyPassportLicense',
      );
  }
}

export function normalizeStateCode(countryCode, state) {
  if (countryCode && state) {
    const provice = provincesByCountry[countryCode]?.find(
      ({ name }) => name() === state,
    );
    if (provice) {
      return provice.value;
    }
  }
  return state;
}
