// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".business-details_XQe1j div[class^=input-container],.business-details_XQe1j div[class^=wrapper]{max-width:none}.business-details_XQe1j div[class^=menu],.business-details_XQe1j input,.business-details_XQe1j input:focus,.business-details_XQe1j textarea{border:none;box-shadow:none!important;background-color:#f2f2f2}.business-details_XQe1j label[class^=form-input-label]{font-weight:400;font-size:10px;line-height:10px;letter-spacing:1px;text-transform:uppercase}.form-group_o5sUC{padding:30px 10px 0}.form-group_o5sUC legend{margin:0;padding:0}.experiments-button_x7olU{min-width:200px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"business-details": "business-details_XQe1j",
	"form-group": "form-group_o5sUC",
	"experiments-button": "experiments-button_x7olU"
};
export default ___CSS_LOADER_EXPORT___;
