import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import i18n from '@/plugins/i18n';
import analytics from '@/utils/analytics';

export const WAYFINDING_ROUTES = {
  // To update routes in #78077
  SPEND: 'spend',
  ADVANCES: 'advances',
  CARD: 'clearco-card',
  VENDORS: 'pay-vendors',
  VENDOR_LIST: PAYMENTS_ROUTE_NAMES.VENDOR_LIST,
  PAYMENTS: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE,
  UPCOMING_REPAYMENTS: PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS,
  PAYMENTS_BIZ_PROFILE_DASHBOARD: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
  ACCOUNT_SETTINGS: 'account-settings',
  ACCOUNT_CONNECTIONS: 'account-connections',
  BUSINESS_DETAILS: 'business-details',
  DOCUMENT_UPLOADS: 'document-uploads',
  DATA_PRIVACY: 'data-security',
  LOGOUT: 'logout',
  BANKING: 'banking',
  VERIFY_REVENUE: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT,
  ALL_DOCUMENTS: PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS,
};
export const WAYFINDING_MENU_KEYS = {
  FINANCING: 'financing',
  ACCOUNT: 'account',
  CARD: 'card',
  VENDORS: 'vendors',
  PAYMENTS: 'payments',
  BANKING: 'banking',
  HOME: 'home',
  REVENUE_SOURCES: 'revenueSources',
  ALL_DOCUMENTS: 'allDocuments',
};
export const WAYFINDING_MENU_TEMPLATE = {
  [WAYFINDING_MENU_KEYS.ACCOUNT]: {
    title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.myAccount'),
    route: '',
    children: [
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.accountSettings'),
        route: WAYFINDING_ROUTES.ACCOUNT_SETTINGS,
      },
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.accountConnections'),
        route: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      },
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.businessDetails'),
        route: WAYFINDING_ROUTES.BUSINESS_DETAILS,
      },
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.documentUploads'),
        route: WAYFINDING_ROUTES.DOCUMENT_UPLOADS,
      },
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.dataPrivacy'),
        route: WAYFINDING_ROUTES.DATA_PRIVACY,
      },
      {
        title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.signOut'),
        route: WAYFINDING_ROUTES.LOGOUT,
      },
    ],
  },
  [WAYFINDING_MENU_KEYS.HOME]: {
    title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.home'),
    route: WAYFINDING_ROUTES.DASHBOARD,
  },
  // To update routes in #78077
  [WAYFINDING_MENU_KEYS.FINANCING]: {
    title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.financing'),
    route: WAYFINDING_ROUTES.SPEND,
    forceDropdown: true,
    children: [
      {
        title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.spend'),
        route: WAYFINDING_ROUTES.SPEND,
      },
      {
        title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.advances'),
        route: WAYFINDING_ROUTES.ADVANCES,
      },
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.businessProfile'),
        route: WAYFINDING_ROUTES.PAYMENTS_BIZ_PROFILE_DASHBOARD,
      },
    ],
  },
  [WAYFINDING_MENU_KEYS.BANKING]: {
    title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.banking'),
    subtitle: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.newProduct'),
    route: WAYFINDING_ROUTES.BANKING,
  },
  [WAYFINDING_MENU_KEYS.PAYMENTS]: {
    title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.paymentsBeta'),
    route: WAYFINDING_ROUTES.PAYMENTS,
    forceClickable: true,
    forceDropdown: true,
    children: [
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.repaymentSchedule'),
        route: WAYFINDING_ROUTES.UPCOMING_REPAYMENTS,
        matchForChildren: true,
      },
      {
        title: () =>
          i18n.t('data.wayfinding.wayfindingMenuTemplate.businessProfile'),
        route: WAYFINDING_ROUTES.PAYMENTS_BIZ_PROFILE_DASHBOARD,
        matchForChildren: true,
        faIcon: 'exclamation-triangle',
      },
    ],
  },
  [WAYFINDING_MENU_KEYS.REVENUE_SOURCES]: {
    title: () => 'Revenue Sources',
    route: WAYFINDING_ROUTES.VERIFY_REVENUE,
    forceClickable: true,
    children: [],
  },
  [WAYFINDING_MENU_KEYS.ALL_DOCUMENTS]: {
    title: () => 'Documents',
    route: WAYFINDING_ROUTES.ALL_DOCUMENTS,
    forceClickable: true,
    children: [],
  },
};

export const WAYFINDING_QUICK_LINKS = {
  [WAYFINDING_MENU_KEYS.CARD]: {
    segmentEventName: 'fe_clearco_card_clicked',
    title: () => i18n.t('spendPage.clearcoCard'),
    smallTitle: () => i18n.t('spendPage.clearcoCard'),
    route: WAYFINDING_ROUTES.CARD,
    icon: 'card-thin',
  },
  [WAYFINDING_MENU_KEYS.VENDORS]: {
    segmentEventName: 'fe_pay_vendor_clicked',
    title: () => i18n.t('data.wayfinding.wayfindingMenuTemplate.payAVendor'),
    smallTitle: () =>
      i18n.t('data.wayfinding.wayfindingMenuTemplate.vendorPay'),
    route: WAYFINDING_ROUTES.VENDOR_LIST,
    icon: 'invoice-thin',
  },
};

export const trackImprintClick = (route) => {
  analytics.track('fe_imprint_clicked', {
    path: route.name,
    url: route.fullPath,
  });
};
