import { EMPTY_OWNER } from '@/data/owner';

export default {
  state: {
    owners: [],
    selectedOwner: null,
  },
  getters: {
    selectedOwner: (state) => state.selectedOwner,
    selectedOwnerFullName: (state, rootGetters) => {
      const firstName = rootGetters.selectedOwner?.firstName;
      const lastName = rootGetters.selectedOwner?.lastName;
      return firstName?.concat(' ', lastName);
    },
  },
  actions: {
    UPDATE_OWNER_PARAMS: ({ commit }, params) => {
      commit('UPDATE_OWNER_PARAMS', params);
    },
    UPDATE_OWNER_ADDRESS_PARAMS: ({ commit }, params) => {
      commit('UPDATE_OWNER_ADDRESS_PARAMS', params);
    },
    SET_SELECTED_OWNER: ({ state, commit, rootGetters }, ownerId) => {
      const owner = Number(ownerId)
        ? rootGetters.user
        : (rootGetters.business.owners || []).find(
            (businessOwner) => businessOwner.id === ownerId,
          );
      commit('SET_SELECTED_OWNER', owner || { ...EMPTY_OWNER, id: ownerId });
    },
    UNSELECT_OWNER: ({ commit }) => {
      commit('UNSELECT_OWNER');
    },
  },
  mutations: {
    UPDATE_OWNER_PARAMS: (state, params) => {
      state.selectedOwner = { ...state.selectedOwner, ...params };
    },
    UPDATE_OWNER_ADDRESS_PARAMS: (state, params) => {
      state.selectedOwner.address = {
        ...state.selectedOwner.address,
        ...params,
      };
    },
    SET_SELECTED_OWNER: (state, owner) => {
      state.selectedOwner = owner;
    },
    UNSELECT_OWNER: (state) => {
      state.selectedOwner = null;
    },
  },
};
