<template>
  <div>
    <h1 :class="$style.title">{{ selectedOwnerFullName }}</h1>
    <Box :class="$style['input-container']">
      <div>
        <div :class="$style['top-section']">
          <div>
            <Checkbox
              :name="OWNER_ROLES.OWNER"
              :model-value="isOwner"
              :class="$style['input-fields']"
              @input="addOrRemoveOwnerRole"
            >
              {{ $t('common.owner') }}
            </Checkbox>
            <Checkbox
              :name="OWNER_ROLES.DIRECTOR"
              :model-value="isDirector"
              :class="$style['input-fields']"
              @input="addOrRemoveOwnerRole"
            >
              {{ $t('components.formOwnersStepTwo.directorBoardMember') }}
            </Checkbox>
          </div>
          <DownloadableFileList
            :class="$style['file-list']"
            :files="userUploads"
            :is-closable="false"
            shorten-filenames
            @trackUploadedDocClicked="handleDownloadFile"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            name="email"
            type="email"
            :model-value="!!selectedOwner ? selectedOwner.email : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.email')"
            :label="$t('common.email')"
            required
            :disabled="isPrimary"
            :validate-on-blur="showError"
            :error-msg="emailErrorMsg"
            :error="checkForEmailError() && showError"
            @input="updateOwnerParams"
          />
          <InputWithEyebrow
            name="phoneNumber"
            type="phone"
            :model-value="!!selectedOwner ? selectedOwner.phoneNumber : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.phoneNumber')"
            :label="$t('common.phoneNumber')"
            required
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.phoneNumber && showError"
            :disabled="isOnboardingCheckboxDisabled"
            @input="updateOwnerParams"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <DropdownWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.jobTitle : ''"
            :options="jobTitleOptions"
            :placeholder="$t('common.jobTitle')"
            name="jobTitle"
            required
            :class="sideInputClasses(true)"
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.jobTitle && showError"
            @input="updateOwnerParams"
          />
          <InputWithEyebrow
            name="ownershipPercent"
            :model-value="!!selectedOwner ? selectedOwner.ownershipPercent : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.ownership')"
            :label="$t('common.ownership')"
            :error-msg="ownershipPercentErrorMsg"
            :error="ownershipPercentError && showError"
            @input="updateOwnerParams"
          />
        </div>
        <Autocomplete
          id="auto"
          :model-value="selectedOwner.address.line1"
          name="line1"
          required
          :validate-on-blur="showError"
          :label="$t('common.address.homeAddressLine1')"
          :placeholder="$t('common.address.homeAddressLine1')"
          :class="[$style['input-fields'], $style['address']]"
          :error="!selectedOwner.address.line1 && showError"
          :eyebrow="$t('common.address.homeAddressLine1')"
          @address-selected="updateAddress"
          @onBlur="saveManualAddressEntry"
        />
        <InputWithEyebrow
          :model-value="!!selectedOwner ? selectedOwner.address.line2 : ''"
          name="line2"
          type="text"
          autofill="new-address"
          :class="[$style['input-fields'], $style['address']]"
          :placeholder="$t('common.address.homeAddressLine2')"
          :label="$t('common.address.homeAddressLine2')"
          @input="updateOwnerAddressParams"
        />
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.address.city : ''"
            name="city"
            type="text"
            autofill="new-address"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.address.city')"
            :label="$t('common.address.city')"
            required
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.address.city && showError"
            @input="updateOwnerAddressParams"
          />
          <DropdownWithEyebrow
            v-if="
              !!stateOptionsForCountry(selectedOwner.address.country) &&
              !isMobile()
            "
            :model-value="!!selectedOwner ? selectedOwner.address.state : ''"
            :options="
              stateOptionsForCountry(
                selectedOwner.address.country || COUNTRY_CODES.CA,
              )
            "
            name="state"
            required
            :class="sideInputClasses(true)"
            :validate-on-blur="showError"
            :error="
              !!selectedOwner && !selectedOwner.address.state && showError
            "
            :placeholder="stateLabel(selectedOwner.address.country)"
            @input="updateOwnerAddressParams"
          />
        </div>
        <div :class="sideContainerClasses(false)">
          <DropdownWithEyebrow
            v-if="
              !!stateOptionsForCountry(selectedOwner.address.country) &&
              isMobile()
            "
            :model-value="!!selectedOwner ? selectedOwner.address.state : ''"
            :options="
              stateOptionsForCountry(
                selectedOwner.address.country || COUNTRY_CODES.CA,
              )
            "
            name="state"
            required
            :class="sideInputClasses(false)"
            :validate-on-blur="showError"
            :error="
              !!selectedOwner && !selectedOwner.address.state && showError
            "
            :placeholder="stateLabel(selectedOwner.address.country)"
            @input="updateOwnerAddressParams"
          />
          <InputWithEyebrow
            v-if="
              isCountryWithPostalCode(selectedOwner.address.country) &&
              isMobile()
            "
            :model-value="
              !!selectedOwner ? selectedOwner.address.postalCode : ''
            "
            autofill="new-address"
            name="postalCode"
            type="postalCode"
            :label="postalCodeLabel(selectedOwner.address.country)"
            required
            :validate-on-blur="showError"
            :special-number-regex="postalCodeRegexAccordingToCountry"
            :error="
              !!selectedOwner && !selectedOwner.address.postalCode && showError
            "
            :placeholder="postalCodeLabel(selectedOwner.address.country)"
            :class="sideInputClasses(false)"
            @input="updateOwnerAddressParams"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            v-if="
              isCountryWithPostalCode(selectedOwner.address.country) &&
              !isMobile()
            "
            :model-value="
              !!selectedOwner ? selectedOwner.address.postalCode : ''
            "
            autofill="new-address"
            name="postalCode"
            type="postalCode"
            :label="postalCodeLabel(selectedOwner.address.country)"
            required
            :validate-on-blur="showError"
            :special-number-regex="postalCodeRegexAccordingToCountry"
            :error="
              !!selectedOwner && !selectedOwner.address.postalCode && showError
            "
            :placeholder="postalCodeLabel(selectedOwner.address.country)"
            :class="sideInputClasses(true)"
            @input="updateOwnerAddressParams"
          />
          <DropdownWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.address.country : ''"
            name="country"
            autofill="new-address"
            :placeholder="$t('common.address.country')"
            :label="$t('common.address.country')"
            required
            :class="sideInputClasses(true)"
            :options="countryOptionsWithPriority()"
            :validate-on-blur="showError"
            :error="
              !!selectedOwner && !selectedOwner.address.country && showError
            "
            @input="updateOwnerAddressParams"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <DropdownWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.citizenship : ''"
            name="citizenship"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.address.countryOfCitizenship')"
            :label="$t('common.address.countryOfCitizenship')"
            required
            :options="
              countryOptionsWithPriority([
                COUNTRY_CODES.US,
                COUNTRY_CODES.CA,
                COUNTRY_CODES.GB,
              ])
            "
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.citizenship && showError"
            @input="updateOwnerParams"
          />
          <InputWithEyebrow
            name="birthday"
            type="date"
            :model-value="!!selectedOwner ? selectedOwner.birthday : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.dateOfBirth')"
            :label="$t('common.dateOfBirth')"
            :eyebrow="$t('common.dateOfBirth')"
            required
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.birthday && showError"
            @input="updateOwnerParams"
          />
        </div>
        <InputWithEyebrow
          v-if="!!personalTaxIdCountry"
          name="personalTaxId"
          :model-value="!!selectedOwner ? selectedOwner.personalTaxId : ''"
          type="text"
          required
          :label="personalTaxIdLabelShort(personalTaxIdCountry)"
          :placeholder="personalTaxIdLabelShort(personalTaxIdCountry)"
          :validate-on-blur="personalTaxIdError || showError"
          :error="
            ((!!selectedOwner && !selectedOwner.personalTaxId) ||
              personalTaxIdError) &&
            showError
          "
          :error-msg="
            $t('common.invalidId', {
              type: personalTaxIdLabelShort(personalTaxIdCountry),
            })
          "
          :tooltip-msg="ssnTooltipMsg"
          :class="$style['input-fields']"
          @openTooltip="trackTooltipClick('open')"
          @closeTooltip="trackTooltipClick('close')"
          @input="updateOwnerParams"
          @onBlur="trackInputUpdate('SSN')"
        />
        <InputWithEyebrow
          v-if="!personalTaxIdCountry"
          name="passportNumber"
          placeholder="Passport Number"
          :model-value="!!selectedOwner ? selectedOwner.passportNumber : ''"
          :error="!!selectedOwner && !selectedOwner.passportNumber && showError"
          :validate-on-blur="showError"
          type="text"
          required
          label="Passport Number"
          :class="$style['input-fields']"
          @input="updateOwnerParams"
          @onBlur="trackInputUpdate('passportNumber')"
        />
      </div>
      <Checkbox
        v-if="canSelectedOwnerBeSignatory"
        :name="OWNER_ROLES.SIGNATORY"
        :model-value="isSignatory"
        :class="$style['input-fields']"
        :disabled="isOnboardingCheckboxDisabled"
        @input="addOrRemoveOwnerRole"
      >
        {{ $t('common.authorizedSignatory') }}
      </Checkbox>
      <error-message v-if="errorMessage" :class="$style['error-container']">
        <template #message>
          <span>
            {{ errorMessage }}
            <span v-if="showErrorSupportContact">
              Please contact us at
              <a href="mailto:support@clear.co" target="_blank"
                >support@clear.co</a
              >.
            </span>
          </span>
        </template>
      </error-message>
      <slot name="cta" :class="$style['button-container']" />
    </Box>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { isMobileMixin } from '@/utils/vue-mixins';
import analytics from '@/utils/analytics';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import {
  countryOptionsWithPriority,
  isCountryWithPostalCode,
} from '@/data/country-code-with-names';
import {
  ENTITY_TYPE,
  ID_TYPE,
  OWNER_ROLES,
  isAuthorizedOwner,
} from '@clearbanc/data-common-types';
import { JobTitlesByCorpCountry } from '@/data/owner';
import {
  personalTaxIdLabel,
  personalTaxIdRegex,
  personalTaxIdLabelShort,
  postalCodeLabel,
  stateLabel,
  stateOptionsForCountry,
  getPostalCodeRegexAccordingToCountry,
} from '@/utils/local';
import {
  CONTACT_SUPPORT_MSG,
  shouldDisplayContactSupport,
  getDisplayedErrorMessage,
} from '@/data/error-messages';
import { validateChildren } from '@/composables/validation';
import DropdownWithEyebrow from '@/components/DropdownWithEyebrow';
import Autocomplete from '@/components/inputs/InputAutocompleteAddress';
import { DownloadableFileList } from '@/components';
import Checkbox from '@/components/Checkbox';
import Box from '@/components/Box';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';

export default {
  components: {
    DownloadableFileList,
    Checkbox,
    InputWithEyebrow,
    DropdownWithEyebrow,
    Autocomplete,
    Box,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      errorMessage: '',
      showError: false,
      COUNTRY_CODES,
      OWNER_ROLES,
      ssnTooltipMsg: this.$t('components.formOwnersStepTwo.ssnTooltipMsg'),
      emailErrorMsg: '',
    };
  },
  computed: {
    CONTACT_SUPPORT_MSG() {
      return CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    ...mapGetters([
      'selectedOwner',
      'user',
      'business',
      'advanceInNegotiation',
      'advanceContracts',
      'business',
      'productSegmentLabel',
      'uploads',
      'selectedOwnerFullName',
      'contactDetails',
      'isDiligenceServiceReadDataEnabled',
      'allOwners',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: ['UPDATE_USER_BUSINESS'],
      updateUserRequest: 'UPDATE_USER',
    }),
    currentRoute() {
      return this.$route.name;
    },
    requiredDropdownFieldsComplete() {
      const stateIsRequired = stateOptionsForCountry(
        this.selectedOwner.address.country || COUNTRY_CODES.CA,
      );
      const isJobTitleValid = !!this.selectedOwner?.jobTitle;
      const isCountryValid = !!this.selectedOwner?.address?.country;
      const isCitizenshipValid = !!this.selectedOwner?.citizenship;

      let isStateValid = true;
      if (stateIsRequired) {
        isStateValid = !!this.selectedOwner?.address?.state;
      }

      return (
        isJobTitleValid && isCountryValid && isCitizenshipValid && isStateValid
      );
    },
    personalTaxIdError() {
      if (!this.selectedOwner?.personalTaxId) {
        return false;
      }
      return (
        this.selectedOwner?.personalTaxId?.length &&
        !RegExp(this.personalTaxIdRegex(this.personalTaxIdCountry)).test(
          this.selectedOwner?.personalTaxId,
        )
      );
    },
    postalCodeRegexAccordingToCountry() {
      return getPostalCodeRegexAccordingToCountry(
        this.selectedOwner?.address?.country,
      );
    },
    ownershipPercentError() {
      const ownershipPercent = this.selectedOwner?.ownershipPercent;
      if (!ownershipPercent) {
        return false;
      }
      return !(
        typeof Number(ownershipPercent) === 'number' &&
        Number(ownershipPercent) >= 0 &&
        Number(ownershipPercent) <= 100
      );
    },
    ownershipPercentErrorMsg() {
      return this.ownershipPercentError
        ? this.$t('components.formOwnersStepTwo.ownershipPercentErrorMsg')
        : '';
    },
    isPrimary() {
      return this.selectedOwner?.id === this.user.id;
    },
    jobTitleOptions() {
      return JobTitlesByCorpCountry(this.business.corpCountry);
    },
    uploadFilters() {
      return this.isDiligenceServiceReadDataEnabled
        ? {
            externalId: `${this.selectedOwner?.id}`,
            externalIdType: this.isPrimary
              ? ENTITY_TYPE.USER
              : ENTITY_TYPE.OWNER,
            documentType: [ID_TYPE.ID, ID_TYPE.PASSPORT],
          }
        : {
            metaId: `${this.selectedOwner?.id}`,
            entity: this.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
            type: 'passport_or_license',
          };
    },
    userUploads() {
      return this.$store.getters.uploadsMatching(this.uploadFilters);
    },
    advanceContractUserSigned() {
      return this.advanceContracts[this.advanceInNegotiation.id]?.userSignedAt;
    },
    personalTaxIdCountry() {
      const { citizenship } = this.selectedOwner;
      if (
        [COUNTRY_CODES.US, COUNTRY_CODES.CA, COUNTRY_CODES.GB].includes(
          citizenship,
        )
      ) {
        return citizenship;
      }
      return null;
    },
    isSignatory() {
      return this.selectedOwner?.jobRoles?.includes(OWNER_ROLES.SIGNATORY);
    },
    isDirector() {
      return this.selectedOwner?.jobRoles?.includes(OWNER_ROLES.DIRECTOR);
    },
    isOwner() {
      return this.selectedOwner?.jobRoles?.includes(OWNER_ROLES.OWNER);
    },
    canSelectedOwnerBeSignatory() {
      const { jobTitle, jobRoles } = this.selectedOwner ?? {};
      if (!jobTitle) {
        return false;
      }
      return isAuthorizedOwner(jobTitle, jobRoles);
    },
    selectedOwnerJobTitle() {
      return this.selectedOwner?.jobTitle;
    },
    isRoleSelected() {
      return this.isOwner || this.isDirector;
    },
    isOnboardingCheckboxDisabled() {
      return this.isPrimary && !!this.advanceContractUserSigned;
    },
    showErrorSupportContact() {
      return shouldDisplayContactSupport(
        this.updateUserRequest.error?.errorCode,
      );
    },
  },
  watch: {
    canSelectedOwnerBeSignatory(canBeSignatory) {
      if (!canBeSignatory) {
        this.addOrRemoveOwnerRole(false, OWNER_ROLES.SIGNATORY);
      }
    },
  },
  methods: {
    isCountryWithPostalCode,
    countryOptionsWithPriority,
    personalTaxIdLabelShort,
    personalTaxIdRegex,
    personalTaxIdLabel,
    postalCodeLabel,
    stateOptionsForCountry,
    stateLabel,
    sideContainerClasses(wrapOnMobile) {
      return {
        [this.$style['side-container']]: true,
        [this.$style.wrap]: wrapOnMobile,
      };
    },
    sideInputClasses(wrapOnMobile) {
      return {
        [this.$style['side-inputs']]: true,
        [this.$style['wrap-inputs']]: wrapOnMobile,
      };
    },
    addOrRemoveOwnerRole(addRole, role) {
      let ownerRoles = [...(this.selectedOwner?.jobRoles ?? [])];
      if (addRole) {
        ownerRoles.push(role);
      } else {
        ownerRoles = ownerRoles.filter((existingRole) => existingRole !== role);
      }
      this.updateOwnerParams(ownerRoles, 'jobRoles');
    },
    updateOwnerParams(val, name) {
      // if user changes citizenship to country other than US, CA, then reset
      // personalTaxId since it is only required for NA
      if (name === 'citizenship') {
        if (![COUNTRY_CODES.US, COUNTRY_CODES.CA].includes(val)) {
          this.updateOwnerParams(null, 'personalTaxId');
        }
      }
      if (name === 'ownershipPercent') {
        this.$store.dispatch('UPDATE_OWNER_PARAMS', {
          [name]:
            val?.charAt(val.length - 1) === '%'
              ? Number(val.substring(0, val.length - 1))
              : Number(val),
        });
        return;
      }
      this.$store.dispatch('UPDATE_OWNER_PARAMS', { [name]: val });
    },
    updateOwnerAddressParams(val, name) {
      this.$store.dispatch('UPDATE_OWNER_ADDRESS_PARAMS', { [name]: val });
    },
    updateAddress(id, autoAddress) {
      for (const [name, val] of Object.entries(autoAddress)) {
        this.updateOwnerAddressParams(val, name);
      }
    },
    saveManualAddressEntry(id, result) {
      this.trackInputUpdate('home_address');
      this.updateOwnerAddressParams(result.value, 'line1');
    },
    async validateForm() {
      this.showError = true;
      const error = await this.hasError();
      if (
        error ||
        this.ownershipPercentError ||
        this.personalTaxIdError ||
        !this.requiredDropdownFieldsComplete
      ) {
        return;
      }
      window.scrollTo(0, 0);

      this.isPrimary
        ? await this.saveUser(this.selectedOwner)
        : await this.saveBusiness(this.selectedOwner);

      this.trackEvent('self_serve_stage_complete', {
        businessId: this.business.id,
        userId: this.user.id,
        stage: this.currentRoute,
      });
    },
    async saveUser(owner) {
      await this.$store.dispatchApiAction('UPDATE_USER', owner);
      if (!this.updateUserRequest.isSuccess) {
        const { message } = this.updateUserRequest.error;
        this.errorMessage = getDisplayedErrorMessage(
          this.updateUserRequest.error,
        );
        this.trackEvent('self_serve_error', {
          errorMessage: message,
        });
        return;
      }
      this.errorMessage = undefined;
      this.$emit('validationSuccess');
    },
    async saveBusiness(owner) {
      const { owners } = this.business;
      const isNewOwner = !owners.some(
        (existingOwner) => existingOwner.id === owner.id,
      );
      const updatedOwners = isNewOwner
        ? owners.concat(owner)
        : owners.reduce(
            (acc, val) => acc.concat(val.id === owner.id ? owner : val),
            [],
          );
      await this.$store.dispatchApiAction('UPDATE_USER_BUSINESS', {
        owners: updatedOwners,
      });

      if (this.updateBusinessRequest.error) {
        this.errorMessage = this.updateBusinessRequest.error.message;
        this.trackEvent('self_serve_error', {
          errorMessage: this.errorMessage,
        });
        return;
      }
      this.$emit('validationSuccess');
    },
    handleDownloadFile() {
      this.trackEvent('uploaded_doc_clicked');
    },
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.eventTrackingFields, ...props });
    },
    trackInputUpdate(name) {
      this.trackEvent(`${name}_capture`, {
        businessId: this.business.id,
        userId: this.user.id,
      });
    },
    trackTooltipClick(type) {
      this.trackEvent('tooltip_click', {
        content: 'ssn/sin',
        type,
      });
    },
    checkForEmailError() {
      const missingEmail = !this.selectedOwner?.email;

      let businessHasOwnerWithSameEmail = false;

      if (!missingEmail) {
        businessHasOwnerWithSameEmail = this.allOwners.some(
          (owner) =>
            owner.email === this.selectedOwner.email &&
            owner.id !== this.selectedOwner.id,
        );
      }

      this.emailErrorMsg = businessHasOwnerWithSameEmail
        ? 'This email is already in use.'
        : '';

      return missingEmail || businessHasOwnerWithSameEmail;
    },
  },
};
</script>

<style lang="less" module>
.title {
  margin-bottom: @gutter-10;
  font-family: 'Montserrat';
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.top-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
}
.input-container {
  width: 650px;
  @media screen and (max-width: 700px) {
    width: unset;
  }
  .file-list {
    margin-bottom: 10px;
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .input-fields {
    margin-bottom: 20px;
    max-height: 40px;
  }

  .address {
    margin-bottom: 30px;
  }

  .side-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.wrap {
      @media screen and (max-width: 500px) {
        flex-flow: row wrap;
      }
    }
    .side-inputs {
      height: 40px;
      min-height: unset;
      margin-bottom: 30px;
      &:nth-child(2) {
        margin-left: 15px;
        &.wrap-inputs {
          @media screen and (max-width: 500px) {
            margin-left: unset;
          }
        }
      }
    }
  }
}
.button-container {
  margin-top: 30px;
}

.error-container {
  margin-bottom: 10px;
  text-align: left;
}
</style>
