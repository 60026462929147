<template>
  <div>
    <p>This window will automatically close.</p>
  </div>
</template>

<script>
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';

export default {
  computed: {
    ...mapGetters(['isSelfServeUser']),
    ...mapRequestStatuses({
      addExternalAccountsRequest: 'ADD_USER_EXTERNAL_ACCOUNT',
    }),
  },
  async beforeCreate() {
    const platform = this.$route.params.platform;
    await this.$store.dispatchApiAction('ADD_USER_EXTERNAL_ACCOUNT', {
      type: 'codat',
      companyId: this.$route.params.companyId,
      platform,
    });
    let data;
    if (this.addExternalAccountsRequest.isError) {
      data = {
        type: 'CodatPopupMessage',
        errorMessage: this.addExternalAccountsRequest.errorMessage,
        platform,
      };
    }
    if (this.addExternalAccountsRequest.isSuccess) {
      if (this.isSelfServeUser) {
        analytics.track('fe_account_connected');
      }
      data = {
        type: 'CodatPopupMessage',
        platform,
      };
    }

    window.opener.postMessage(data, window.location.origin);
    window.close();
  },
};
</script>
