<template>
  <div class="advance-details">
    <h4 class="name" data-cy="total-advance-details">
      {{ title }} {{ $t('advancesPage.advanceNum', { advanceId: advance.id }) }}
      {{
        $filters.currency(advance.amountCents, false, {
          symbol: '',
          precision: 0,
        })
      }}
      {{ advance.currencyCode }}
    </h4>
    <div v-if="isVentures" class="details">
      <span>
        @
        <b>{{ $filters.percent(advance.repaymentRate) }}</b>
        {{ $t('advancesPage.repaymentRate') }}
      </span>
    </div>
    <div v-else class="details">
      <span data-cy="flat-fee">
        {{ $t('advancesPage.for') }}
        {{ $filters.currency(advance.feeCents, advance.currencyCode, false) }}
        {{ $t('advancesPage.fee') }}
      </span>
      <span
        v-if="advance.topUpAmountCents"
        class="italic"
        data-cy="previous-outstanding"
      >
        {{ $t('advancesPage.prevAmountOutstanding') }}
        {{ $filters.currency(advance.topUpAmountCents, advance.currencyCode) }})
      </span>
      <span data-cy="remittance-rate"
        >@ {{ $filters.percent(advance.repaymentRate) }}
        {{ $t('advancesPage.remittanceRate') }}
      </span>
    </div>
    <hr />
    <div class="bar-labels">
      <div class="bar-label" data-cy="activated-at-label">
        <h6>{{ $t('advancesPage.activated') }}</h6>
        <h5>{{ $filters.date(advance.activatedAt) }}</h5>
        <router-link
          v-if="isVentures"
          data-cy="ventures-payment-details-link"
          :to="{ name: 'payments', query: { advanceId: advance.id } }"
          ><b>{{ $t('advancesPage.viewPaymentDetails') }}</b></router-link
        >
      </div>
    </div>
    <template v-if="!isIndiegogo">
      <progress-bar
        v-if="!isVentures"
        data-cy="progress-bar-component"
        :success="Number(advance.chargeStatsCents.success)"
        :pending="Number(advance.chargeStatsCents.pending)"
        :failed="Number(advance.chargeStatsCents.failed)"
        :topup="Number(advance.topUpAmountCents)"
        :cashback="Number(advance.cashbackTotalCents)"
        :currency-code="advance.currencyCode"
        :total="Number(advance.totalCostCents)"
        @openCashBackDetails="openCashBackDetails(advance.id)"
      />
    </template>
    <div
      v-if="!isVentures && !isIndiegogo"
      ref="barLabelsWithStats"
      class="bar-labels"
    >
      <div
        ref="chargeStatsLabel"
        class="bar-label"
        data-cy="advance-amount-paid"
      >
        <h5>
          {{
            $filters.currency(
              advance.chargeStatsCents.success,
              advance.currencyCode,
            )
          }}
          <router-link
            data-cy="not-ventures-indiegogo-payment-details-link"
            :to="{ name: 'payments', query: { advanceId: advance.id } }"
            >{{ $t('advancesPage.viewPaymentDetails') }}</router-link
          >
        </h5>
        <h6>{{ $t('advancesPage.amountPaid') }}</h6>
      </div>
      <div
        ref="chargeBalanceLabel"
        class="bar-label"
        data-cy="advance-amount-outstanding"
      >
        <h5>
          {{
            $filters.currency(advance.chargeBalanceCents, advance.currencyCode)
          }}
        </h5>
        <h6 v-if="!isVentures">{{ $t('advancesPage.amountOutstanding') }}</h6>
      </div>
    </div>
    <cashback-transactions-popup ref="cashbackDetails" />
  </div>
</template>

<script>
import { ADVANCE_SUBTYPES } from '@/data/advance-subtypes';

const components = {
  'progress-bar': require('@/components/progress/ProgressBar').default,
  'cashback-transactions-popup':
    require('@/components/popups/PopupCashbackTransactions').default,
};

export default {
  components,
  props: {
    advance: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isVentures() {
      return this.advance.subtype === ADVANCE_SUBTYPES.VENTURES;
    },
    isIndiegogo() {
      return this.advance.feesVersionId === 9;
    },
    title() {
      if (this.advance.subtype === ADVANCE_SUBTYPES.BNPL)
        return this.$t('advancesPage.bnplAgreement');
      if (this.advance.subtype === ADVANCE_SUBTYPES.INVENTORY)
        return this.$t('advancesPage.inventory');
      if (this.isVentures) return this.$t('advancesPage.ventures');
      if (this.advance.subtype === ADVANCE_SUBTYPES.MARKETING_FACEBOOK)
        return this.$t('advancesPage.facebook');
      return this.$t('advancesPage.marketing');
    },
  },
  methods: {
    openCashBackDetails(advanceId) {
      this.$refs.cashbackDetails.open(advanceId);
    },
  },
};
</script>

<style lang="less">
.advance-details {
  border: 2px solid #ddd;
  padding: 24px;
  background: white;

  h6 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    color: @color-grey-400;
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: @dark-black;
  }

  a {
    text-transform: none;
  }

  .name {
    font-weight: bold;
  }

  .details {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: @color-grey-400;
    margin: 8px 0;
  }

  hr {
    border: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
  }

  .bar-labels {
    display: flex;
    padding: 7px 0;

    .bar-label {
      flex: 1 0 0;
      text-align: right;
    }

    .bar-label:first-child {
      text-align: left;
    }
  }

  .amounts {
    font-weight: bold;
    padding: 5px;
  }

  .paid-total {
    float: left;
  }

  .outstanding-balance,
  .total {
    float: right;
  }
}
</style>
