<template>
  <div>
    <plaid-link
      ref="plaidlink"
      client-name="Clearco"
      :plaid-env="PLAID_ENV"
      @widgetLoaded="$emit('plaidOpened')"
      @success="handlePlaidLinkSuccess"
      @error="handleConnectionError"
      @exit="handlePlaidLinkExit"
      @flowEvent="handleFlowEvent"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { plaidOAuthMixin } from '@/utils/vue-mixins';

import analytics from '@/utils/analytics';
import PlaidLink from '@/components/LinkPlaid';

export default {
  components: {
    PlaidLink,
  },
  mixins: [plaidOAuthMixin],
  data() {
    return {
      havingTrouble: 0,
      bankConnectionReconnecting: null,
    };
  },
  computed: {
    ...mapGetters([
      'isPlaidConnectSupportedCountry',
      'onboardingExperimentName',
      'bankAccounts',
    ]),
    ...mapRequestStatuses({
      addBankConnectionRequest: 'ADD_USER_BANK_CONNECTION',
      reconnectBankConnectionRequest: 'RECONNECT_USER_BANK_CONNECTION',
    }),
    PLAID_ENV: () => process.env.PLAID_ENV,
  },
  watch: {
    havingTrouble() {
      analytics.track('fe_bank_link_trouble_clicked', {
        severity: this.havingTrouble,
      });
      if (this.havingTrouble === 2) {
        this.$emit('troubleConnecting');
      }
    },
  },
  methods: {
    openBankIntegration(lastPageBeforePlaidOAuth) {
      if (this.isPlaidConnectSupportedCountry) {
        analytics.track('fe_bank_link_initiated');

        const plaidLink = this.$refs.plaidlink;
        this.openPlaidIntegration(lastPageBeforePlaidOAuth, plaidLink);
      }
    },
    reconnectAccount(lastPageBeforePlaidOAuth, bankAccount) {
      const errorPopup = this.$refs?.plaidErrorPopup; // close in case open
      if (errorPopup) {
        errorPopup.close();
      }
      const plaidLink = this.$refs.plaidlink;

      this.openPlaidIntegration(
        lastPageBeforePlaidOAuth,
        plaidLink,
        bankAccount.bankConnectionId,
      );
    },
    async handlePlaidLinkSuccess({ token, metadata }) {
      if (this.bankConnectionReconnecting !== null) {
        // plaid reconnect was successful, tell the API
        await this.$store.dispatchApiAction('RECONNECT_USER_BANK_CONNECTION', {
          type: 'plaid',
          bankConnectionId: this.bankConnectionReconnecting,
        });
      } else {
        // plaid was successful, now send new token to the API
        await this.$store.dispatchApiAction('ADD_USER_BANK_CONNECTION', {
          type: 'plaid',
          plaidToken: token,
          metadata, // not currently used
        });
      }
      this.clearPlaidOAuthData();
      if (
        this.reconnectBankConnectionRequest.isSuccess ||
        this.addBankConnectionRequest.isSuccess
      ) {
        this.$emit('success');
        return;
      }
      this.$emit('error', { isPlaidLinkError: false });
    },
    handleConnectionError(metadata) {
      analytics.track('fe_bank_link_failed', {
        fail_type: 'client',
        ...(metadata && {
          bank: metadata.institution_name,
          institution_id: metadata.institution_id,
          link_session_id: metadata.link_session_id,
          plaid_error: metadata.error_code,
          plaid_request_id: metadata.request_id,
        }),
      });
      this.clearPlaidOAuthData();
      this.$emit('error', metadata);
    },
    handlePlaidLinkExit(metadata) {
      this.$emit('exit', metadata);
      this.clearPlaidOAuthData();
    },
    handleFlowEvent(event) {
      this.$emit('flowEvent', event);
    },
    restoreBankAccount(bankAccountId) {
      this.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
        id: bankAccountId,
        action: 'restore',
      });
    },
  },
};
</script>
