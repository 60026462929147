// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_WRYEy{max-width:460px;margin:55px auto 0;font-family:Montserrat;color:#2c3e50}.button_OmJFb{min-width:186px}@media (min-width:500px){.form_WRYEy{margin-top:30px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_WRYEy",
	"button": "button_OmJFb"
};
export default ___CSS_LOADER_EXPORT___;
