<template>
  <div data-cy="upload-invoice-modal">
    <div :class="$style.heading">
      <p>
        {{
          isReceiptFlow
            ? $t(
                'account.pages.pageVendors.uploadInvoiceModal.receiptDescription',
              )
            : $t(
                'account.pages.pageVendors.uploadInvoiceModal.invoiceDescription',
              )
        }}
      </p>
      <p>
        {{
          $t(
            'account.pages.pageVendors.uploadInvoiceModal.submitMultipleBills',
            {
              type: fileType,
            },
          )
        }}
        {{
          $t('account.pages.pageVendors.uploadInvoiceModal.billHelpParagraph', {
            type: fileType,
          })
        }}
        <a :class="$style.link" :href="helpLink" target="_blank">
          {{
            $t(
              'account.pages.pageVendors.uploadInvoiceModal.billRequirements',
              {
                type: fileType,
              },
            )
          }}
        </a>
      </p>
    </div>
    <VeeForm
      ref="form"
      class="c-form"
      :class="$style.form"
      :validation-schema="schema"
      :initial-values="{
        fileUpload: fileUploaded,
      }"
      @submit="onSubmit"
    >
      <div class="c-form__row">
        <Field v-slot="{ errors }" v-model="fileUploaded" name="fileUpload">
          <Box :class="$style.uploadWrapper">
            <div>
              <p
                v-if="!fileUploaded"
                :class="$style['uploadWrapper__file-types']"
              >
                {{
                  $t(
                    'account.pages.pageVendors.uploadInvoiceModal.acceptedFileTypes',
                  )
                }}
              </p>
              <div>
                <UploadFileWidget
                  v-if="!fileUploaded"
                  name="fileUpload"
                  :empty-prompt="`\n ${$t(
                    'account.pages.pageVendors.uploadInvoiceModal.dragAndDrop',
                  )}`"
                  :upload-filters="uploadFilters"
                  :display-filters="displayFilters"
                  no-list
                  single-upload
                  theme="onboarding"
                  @complete="invoiceUploaded"
                  @uploadAttempt="uploadAttempt"
                />
                <div
                  v-if="currentUpload.id && fileUploaded"
                  :class="$style['uploaded-files']"
                >
                  <a
                    :href="currentUpload.downloadUrl"
                    :class="$style['uploaded-files__item']"
                    >{{ currentUpload.name }}</a
                  >
                  <svg
                    :class="$style.icon"
                    @click="removeDocs(currentUpload.id)"
                    @keydown="removeDocs(currentUpload.id)"
                    v-html="require('@/assets/icons/close-icon.svg')"
                  ></svg>
                </div>
              </div>
            </div>
          </Box>
          <p v-if="errors" :class="$style.error" class="p-error">
            {{ errors[0] }}
          </p>
        </Field>
      </div>
      <div class="c-form__controls">
        <DSButton
          :label="$t('account.pages.pageVendors.goBack')"
          class="p-button-link"
          :class="$style['back-button']"
          @click="goBack"
        />
        <DSButton :disabled="!fileUploaded" type="submit">{{
          $t('account.pages.pageVendors.nextStep')
        }}</DSButton>
      </div>
    </VeeForm>
  </div>
</template>

<script>
import { Form as VeeForm, Field } from 'vee-validate';
import { object, boolean } from 'yup';
import { validateChildren } from '@/composables/validation';
import { mapGetters } from 'vuex';
import { ENTITY_TYPE, PAYMENTS_DOC_TYPE } from '@clearbanc/data-common-types';
import Box from '@/components/Box';
import analytics from '@/utils/analytics';
import { CLEARPAY_SEGMENT_EVENTS } from '@/data/payments';
import DSButton from '@clearbanc/clear-components/button';
import addLocaleParam from '@/utils/add-locale-param';

export default {
  components: {
    Box,
    VeeForm,
    Field,
    DSButton,
  },
  props: {
    fileUploaded: {
      type: Boolean,
      required: false,
    },
    businessId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      schema: object({
        fileUpload: boolean().isTrue('Please upload a document'),
      }),
    };
  },
  computed: {
    ...mapGetters([
      'currentUpload',
      'isReceiptFlow',
      'isDiligenceServiceReadDataEnabled',
      'isProcessDocumentOcrEnabled',
    ]),
    helpLink() {
      return this.isReceiptFlow
        ? 'https://help.clear.co/s/article/How-to-submit-a-receipt'
        : 'https://help.clear.co/s/article/How-to-submit-an-invoice';
    },
    fileType() {
      return this.isReceiptFlow
        ? PAYMENTS_DOC_TYPE.RECEIPT
        : PAYMENTS_DOC_TYPE.INVOICE;
    },
    uploadFilters() {
      return {
        entity: ENTITY_TYPE.PAYMENTS,
        type: this.fileType,
        businessId: this.businessId,
      };
    },
    displayFilters() {
      return this.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: ENTITY_TYPE.BILL,
            documentType: this.fileType,
            businessId: this.businessId,
          }
        : {
            entity: ENTITY_TYPE.PAYMENTS,
            type: this.fileType,
            businessId: this.businessId,
          };
    },
  },
  methods: {
    addLocaleParam,
    invoiceUploaded(uploadId) {
      const hasUploadId = !!uploadId;
      if (hasUploadId) {
        this.getFileUpload(uploadId);
        this.$emit('checkFileUploaded', uploadId);
      }
    },
    async getFileUpload(uploadId) {
      const uploadedFile = await this.$store.dispatchApiAction(
        'GET_USER_UPLOAD_DETAILS',
        {
          id: uploadId,
          download: true,
          withProcessedData: this.isProcessDocumentOcrEnabled,
        },
      );
      this.$emit('getUploadedFile', uploadedFile);
    },
    uploadAttempt() {
      analytics.track(CLEARPAY_SEGMENT_EVENTS.STARTED_BILL_UPLOAD);
    },
    async removeDocs(id) {
      this.$emit('removeFile');
      await this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
    },
    goBack() {
      this.$emit('prev-tab');
    },
    async onSubmit(values) {
      if (this.isReceiptFlow) analytics.track('fe_add_receipt_next_clicked');
      this.$emit('updateFile');
      this.$emit('next-tab');
    },
  },
};
</script>

<style lang="less" module>
.heading {
  margin-bottom: 2rem;
  p {
    margin: 0;
  }
}

.form {
  text-align: left;
  padding: 0;
}

.uploadWrapper {
  margin: 0;
  width: 100%;
  padding: 2.5rem;
}

.uploadWrapper__file-types {
  margin-bottom: 0.5rem;
}

.uploaded-files {
  display: flex;
  align-items: center;
}

.uploaded-files__item {
  margin-right: 0.75rem;
}

.link {
  color: #145bce;
}

.error {
  margin: 0.5rem 0 0;
  font-weight: 400;
}

button.back-button {
  margin-right: auto;
  padding: 0;
}

.uploadWrapper {
  margin: 0;
  width: 100%;
  padding: 2.5rem;
}

.uploadWrapper__file-types {
  margin-bottom: 0.5rem;
}

.uploaded-files {
  display: flex;
  align-items: center;
}

.uploaded-files__item {
  margin-right: 0.75rem;
}

.error {
  margin-top: 0.5rem;
  font-weight: 400;
}

.icon {
  height: 12px;
  width: 12px;
  cursor: pointer;
}
</style>
