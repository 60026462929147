/* Place one instance of this component on the page where the user triggers
oauth login to start and another on a page that is used as the Oauth redirect
url. ie: https://example.com/oauth-callback On the callback page component, pass
the querystring (as an object) to the `callback-payload` param. Assuming you are
using vue-router, it would look like: (using pug)
`oauth-connect(:callback-payload='this.$route.query')` */

<template>
  <div />
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/browser';
import { REDIRECT_HANDLER_CONSTANTS } from '@/data/redirect-handler-constants';

const ua = navigator.userAgent.toLowerCase().replace(/\s+/, '');
const isBrowserIosWebview =
  ua.match(/ip(hone|od|ad)/) &&
  (ua.indexOf('safari') === -1 || ua.indexOf('crios') > 0);

// see https://www.w3schools.com/jsref/met_win_open.asp
const defaultPopupSpecs = {
  location: 0,
  status: 0,
  width: 650,
  height: 700,
};

function buildSpecString(options) {
  const specs = [];
  Object.keys(options).forEach((key) => {
    if (options[key] !== undefined) {
      specs.push(`${key}=${options[key]}`);
    }
  });
  return specs.join(',');
}

export default {
  props: {
    popupWindowName: { type: String, default: 'ConnectWithOAuth' },
    providerConfig: { type: Object },
    callbackPayload: { type: Object },
    isListenerInstance: { type: Boolean, default: true },
  },
  data() {
    return {
      activeProvider: '',
    };
  },
  computed: {
    ...mapGetters(['businessId']),
    popupWindowSpec() {
      return buildSpecString({
        ...defaultPopupSpecs,
        // overrides
      });
    },
  },
  created() {
    if (this.isListenerInstance) {
      window.addEventListener('message', this.receiveMessage);
    }
  },
  unmounted() {
    if (this.isListenerInstance) {
      window.removeEventListener('message', this.receiveMessage);
    }
  },
  mounted() {
    // on the oauth callback page, this component should receive the query
    // string params as the `callbackPayload` param. If it is present, try to
    // pass this back to the parent window
    if (this.callbackPayload) {
      // TODO: handle ios webview scenario using localstorage
      if (isBrowserIosWebview) {
        // eslint-disable-next-line no-console
        console.warn('Oauth login is not yet supported in a webview.');
        // if provider exists in localStorage, then treat as a no-popup execution
      } else if (localStorage.getItem('oauth-provider')) {
        this.oauthCallbackHandler(this.callbackPayload);
        // try to locate the parent window
      } else if (window.opener) {
        const data = {
          type: REDIRECT_HANDLER_CONSTANTS.oauthCallback,
          message: this.callbackPayload,
        };

        if (!this.isListenerInstance) {
          window.opener.postMessage(data, window.location.origin);
        }

        window.close();
      } else {
        // eslint-disable-next-line no-alert
        alert('Something went wrong - please contact support');
      }
    }
  },
  methods: {
    launch(provider, overrides = {}) {
      const config = this.providerConfig[provider];
      if (!config) {
        throw new Error(`Missing oauth config for provider - ${provider}`);
      }

      let url = overrides.authorizeUri
        ? overrides.authorizeUri
        : config.authorizeUri;
      url += url.indexOf('?') > 0 ? '&' : '?';
      url += `client_id=${config.clientId}`;

      // TODO: some don't need this
      // some should be optional
      url += '&response_type=code';

      const wl = window.location;
      if (config.redirectUri === true) {
        this.redirectUri = `${wl.protocol}//${wl.host}${wl.pathname}`;
        url += '&redirect_uri=';
      } else if (config.redirectUri) {
        if (config.redirectUri.indexOf('http') === 0) {
          this.redirectUri = `${config.redirectUri}`;
        } else {
          this.redirectUri = `${wl.protocol}//${wl.host}${config.redirectUri}`;
        }
      } else {
        this.redirectUri = null;
      }

      if (this.redirectUri) {
        url += `&redirect_uri=${this.redirectUri}`;
      }

      if (config.scope) {
        url += `&scope=${config.scope}`;
      } else if (config.scopes) {
        url += `&scope=${config.scopes.join(',')}`;
      }

      if (config.sub) {
        url += `&sub=${config.sub}`;
      }

      if (config.prompt) {
        url += `&prompt=${config.prompt}`;
      }

      if (config.access_type) {
        url += `&access_type=${config.access_type}`;
      }

      if (config.grantOptions) {
        config.grant_options.forEach((o) => {
          url += `&grant_options[]=${o}`;
        });
      }

      if (config.useStateNone !== false) {
        this.nonce = `CB${Math.random() * +new Date()}`;
        url += `&state=${this.nonce}`;
        if (overrides.extras) {
          url += `,${overrides.extras}`;
        }
      }

      if (provider === 'bigcommerce') {
        url = `https://login.bigcommerce.com/app/${config.clientId}/install`;
      }
      if (provider === 'woocommerce') {
        url = this.woocommerceUrl(
          overrides.authorizeUri,
          this.redirectUri,
          overrides.storeUrl,
        );
      }

      if (provider === 'amazon' || provider === 'amzvendor') {
        // when running locally you *must* use ngrok (host is whitelisted by amazon, must be clearbanc.ngrok.io)
        this.redirectUri = `${wl.protocol}//${wl.host}${config.redirectUri}`;
        url = `${overrides.authorizeUri}&application_id=${config.appId}&redirect_uri=${this.redirectUri}`;
      }

      this.activeProvider = provider;

      this.$emit('redirect', this.activeProvider);

      // make callback globally accessible so that popup window can call it
      // if overrides.noPopup exists then treat as a no-popup execution
      if (overrides.noPopup) {
        // fire Sentry error if oauth-provider does not exist in localStorage before redirecting
        if (localStorage.getItem('oauth-provider') === null) {
          const scope = new Sentry.Scope();
          scope.setTag('provider', provider);
          Sentry.captureException(
            new Error(
              'User redirected without oauth-provider being set in localStorage',
            ),
            scope,
          );
        }
        window.location.assign(url);
      } else {
        // TODO: handle ios webview scenario using a redirect rather than popup
        if (isBrowserIosWebview) {
          // eslint-disable-next-line no-console
          console.warn('Oauth login is not yet supported in a webview.');
        }
        const popupWidth = this.providerConfig[provider]?.largePopup
          ? window.outerWidth - window.outerWidth * 0.2
          : 650;
        // wrap to prevent pop up being blocked on safari
        setTimeout(() => {
          this.popupWindow = window.open(
            url,
            this.popupWindowName,
            `width=${popupWidth},height=700,location=1`,
          );
        });

        if (!this.checkWindowInterval) {
          this.checkWindowInterval = setInterval(() => {
            if (
              !this.popupWindow ||
              this.popupWindow.closed ||
              this.popupWindow.closed === undefined
            ) {
              this.popupClosedHandler();
            }
          }, 1000);
        }
      }
    },
    receiveMessage(event) {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data?.type === REDIRECT_HANDLER_CONSTANTS.oauthCallback) {
        this.oauthCallbackHandler(event.data.message);
      }
    },
    popupClosedHandler() {
      if (this.checkWindowInterval) {
        clearInterval(this.checkWindowInterval);
        this.checkWindowInterval = null;
      }
      if (!this.expectClose) {
        this.$emit('error', {
          provider: this.activeProvider,
          error: 'window_closed',
        });
      }
      this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
      this.expectClose = false;
    },
    oauthCallbackHandler(payload) {
      payload.provider = this.activeProvider || payload.provider;
      payload.redirectUri = this.redirectUri;
      this.expectClose = true;

      // this checks specifically for amazon-spapi response code
      if (payload.spapi_oauth_code) {
        payload.code = payload.spapi_oauth_code;
        payload.state = `${payload.state}, ${payload.selling_partner_id}`;
      }

      // TODO: check payload.state === nonce if option is activated
      // if provider exists in localStorage, then treat it as a no-popup execution
      const provider = localStorage.getItem('oauth-provider');
      if (provider) {
        // provider name is needed to validate the Oauth token received
        payload.provider = provider;
        // redirect route is where we redirect to after the Oauth work is finished
        const redirectRoute = localStorage.getItem('oauth-redirect-route');
        this.$store.dispatch('SET_OAUTH_PAYLOAD', payload);
        this.$router.push({
          path: redirectRoute,
        });
        return;
      }
      if (payload.error) {
        // payload.error will contain an error code like "access_denied"
        this.$emit('error', {
          provider: payload.provider,
          error: payload.error,
        });
      } else if (payload.code || payload.id) {
        // payload.code will contain the oauth code
        this.$emit('code', payload);
      } else if (payload.success && payload.user_id) {
        // From WooCommerce
        this.$emit('connectPayment', payload);
      }
    },
    /**
     * Returns the final url for Woo Commerce authentication
     * @param authorizeUri
     * @param redirectUri
     * @param storeUrl
     * @returns {string}
     */
    woocommerceUrl(authorizeUri, redirectUri, storeUrl) {
      const params = {
        app_name: 'Clearbanc',
        scope: 'read',
        user_id: `${this.businessId} ${storeUrl}`,
        return_url: redirectUri,
        callback_url: `${
          process.env.NODE_ENV === 'development'
            ? process.env.NGROK_DOMAIN
            : process.env.NODE_API_URL
        }/oauth/woocommerce`,
      };

      const queryParams = new URLSearchParams(params);
      const queryString = queryParams.toString().replace(/%20/g, '+');

      return `${authorizeUri}?${queryString}`;
    },
  },
};
</script>
