import {
  BANK_ACCOUNT_DOC_TYPE,
  ENTITY_TYPE,
  BUSINESS_DOC_TYPE,
  CONTRACTS_DOC_TYPE,
} from '@clearbanc/data-common-types';

export const DOCUMENT_CATEGORIES = {
  ALL: 'all',
  BUSINESS: 'business',
  FINANCIAL: 'financial',
  BANK: 'bank',
  CONTRACTS: 'contracts',
};

export const FILE_TYPES_IN_CATEGORIES = {
  [DOCUMENT_CATEGORIES.BUSINESS]: [
    BUSINESS_DOC_TYPE.CORP_DOCS,
    BUSINESS_DOC_TYPE.EIN_DOC,
    BUSINESS_DOC_TYPE.ENTITY_CHART,
    BUSINESS_DOC_TYPE.CASHFLOW_FORECAST,
    BUSINESS_DOC_TYPE.OPERATING_BUDGET,
    BUSINESS_DOC_TYPE.DEBT_OBLIGATIONS,
  ],
  [DOCUMENT_CATEGORIES.FINANCIAL]: [BUSINESS_DOC_TYPE.FINANCIAL_STATEMENTS],
  [DOCUMENT_CATEGORIES.BANK]: [
    BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS,
    BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE,
    BANK_ACCOUNT_DOC_TYPE.DEBIT_AUTHORIZATION,
  ],
  [DOCUMENT_CATEGORIES.CONTRACTS]: [
    CONTRACTS_DOC_TYPE.BNPL_BILL,
    CONTRACTS_DOC_TYPE.APR_AGREEMENT,
    CONTRACTS_DOC_TYPE.ACH,
    CONTRACTS_DOC_TYPE.ADVANCE,
  ],
};

// You can use the following query in periscope to see all file types
// SELECT entity, type FROM file_uploads GROUP BY entity, type;
export const FILE_TYPES_IN_ENTITIES = {
  [ENTITY_TYPE.BANK_ACCOUNT]: [
    BANK_ACCOUNT_DOC_TYPE.DEBIT_AUTHORIZATION,
    BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS,
    BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE,
  ],
  [ENTITY_TYPE.BUSINESS]: [
    BUSINESS_DOC_TYPE.CORP_DOCS,
    BUSINESS_DOC_TYPE.EIN_DOC,
    BUSINESS_DOC_TYPE.FINANCIAL_STATEMENTS,
    BUSINESS_DOC_TYPE.ENTITY_CHART,
    BUSINESS_DOC_TYPE.CASHFLOW_FORECAST,
    BUSINESS_DOC_TYPE.OPERATING_BUDGET,
    BUSINESS_DOC_TYPE.DEBT_OBLIGATIONS,
  ],
};
