import { computed } from 'vue';
import { mapStateModuleGettersForComposable } from '@/utils/state-modules';
import { isOwnersStepOneComplete } from '@/utils/onboarding-steps';

export default (context) => {
  const ctx = context.root;
  const { getters } = ctx.$store;

  const user = computed(() => getters.user);
  const isOwnersFormComplete = computed(() => getters.isOwnersFormComplete);
  const firstIncompleteOwnerStepOne = computed(
    () => getters.firstIncompleteOwnerStepOne,
  );
  const firstIncompleteOwner = computed(() => getters.firstIncompleteOwner);
  const ownerVerificationStepComplete = computed(
    () => !!user.value?.agreedToKyc,
  );
  const isStepOneComplete = () => {
    const { steps } = mapStateModuleGettersForComposable(['steps'], ctx);
    const firstPageOfForm = steps.value[ctx.$route.name].firstStepOfForm?.name;
    const stepTwo = steps.value[firstPageOfForm].nextRoute?.name;

    return (
      ctx.$route.name === stepTwo &&
      isOwnersStepOneComplete(getters.allBusinessProfiles, getters.uploads)
    );
  };

  const redirectToCorrectOwnersPage = async () => {
    const { steps } = mapStateModuleGettersForComposable(
      [
        'steps',
        'isOwnersFormComplete',
        'firstIncompleteOwnerStepOne',
        'firstIncompleteOwner',
      ],
      ctx,
    );

    const firstPageOfForm = steps.value[ctx.$route.name].firstStepOfForm?.name;
    const formCompletePage = steps.value[firstPageOfForm].formCompleteStep.name;

    if (isOwnersFormComplete.value) {
      ctx.$router
        .push({
          name: formCompletePage,
        })
        .catch(() => {});
    } else if (firstIncompleteOwner.value) {
      const ownerId = firstIncompleteOwner.value.id;
      await ctx.$store.dispatch('SET_SELECTED_OWNER', ownerId);
      let routeName;
      if (firstIncompleteOwnerStepOne?.value?.id === ownerId) {
        routeName = firstPageOfForm;
      } else {
        // if step one complete direct to second step
        routeName = steps.value[firstPageOfForm].nextRoute.name;
      }

      ctx.$router
        .push({
          name: routeName,
          params: { id: ownerId },
        })
        .catch(() => {});
    } else {
      ctx.$router
        .push({
          name: firstPageOfForm,
          params: { id: user.value.id },
        })
        .catch(() => {});
    }
  };
  return {
    ownerVerificationStepComplete,
    redirectToCorrectOwnersPage,
    isStepOneComplete,
  };
};
