<template>
  <todo-section
    :complete="isComplete"
    :title="$t('applicationPage.uploadSupportingDocs')"
    theme="white"
    :open-dropdown="openDropdown"
  >
    <form-row no-inputs="no-inputs">
      <p>
        {{ $t('applicationPage.validateIdentity') }}
      </p>
    </form-row>
    <todo-section
      v-if="uploadRequirements.corp_docs.required"
      :complete="uploadRequirements.corp_docs.complete"
      :title="`${businessName} - ${supportingDocumentsLabel(
        business.corpCountry,
      )()}`"
      theme="white"
      top-border
      bottom-border
    >
      <p>
        <b>{{ corpDocsRequired }}</b
        ><br /><span class="small italic">
          {{ $t('applicationPage.businessDetailsShouldMatch') }}</span
        >
      </p>
      <UploadFileWidget
        :upload-filters="{ entity: 'business', type: 'corp_docs' }"
      />
    </todo-section>
    <todo-section
      v-if="uploadRequirements.ein_doc.required"
      :complete="uploadRequirements.ein_doc.complete"
      :title="$t('applicationPage.einVerification', { business: businessName })"
      theme="white"
      bottom-border
    >
      <p>
        <b>{{ $t('applicationPage.uploadEIN') }}</b
        ><br /><span class="small italic"
          ><a
            v-if="!showEINHelp"
            href="#"
            @click.prevent="showEINHelp = true"
            >{{ $t('applicationPage.lostEIN') }}</a
          ><span v-else>
            <i18n-t keypath="applicationPage.requestNewLetter">
              <template #contactNumber>
                <a href="tel:18008294933"> 1-800-829-4933 </a>
              </template>
              <template #askFor>
                <br />
                {{ $t('applicationPage.askFor') }}
                <br />
              </template>
            </i18n-t> </span
        ></span>
      </p>
      <UploadFileWidget
        :upload-filters="{ entity: 'business', type: 'ein_doc' }"
        :empty-prompt="$t('components.uploadFileWidget.emptyPrompt')"
      />
    </todo-section>
    <todo-section
      v-if="uploadRequirements.id_user.required"
      :complete="uploadRequirements.id_user.complete"
      :title="$t('applicationPage.governmentID', { user: userName })"
      theme="white"
      bottom-border
    >
      <p>
        <b>{{ $t('applicationPage.uploadClearAndLegible') }}</b
        ><br />{{ idRequired }} {{ $t('applicationPage.andEnsure') }}<br /><span
          class="small italic"
          >{{ $t('applicationPage.idMustBeValid') }}</span
        >
      </p>
      <UploadFileWidget
        :upload-filters="{ entity: 'user', type: ID_TYPE.PASSPORT_OR_LICENSE }"
        :display-filters="{ entity: 'user', type: Object.values(ID_TYPE) }"
        :empty-prompt="$t('components.uploadFileWidget.emptyPrompt')"
      />
    </todo-section>
    <todo-section
      v-for="owner in businessOwners"
      :key="`owner-id-upload-${owner.id}`"
      :complete="uploadRequirements[`id_${owner.id}`].complete"
      :title="
        $t('applicationPage.ownerGovernmentID', {
          firstName: user.firstName,
          lastName: user.lastName,
        })
      "
      theme="white"
      bottom-border
    >
      <p>
        <b>{{ $t('applicationPage.uploadClearAndLegible') }}</b
        ><br />{{ $t('applicationPage.ifPersonHasUS') }}<br /><span
          class="small italic"
          >{{ $t('applicationPage.idMustBeValid') }}</span
        >
      </p>
      <UploadFileWidget
        :upload-filters="{
          entity: 'owner',
          metaId: owner.id,
          type: ID_TYPE.PASSPORT_OR_LICENSE,
        }"
        :display-filters="{
          entity: 'owner',
          metaId: owner.id,
          type: Object.values(ID_TYPE),
        }"
        :empty-prompt="$t('components.uploadFileWidget.emptyPrompt')"
      />
    </todo-section>
    <todo-section
      :complete="uploadRequirements.bank_statements.complete"
      :optional="!uploadRequirements.bank_statements.required"
      :title="
        $t('applicationPage.bankingDocuments', { business: businessName })
      "
      theme="white"
      bottom-border
    >
      <p>
        <b>{{ bankDocsRequiredLabel }}</b
        ><br />
        <span v-if="bankDocsSubtextRequired" class="small italic">
          {{ $t('applicationPage.usuallyNotRequired') }}</span
        >
      </p>
      <UploadFileWidget
        :upload-filters="{ entity: 'bank_account', type: 'bank_statements' }"
        :empty-prompt="$t('components.uploadFileWidget.emptyPrompt')"
      />
    </todo-section>
    <todo-section
      v-if="uploadRequirements.asset_liability_doc.required"
      :complete="uploadRequirements.asset_liability_doc.complete"
      :optional="!uploadRequirements.asset_liability_doc.required"
      :title="
        $t('applicationPage.accountingDocuments', { business: businessName })
      "
      theme="white"
      bottom-border
    >
      <p>
        <b>{{ $t('applicationPage.downloadAndCompleteAssets') }}</b>
      </p>
      <OnboardingButton
        :text="$t('common.downloadForm')"
        data-cy="download-form-cta"
        @click="downloadForm"
      />
      <UploadFileWidget
        :upload-filters="{
          entity: 'business',
          type: 'asset_liability_doc',
        }"
        :empty-prompt="$t('components.uploadFileWidget.emptyPrompt')"
        accepted-file-types="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
      />
    </todo-section>
    <todo-section
      :title="$t('applicationPage.otherUploads')"
      optional="optional"
      theme="white"
      bottom-border
    >
      <p>
        <b>{{ $t('applicationPage.ifWeAsked') }}</b
        ><br /><span class="small italic">{{
          $t('applicationPage.usuallyNotRequired')
        }}</span>
      </p>
      <UploadFileWidget
        :upload-filters="{ type: 'other' }"
        :empty-prompt="$t('components.uploadFileWidget.emptyPrompt')"
      />
    </todo-section>
  </todo-section>
</template>

<script>
import { mapGetters } from 'vuex';

import { ID_TYPE } from '@clearbanc/data-common-types';
import { supportingDocumentsLabel } from '@/utils/local';
import OnboardingButton from '@/components/buttons/ButtonOnboarding2';
import analytics from '@/utils/analytics';

export default {
  components: {
    OnboardingButton,
  },
  props: {
    openDropdown: { type: Boolean, default: false },
  },
  data() {
    return {
      showEINHelp: false,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'business',
      'businessOwners',
      'uploadRequirements',
      'uploadsComplete',
    ]),
    ID_TYPE: () => ID_TYPE,
    isComplete() {
      return this.uploadsComplete;
    },
    corpCountry() {
      return this.business.corpCountry;
    },
    idRequired() {
      if (this.corpCountry === 'GB') {
        return this.$t('applicationPage.pleaseUseUK');
      } else if (this.corpCountry === 'AU') {
        return this.$t('applicationPage.pleaseUseAustralian');
      } else if (this.corpCountry === 'NL') {
        return this.$t('applicationPage.pleaseUseNetherland');
      } else if (this.corpCountry === 'DE' || this.corpCountry === 'AT') {
        return this.$t('applicationPage.pleaseUseDriver');
      } else if (this.corpCountry === 'BE' || this.corpCountry === 'FI') {
        return this.$t('applicationPage.pleaseUseDriverOrCard');
      } else if (this.corpCountry === 'IE') {
        return this.$t('applicationPage.pleaseUseUK');
      }
      return this.$t('applicationPage.pleaseUseUS');
    },
    corpDocsRequired() {
      if (this.corpCountry === 'AU') {
        return this.$t('applicationPage.uploadAustralian');
      } else if (this.corpCountry === 'NL') {
        return this.$t('applicationPage.uploadExtract');
      }
      return this.$t('applicationPage.uploadSignedCopy');
    },
    bankDocsRequiredLabel() {
      if (
        ['AU', 'DE', 'BE', 'IE', 'NL', 'AT', 'FI'].includes(this.corpCountry)
      ) {
        return this.$t('applicationPage.uploadBankStatement');
      }
      return this.$t('applicationPage.ifWeAskedVoidedCheck');
    },
    bankDocsSubtextRequired() {
      return !['AU', 'DE', 'BE', 'IE', 'NL', 'AT', 'FI'].includes(
        this.corpCountry,
      );
    },
    businessName() {
      return this.business.name ?? this.$t('applicationPage.business');
    },
    userName() {
      return this.user.firstName
        ? this.user.fullName
        : this.$t('applicationPage.owner');
    },
  },
  mounted() {
    this.$store.dispatchApiAction('FETCH_USER_UPLOADS');
  },
  methods: {
    supportingDocumentsLabel,
    async downloadForm() {
      const downloadURL =
        'https://storage.googleapis.com/marketing-public-files/Clearco%20-%20Assets%20%26%20Liabilities%20Form.xlsx';
      window.open(downloadURL, '_blank');
      analytics.track('fe_download_acctforms');
    },
  },
};
</script>
