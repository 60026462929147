<template>
  <todo-section
    :complete="isComplete"
    :title="$t('applicationPage.businessDetails')"
    theme="white"
    :class="$style['business-details']"
    :open-dropdown="openDropdown"
  >
    <div v-if="!isBusinessDetailsShown">
      <b>{{ $t('applicationPage.searchForBusiness') }}</b>
      <ui-fieldset class="p-t-30">
        <design-input
          :label="$t('applicationPage.incorporationCountry')"
          :model-value="getCountryName"
          :error-msg="$t('common.requiredField')"
          :disabled="true"
          required
        />
        <design-dropdown
          v-if="
            stateOptionsForCountry(businessSearchParams.corpCountry) &&
            isStateRequiredForCountrySearch(businessSearchParams.corpCountry)
          "
          v-model="businessSearchParams.corpState"
          name="state"
          :error-msg="$t('common.requiredField')"
          :external-label="
            $t('applicationPage.stateOfIncorporation', {
              jurisdiction: stateLabel(businessSearchParams.corpCountry),
            })
          "
          :label="
            $t('applicationPage.selectStateOfIncorporation', {
              jurisdiction: stateLabel(businessSearchParams.corpCountry),
            })
          "
          :options="stateOptionsForCountry(businessSearchParams.corpCountry)"
          required
        />
        <design-input
          v-else-if="
            isStateRequiredForCountrySearch(businessSearchParams.corpCountry)
          "
          v-model="businessSearchParams.corpState"
          type="text"
          :error-msg="$t('common.requiredField')"
          :label="
            $t('applicationPage.homeCorpCountry', {
              jurisdiction: stateLabel(businessSearchParams.corpCountry),
            })
          "
          required
        />
      </ui-fieldset>
      <design-input
        v-model="businessSearchParams.name"
        type="text"
        :error-msg="$t('common.requiredField')"
        :label="businessNameLabel(businessSearchParams.corpCountry)"
        required
      />
      <form-row>
        <experiments-button
          :text="
            isSearchLoading
              ? this.$t('applicationPage.loading')
              : this.$t('applicationPage.search')
          "
          :disabled="isSearchLoading"
          :class="$style['experiments-button']"
          @click="searchForBusiness"
        />
      </form-row>
      <div
        v-if="
          businessSearchRequest.isSuccess || businessSearchRequest.error != null
        "
      >
        <div class="table-component__table-wrapper">
          <DataTable
            v-model:selection="selectedBusiness"
            :value="businessSearchResult"
            class="table-component__table"
            data-key="name"
            responsive-layout="scroll"
          >
            <template #empty>
              {{ $t('components.tableComponent.filterNoResults') }}
            </template>
            <Column selection-mode="single"></Column>
            <Column
              field="name"
              :header="
                businessNameLabel(businessSearchParams.corpCountry || 'AU')
              "
            />
            <Column
              field="fullAddress"
              :header="$t('applicationPage.address')"
            />
            <Column
              field="registrationNumber"
              :header="
                registrationNumberLabel(businessSearchParams.corpCountry)
              "
            />
          </DataTable>
        </div>
        <experiments-button
          :disabled="businessSearchResult.length === 0"
          :text="$t('applicationPage.select')"
          :class="[$style['experiments-button'], 'm-r-20']"
          @click="importBusiness"
        />
        <experiments-button
          :text="$t('applicationPage.cantFindBusiness')"
          :class="$style['experiments-button']"
          @click="manualInputBusinessDetails"
        />
      </div>
    </div>
    <form-group
      v-if="isBusinessDetailsShown"
      :class="$style['business-details']"
    >
      <form-row v-if="businessSearchSelected && !storeBusinessSearchSelected">
        <experiments-button
          :text="$t('applicationPage.backToSearch')"
          :class="[$style['experiments-button'], 'm-l-10']"
          @click="clearImportedBusiness"
        />
      </form-row>
      <form-group :class="$style['form-group']">
        <ui-fieldset>
          <design-input
            v-model="business.name"
            :label="businessNameLabel(business.corpCountry)"
            :error-msg="$t('common.requiredField')"
            :tooltip-msg="businessNameInstructions(business.corpCountry)"
            :disabled="propLocked('name') || isBusinessImported"
            required
          />
          <design-input
            v-model="business.dbaNames"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.otherBusinessNames')"
            :tooltip-msg="$t('applicationPage.otherBusinessNamesToolTip')"
            :disabled="propLocked('dbaNames')"
          />
        </ui-fieldset>
        <ui-fieldset>
          <design-dropdown
            v-model="business.corpCountry"
            :error-msg="$t('common.requiredField')"
            :external-label="$t('applicationPage.homeCountry')"
            :tooltip-msg="
              !isAUBusiness ? this.$t('applicationPage.homeCountryToolTip') : ''
            "
            :label="$t('applicationPage.selectCountry')"
            :options="countryDropdownOptions"
            :disabled="propLocked('corpCountry') || isBusinessImported"
            apply-sort
            required
            @input="trackCountryChange('incorporation country')"
          />
          <design-dropdown
            v-model="currCorpType"
            :error-msg="$t('common.requiredField')"
            :external-label="$t('common.legalBusinessStructure')"
            :label="$t('applicationPage.select')"
            :options="corpTypesForCountry(business.corpCountry)"
            :disabled="propLocked('corpType')"
            required
          />
          <DesignInput
            v-if="isOtherSelected"
            v-model="otherCorpType"
            :label="$t('common.otherLegalBusinessStructure')"
            :error-msg="$t('common.requiredField')"
            required
          />
        </ui-fieldset>
        <ui-fieldset>
          <design-input
            v-if="business.corpCountry === 'US'"
            v-model="business.federalTaxId"
            :error-msg="$t('common.requiredField')"
            :label="federalTaxIdLabel(business.corpCountry)"
            :disabled="propLocked('federalTaxId')"
            :required="business.corpCountry === 'US'"
          />
          <design-input
            v-model="business.registrationNumber"
            :error-msg="$t('common.requiredField')"
            :label="registrationNumberLabel(business.corpCountry)"
            :disabled="propLocked('registrationNumber') || isBusinessImported"
            :required="business.corpCountry !== 'US'"
            :tooltip-msg="registrationNumberInstructions(business.corpCountry)"
          />
        </ui-fieldset>
        <ui-fieldset>
          <design-input
            v-model="business.phone"
            type="phone"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.phoneNumber')"
            :disabled="propLocked('phone')"
            required
          >
            <template #info-msg>
              <span
                href="#"
                tabindex="0"
                @click.prevent="business.phone = user.phoneNumber"
                @keyup.enter="business.phone = user.phoneNumber"
                >{{
                  $t('applicationPage.clickToUseSignupNumber', {
                    phoneNumber: user.phoneNumber,
                  })
                }}</span
              >
            </template>
          </design-input>
          <design-input
            v-model="business.billingEmail"
            type="email"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.additionalEmailAddress')"
            :tooltip-msg="$t('applicationPage.additionalEmailAddressToolTip')"
          />
        </ui-fieldset>
      </form-group>
      <form-group
        :label="
          ['AU', 'NL', 'SG'].includes(business.corpCountry)
            ? this.$t('applicationPage.registeredAddress')
            : this.$t('applicationPage.businessAddress')
        "
        :disabled="propLocked('address')"
        :class="$style['form-group']"
      >
        <design-address-input
          v-model="business.address"
          :error-msg="$t('common.requiredField')"
        />
      </form-group>
      <form-group
        :label="
          business.corpCountry === 'AU'
            ? this.$t('applicationPage.principalPlace')
            : this.$t('applicationPage.shippingAddress')
        "
        :disabled="propLocked('shippingAddress')"
        :class="[$style['form-group'], 'p-t-10', 'p-b-10']"
      >
        <ui-fieldset>
          <design-checkbox
            v-model="business.shippingAddressSame"
            :error-msg="$t('common.requiredField')"
          >
            {{ $t('applicationPage.sameAsBusinessAddress') }}
          </design-checkbox>
        </ui-fieldset>
      </form-group>
      <form-group
        v-if="!business.shippingAddressSame"
        :label="$t('applicationPage.address')"
        :class="$style['form-group']"
      >
        <design-address-input
          v-model="business.shippingAddress"
          :error-msg="$t('common.requiredField')"
        />
      </form-group>
      <form-group
        :label="$t('applicationPage.incorporationDetails')"
        :class="$style['form-group']"
      >
        <ui-fieldset>
          <design-dropdown
            v-if="jurisdictionsOptionsForCountry(business.corpCountry)"
            v-model="business.corpState"
            :error-msg="$t('common.requiredField')"
            :external-label="
              $t('applicationPage.jurisdictionLabel', {
                jurisdiction: jurisdictionLabel(business.corpCountry),
              })
            "
            :tooltip-msg="$t('applicationPage.whereYouAreIncorporated')"
            :label="
              $t('applicationPage.jurisdictionLabel', {
                jurisdiction: jurisdictionLabel(business.corpCountry),
              })
            "
            :options="jurisdictionsOptionsForCountry(business.corpCountry)"
            :disabled="propLocked('corpState')"
            required
          />
          <design-input
            v-else
            v-model="business.corpState"
            type="text"
            :error-msg="$t('common.requiredField')"
            :label="
              $t('applicationPage.jurisdictionLabel', {
                jurisdiction: jurisdictionLabel(business.corpCountry),
              })
            "
            :tooltip-msg="$t('applicationpage.whereYouAreIncorporated')"
            :disabled="propLocked('corpState')"
            required
          />
          <design-input
            v-if="!['AU', 'SG'].includes(business.corpCountry)"
            v-model="business.corpDate"
            type="date"
            name="corpDate"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.incorporationDate')"
            :disabled="propLocked('corpDate')"
            :required="
              business.corpCountry === 'US' || business.corpCountry === 'CA'
            "
          />
          <design-input
            v-if="!['NL', 'SG'].includes(business.corpCountry)"
            v-model="business.stateTaxId"
            :label="`${stateTaxLabel(business.corpCountry)}`"
            :error-msg="$t('common.requiredField')"
            :tooltip-msg="
              stateTaxIdLabel(business.corpCountry, business.corpState)
            "
            :disabled="propLocked('stateTaxId')"
          />
        </ui-fieldset>
      </form-group>
      <form-group
        :label="$t('applicationPage.onlinePresence')"
        :class="$style['form-group']"
      >
        <ui-fieldset>
          <design-input
            v-model="business.website"
            type="url"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.yourWebsite')"
            :tooltip-msg="$t('applicationPage.otherThanShopify')"
          />
        </ui-fieldset>
        <ui-fieldset>
          <design-input
            v-model="business.facebookUrl"
            :error-msg="$t('common.requiredField')"
            type="url"
            :label="$t('applicationPage.facebookURL')"
          />
          <design-input
            v-model="business.twitterUsername"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.twitterUsername')"
            :tooltip-msg="$t('applicationPage.twitterExample')"
          />
          <design-input
            v-model="business.instagramUsername"
            :error-msg="$t('common.requiredField')"
            :label="$t('applicationPage.instagramUsername')"
            :tooltip-msg="$t('applicationPage.instagramToolTip')"
          />
        </ui-fieldset>
      </form-group>
      <form-row>
        <form-input
          v-model="business.tellUsNotes"
          type="textarea"
          :label="$t('applicationPage.anythingElse')"
        />
      </form-row>
    </form-group>
  </todo-section>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import {
  businessNameLabel,
  businessNameInstructions,
  federalTaxIdLabel,
  registrationNumberLabel,
  registrationNumberInstructions,
  stateTaxIdLabel,
  stateTaxLabel,
  stateLabel,
  stateOptionsForCountry,
  jurisdictionLabel,
  jurisdictionsOptionsForCountry,
} from '@/utils/local';
import {
  COUNTRY_CODE_WITH_NAMES,
  COUNTRY_OPTIONS,
  isStateRequiredForCountrySearch,
  isJurisdictionDifferentFromStateAdressForCountry,
} from '@/data/country-code-with-names';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { ExperimentsButton } from '@/components/';
import { UiFieldset } from 'ui-components';
import Column from '@clearbanc/clear-components/column';
import DataTable from '@clearbanc/clear-components/datatable';

import {
  corpTypesForCountry,
  CORP_TYPE_OTHER,
} from '@/data/corp-types-by-country';

import {
  DesignInput,
  DesignDropdown,
  DesignCheckbox,
  DesignAddressInput,
} from '@/components/deprecated';
import { validateChildren } from '@/composables/validation';

export default {
  components: {
    UiFieldset,
    DesignInput,
    DesignDropdown,
    DesignAddressInput,
    DesignCheckbox,
    ExperimentsButton,
    DataTable,
    Column,
  },
  props: {
    business: Object,
    user: Object,
    openDropdown: { type: Boolean, default: false },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      businessSearchResult: [],
      businessSearchParams: {
        corpCountry: this.business.corpCountry,
        corpState: this.business.corpState,
        name: this.business.name,
      },
      selectedBusiness: {},
    };
  },
  computed: {
    isBusinessDetailsShown() {
      return (
        this.businessHasPropsLocked > 0 ||
        this.businessDetailsComplete ||
        get(this.business.truliooSearchDetails, 'manual') ||
        this.isBusinessImported
      );
    },
    businessSearchSelected() {
      return (
        Object.keys(get(this.business.truliooSearchDetails, 'selected') || {})
          .length > 0
      );
    },
    isBusinessImported() {
      return this.storeBusinessSearchSelected || this.businessSearchSelected;
    },
    countryDropdownOptions() {
      return COUNTRY_OPTIONS;
    },
    otherOption() {
      return CORP_TYPE_OTHER.value;
    },
    isOtherSelected() {
      return this.business.corpType?.includes('other');
    },
    currCorpType: {
      get() {
        return this.isOtherSelected ? this.otherOption : this.business.corpType;
      },
      set(value) {
        this.business.corpType = value;
      },
    },
    otherCorpType: {
      get() {
        /*
         * Using two fields in state to keep track of 'other' input: corpType and corpTypeOther but
         * saving final value in corpType field.
         * If 'other' corpType had previously been saved to business, return substring of 'other - ***'
         * Otherwise, return current corpTypeOther value
         */
        return this.business.corpType.includes('other - ')
          ? this.business.corpType.substring(8)
          : this.business.corpTypeOther;
      },
      set(otherType) {
        this.business.corpTypeOther = otherType;
      },
    },
    COUNTRY_OPTIONS: () => COUNTRY_OPTIONS,
    ...mapGetters({
      propLocked: 'isBusinessPropLocked',
      businessDetailsComplete: 'businessDetailsComplete',
      businessHasPropsLocked: 'businessHasPropsLocked',
      storeBusinessSearchSelected: 'truliooBusinessSearchSelected',
      isAUBusiness: 'isAUBusiness',
      isUSBusiness: 'isUSBusiness',
    }),
    ...mapRequestStatuses({
      businessSearchRequest: 'SEARCH_FOR_BUSINESS',
    }),
    isComplete() {
      return this.businessDetailsComplete;
    },
    isSearchLoading() {
      return this.businessSearchRequest.isPending;
    },
    getCountryName() {
      if (!this.business.corpCountry) {
        return '';
      }
      return COUNTRY_CODE_WITH_NAMES[this.business.corpCountry]();
    },
  },
  methods: {
    async clearImportedBusiness() {
      this.business.truliooSearchDetails = {
        searched: [],
        selected: {},
        manual: false,
      };
    },
    async importBusiness() {
      if (!this.selectedBusiness.registrationNumber) return;
      this.business.truliooSearchDetails = {
        searched: this.businessSearchResult,
        selected: this.selectedBusiness,
        manual: false,
      };
      this.business.address = {
        city: this.selectedBusiness.addressCity,
        country: this.selectedBusiness.addressCountryCode,
        line1: this.selectedBusiness.addressStreet1,
        postalCode: this.selectedBusiness.addressPostalCode,
        // if state is not same as jurisdiction, do not autofill
        state:
          !isJurisdictionDifferentFromStateAdressForCountry(
            this.selectedBusiness.corpCountry,
          ) && this.selectedBusiness.corpState,
      };
      // trulioo doesn't return 'corpState' it returns jurisdiction which is not always state/province
      this.business.corpState =
        this.selectedBusiness.corpState || this.businessSearchParams.corpState;
      this.business.corpCountry =
        this.selectedBusiness.corpCountry ||
        this.businessSearchParams.corpCountry;
      this.business.registrationNumber =
        this.selectedBusiness.registrationNumber;
      this.business.name = this.selectedBusiness.name;
    },
    manualInputBusinessDetails() {
      this.business.truliooSearchDetails = {
        ...this.business.truliooSearchDetails,
        manual: true,
      };
    },
    async searchForBusiness() {
      const error = await this.hasError();
      if (error) return;
      const { business } = await this.$store.dispatchApiAction(
        'SEARCH_FOR_BUSINESS',
        this.businessSearchParams,
      );
      // build full address for display
      const result = business
        ? business.map((item) => {
            return {
              ...item,
              fullAddress: [
                item.addressStreet1,
                item.addressCity,
                item.addressPostalCode,
                item.corpCountry,
              ]
                .join(' ')
                .trim(),
            };
          })
        : [];
      this.businessSearchResult = result;
    },
    trackCountryChange(type) {
      // clear corpState when new country is selected
      this.businessSearchParams.corpState = null;

      analytics.track('fe_country_changed', {
        page: this.$router.path,
        type,
      });
    },

    // state/country localization fns
    corpTypesForCountry,
    federalTaxIdLabel,
    businessNameLabel,
    businessNameInstructions,
    registrationNumberLabel,
    registrationNumberInstructions,
    stateTaxIdLabel,
    stateTaxLabel,
    stateLabel,
    stateOptionsForCountry,
    isStateRequiredForCountrySearch,
    jurisdictionLabel,
    jurisdictionsOptionsForCountry,
  },
};
</script>
<style lang="less" module>
.business-details {
  div[class^='input-container'],
  div[class^='wrapper'] {
    max-width: none;
  }

  div[class^='menu'],
  input,
  input:focus,
  textarea {
    border: none;
    box-shadow: none !important;
    background-color: @self-serve-nav-bar-gray;
  }

  label[class^='form-input-label'] {
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.form-group {
  padding: 30px 10px 0;

  legend {
    margin: 0;
    padding: 0;
  }
}

.experiments-button {
  min-width: 200px;
}
</style>
