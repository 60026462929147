<template>
  <div v-if="shownBankAccounts?.length" :class="$style['account-list']">
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="groupedBankAccounts"
      :always-show-paginator="false"
      :rows="20"
      :sort-order="-1"
      responsive-layout="scroll"
      :loading="isLoading"
      :global-filter-fields="['bankName', 'accounts']"
    >
      <template #empty>
        {{ isLoading ? 'Loading Bank Accounts...' : 'No Bank Accounts Found.' }}
      </template>

      <template #header>
        <heading :class="$style.heading">
          <DSButton label="Add Bank Account" />

          <div :class="$style.search">
            <InputText
              v-model="filters['global'].value"
              :class="$style.search__input"
              placeholder="Search"
            />
            <i class="pi pi-search" :class="$style.search__icon" />
          </div>
        </heading>
      </template>

      <Column expander :style="{ width: '32px', paddingRight: '0' }" />

      <Column field="bankName" header="Bank Account">
        <template #body="slotProps">
          <div :class="$style['bank-label']">
            <span :class="$style['bank-label__logo']">
              <img
                v-if="slotProps.data.logo"
                :class="$style['bank-label__logo__img']"
                :src="getInlineImageData(slotProps.data.logo)"
                :alt="`${slotProps.data.bankName} logo`"
              />
              <span v-else :class="$style['bank-label__logo__img']">{{
                getBankShortInitials(slotProps.data.bankName)
              }}</span>
            </span>
            <span :class="$style['bank-label__name']">
              {{ slotProps.data.bankName }}
            </span>
          </div>
        </template>
      </Column>

      <Column field="" header="Status">
        <template #body="slotProps">
          <Tag
            v-if="accountDataIsLoading(slotProps.data.accounts)"
            icon="pi pi-spin pi-spinner"
            severity="info"
            value="Importing Account"
          ></Tag>

          <Tag
            v-else-if="haveConnectedAccounts(slotProps.data.accounts)"
            severity="success"
            value="Connected"
          ></Tag>

          <Tag
            v-else-if="
              haveAccountErrors(slotProps.data.accounts) ||
              !plaidChecks?.allChecksPass
            "
            severity="danger"
            value="Not Connected"
          ></Tag>
        </template>
      </Column>

      <Column field="" header="">
        <template #body="slotProps">
          <DSButton
            v-if="
              accountsContainRelinkOrConnectNewAction(
                slotProps.data.accounts,
              ) && !businessOnPlaidBypass
            "
            class="p-button-outlined"
            label="Reauthorize Connection"
            @click="
              reconnectClick(
                getAccountRequiringAuthorization(slotProps.data.accounts),
              )
            "
          />
        </template>
      </Column>

      <template #expansion="slotProps">
        <div
          v-for="(account, index) in slotProps.data.accounts"
          :class="$style['account-row-wrapper']"
        >
          <div :class="$style['account-row']">
            <div :class="$style['account-row__item']">
              <span :class="$style['account-row__item__label']">{{
                account.label
              }}</span>
              <span :class="$style['account-row__item__number']">
                *** ***
                {{ account.accountNumberLast4 }}
              </span>
              <Tag
                v-if="
                  account.id === currentBankAccountToBePrimary?.id ||
                  account.id === primaryBankAccount?.id
                "
                :class="$style['account-row__item__tag']"
                :style="{ background: '#F3DFFF', color: '#820EC6' }"
                severity="success"
                value="Primary"
              ></Tag>
            </div>
            <div>
              <DSButton
                icon="pi pi-ellipsis-h"
                class="p-button-rounded p-button-secondary p-button-text"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                @click="toggle(account, index, $event)"
              />
              <DSMenu
                id="overlay_menu"
                ref="menu"
                :model="getAccountMenuItems(account)"
                :popup="true"
              />
            </div>
            <Message
              v-if="
                isReorderBankingStepsEnabled &&
                !isContractSigned(account) &&
                canSignContract(account)
              "
              severity="info"
              :closable="false"
              :class="[$style.message, $style['message--small']]"
            >
              <h3>Sign ACH required</h3>
              <p>
                You are required to sign a debit authorization agreement
                allowing Clearco to debit your weekly payments from this
                account.
              </p>
              <p v-if="!isAuthorizedSignatory && hasContract(account)">
                ACH emailed to signatory.
              </p>
              <a v-else @click.once="signContract($event, account)">
                Sign ACH Agreement
              </a>
            </Message>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import InputText from '@clearbanc/clear-components/inputtext';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';
import DSButton from '@clearbanc/clear-components/button';
import DSMenu from '@clearbanc/clear-components/menu';
import Tag from '@clearbanc/clear-components/tag';
import Message from '@clearbanc/clear-components/message';
import { useContextRoot } from '@/utils/context-root';
import contractUtils from '@/composables/contracts';
import {
  BANK_CONNECTION_STATUS_SUMMARY,
  BANK_ERROR_STATUSES,
  BANK_CONNECTION_ACTIONS,
} from '@/data/bank-connection';

export default {
  emits: ['relink'],
  components: {
    DataTable,
    Column,
    InputText,
    DSButton,
    DSMenu,
    Tag,
    Message,
  },
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { signAchContract } = contractUtils(context, ctxRoot);
    return { signAchContract };
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      isLoading: false,
      groupedBankAccounts: [],
      expandedRows: [],
      BANK_CONNECTION_STATUS_SUMMARY,
      BANK_ERROR_STATUSES,
      BANK_CONNECTION_ACTIONS,
    };
  },
  computed: {
    ...mapGetters([
      'bankAccounts',
      'primaryBankAccount',
      'currentBankAccountToBePrimary',
      'isReorderBankingStepsEnabled',
      'billContracts',
      'businessOnPlaidBypass',
      'allBankAccountChecks',
      'shownBankAccounts',
      'bankAccountContracts',
      'isAuthorizedSignatory',
    ]),
  },
  async mounted() {
    this.isLoading = true;

    await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
    this.prepBankingData();

    this.isLoading = false;
  },
  methods: {
    toggle(account, index, event) {
      this.$refs.menu[index].toggle(event);
    },
    getAccountMenuItems(account) {
      // Customize based on what is avaialble for specific account
      return [
        {
          label: 'Make it primary',
          command: () => {},
        },
        {
          label: 'Rename account',
          command: () => {},
        },
        {
          label: 'Remove account',
          command: () => {},
          class: ['red-menu-item'],
        },
      ];
    },
    getInlineImageData(image) {
      return `data:image/png;base64,${image}`;
    },
    getBankShortInitials(bankName) {
      const words = bankName.split(' ').filter((word) => word !== '');
      const letters = words.map((word) => word[0]).join('');
      return letters.slice(0, 2);
    },
    groupBankAccounts() {
      const groupedBankAccounts = this.bankAccounts.reduce(
        (acc, bankAccount) => {
          const key = bankAccount.bankConnectionId ?? bankAccount.bankName;

          const bankGroup = acc[key] ?? {
            bankName: bankAccount.bankName,
            institutionId: bankAccount.connection?.plaidStatus?.institutionId,
            isPlaid: !!bankAccount.bankConnectionId,
            logo: null,
            accounts: [],
          };

          return {
            ...acc,
            [key]: {
              ...bankGroup,
              accounts: [...bankGroup.accounts, bankAccount],
            },
          };
        },
        {},
      );

      this.groupedBankAccounts = Object.values(groupedBankAccounts);
      this.expandedRows = Object.values(groupedBankAccounts);
    },
    getAllInstitutionDetails() {
      this.groupedBankAccounts.forEach(async (bank, index) => {
        if (bank.isPlaid && bank.institutionId) {
          const { institution } = await this.$store.dispatchApiAction(
            'POST_PLAID_PROXY',
            {
              method: 'institutionsGetById',
              institution_id: bank.institutionId,
              options: {
                include_optional_metadata: true,
              },
            },
          );

          if (institution) {
            this.groupedBankAccounts[index].logo = institution.logo;
          }
        }
      });
    },
    isContractSigned(account) {
      const combinedBankAccountAndBillContract = this.billContracts.find(
        (billContract) => billContract.bankAccountsId === account.id,
      );
      return (
        !!this.bankAccountContracts[account.id]?.userSignedAt ||
        !!combinedBankAccountAndBillContract?.userSignedAt
      );
    },
    canSignContract(account) {
      return !!(account.accountNumber && account.routingNumber);
    },
    async signContract(e, account) {
      e.stopPropagation();
      await this.signAchContract(account.id);
    },
    hasContract(account) {
      return !!this.bankAccountContracts[account.id];
    },
    reconnectClick(account) {
      this.$emit('relink', account);
    },
    getAccountRequiringAuthorization(accounts) {
      let accountRequiresReauthorization;

      accounts.forEach((account) => {
        const plaidStatusAction = account?.connection?.plaidStatus?.action;

        if (
          plaidStatusAction === this.BANK_CONNECTION_ACTIONS.RELINK ||
          plaidStatusAction === this.BANK_CONNECTION_ACTIONS.CONNECT_NEW
        ) {
          accountRequiresReauthorization = account;
        }
      });

      return accountRequiresReauthorization;
    },
    accountsContainRelinkOrConnectNewAction(accounts) {
      return accounts.some((account) => {
        const plaidStatusAction = account?.connection?.plaidStatus?.action;

        return (
          plaidStatusAction === this.BANK_CONNECTION_ACTIONS.RELINK ||
          plaidStatusAction === this.BANK_CONNECTION_ACTIONS.CONNECT_NEW
        );
      });
    },
    accountDataIsLoading(accounts) {
      return accounts.some((account) => {
        const status = account?.connection?.plaidStatus?.summary;

        return [
          this.BANK_CONNECTION_STATUS_SUMMARY.CONNECTING,
          this.BANK_CONNECTION_STATUS_SUMMARY.INITIAL_SCRAPE,
          this.BANK_CONNECTION_STATUS_SUMMARY.SCRAPING,
        ].includes(status);
      });
    },
    haveConnectedAccounts(accounts) {
      return accounts.some((account) => {
        const status = account?.connection?.plaidStatus?.summary;

        return status === this.BANK_CONNECTION_STATUS_SUMMARY.CONNECTED;
      });
    },
    haveAccountErrors(accounts) {
      return accounts.some((account) => {
        const status = account?.connection?.plaidStatus?.summary;

        return this.BANK_ERROR_STATUSES.includes(status);
      });
    },
    prepBankingData() {
      this.groupBankAccounts();
      this.getAllInstitutionDetails();
    },
  },
  watch: {
    bankAccounts(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.isLoading = true;
        this.prepBankingData();
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
body .red-menu-item .p-menuitem-link .p-menuitem-text,
body .red-menu-item .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #bd3e3e;
}
</style>

<style lang="less" module>
.account-list {
  margin-bottom: 40px;
}

.account-list [class^='p-tag-value'],
.account-list [class^='p-column-title'] {
  font-size: 14px;
  white-space: nowrap;
}

.account-list [class^='p-button'] {
  white-space: nowrap;
}

body
  [class^='p-datatable']
  [class^='p-datatable-tbody']
  [class^='p-datatable-row-expansion']
  td {
  padding: 0;
}

.account-list [class^='p-datatable-header'] {
  padding: 0 0 16px 0;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.heading [class^='p-inputtext'] {
  width: auto;
}

.search {
  position: relative;
  margin-top: 16px;
}

input.search__input {
  padding-left: 44px;
}

.search__icon {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.search__icon::before {
  color: #303032;
}

@media (min-width: 768px) {
  .account-list [class^='p-datatable-header'] {
    padding: 0 0 40px 0;
  }

  .heading {
    flex-direction: row;
    justify-content: space-between;
  }

  .heading [class^='p-inputtext'] {
    width: auto;
  }

  .search {
    margin-top: 0;
  }
}

.bank-label {
  display: flex;
  align-items: center;
}

.bank-label__logo img,
.bank-label__logo span {
  margin-right: 8px;
  flex-shrink: 0;
}

.bank-label__logo img {
  height: 40px;
  width: 40px;
}

.bank-label__logo span {
  height: 40px;
  width: 40px;
  background: #e8e8ea;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.bank-label__name {
  font-weight: 500;
  white-space: nowrap;
}

.account-row-wrapper {
  position: relative;
  padding-left: 52px;
  transition: background 0.2s;
  overflow: hidden;
}

.account-row-wrapper:hover {
  background: #f8f8fa;
  cursor: pointer;
}

.account-row-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: -1rem;
  width: calc(100% + 2rem);
  height: 1px;
  background: #ceced0;
}

.account-row-wrapper:first-child:before {
  display: none;
}

.account-row-wrapper > [class^='p-message'] {
  margin: 0.8rem 0 0 0;
}

.account-row-wrapper + .account-row-wrapper {
  padding-top: 1px;
}

.account-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.account-row-wrapper:first-child .account-row {
  margin-top: 0;
}

.account-row-wrapper:last-child .account-row {
  margin-bottom: 0;
}

.account-row__item {
  font-family: 'Gerstner-Programm';
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  white-space: nowrap;
}

.account-row__item__label {
  color: #303032;
  font-weight: 500;
  margin-right: 16px;
}

.account-row__item__number {
  color: #5f5f61;
  font-weight: 300;
  margin-right: 16px;
}

.account-row__item__tag {
  background: '#F3DFFF';
  color: '#820EC6';
}

.message {
  cursor: default;
}

.message a {
  cursor: pointer;
}

body .message--small h3 {
  margin-top: 2px;
}

body .message--small h3,
body .message--small p,
body .message--small a {
  font-size: 14px;
}
</style>
