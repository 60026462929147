// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".advance-offers-tabs_PQaGl{margin-bottom:0}.application-offers_nKSW_{margin-bottom:60px}.application-page_cbiaq div[class^=error-message]{font-size:14px}.application-page_cbiaq .title_vJVYQ{margin:0;font-family:Gerstner-Programm;font-style:normal;font-weight:400}.application-page_cbiaq .page-header_BcN3B .title_vJVYQ{font-size:26px;line-height:32px}.application-page_cbiaq .article-header_jKJxA{margin:50px 0 40px 0}.application-page_cbiaq .article-header_jKJxA .title_vJVYQ{font-size:22px;line-height:28px}.application-page_cbiaq .notice-text_TFgaF{font-weight:500;margin-bottom:20px}.application-page_cbiaq .notice-section_eS71u{padding:20px;border-radius:3px;background:#fff;margin:20px 0}.application-page_cbiaq .notice-section_eS71u.crunching-numbers_ZESn6{background:linear-gradient(rgba(0,0,0,.9),rgba(0,0,0,.5)),url(https://media0.giphy.com/media/4heseFMvObk9q/giphy.webp?cid=3640f6095c0182b066444936553b852a);color:#fff;text-shadow:0 1px 1px rgba(0,0,0,.5)}.application-page_cbiaq .notice-section_eS71u.crunching-numbers_ZESn6 h4{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advance-offers-tabs": "advance-offers-tabs_PQaGl",
	"application-offers": "application-offers_nKSW_",
	"application-page": "application-page_cbiaq",
	"title": "title_vJVYQ",
	"page-header": "page-header_BcN3B",
	"article-header": "article-header_jKJxA",
	"notice-text": "notice-text_TFgaF",
	"notice-section": "notice-section_eS71u",
	"crunching-numbers": "crunching-numbers_ZESn6"
};
export default ___CSS_LOADER_EXPORT___;
