// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropzone-container_heWKH{text-align:center}.dropzone-container_heWKH .upload-zone_GJqYF{margin:0 auto;max-width:350px;margin-bottom:25px}.dropzone-container_heWKH .file-list_QKKvZ{text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone-container": "dropzone-container_heWKH",
	"upload-zone": "upload-zone_GJqYF",
	"file-list": "file-list_QKKvZ"
};
export default ___CSS_LOADER_EXPORT___;
