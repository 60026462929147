<template>
  <div v-if="advance.rejectedAt" class="rejection-notice">
    <h4>{{ $t('applicationPage.doNotQualify') }}</h4>
    <div v-if="advance.rejectedReason === 'low_revenue'">
      <div v-if="business.type === 'vr'">
        <p>
          <i18n-t keypath="applicationPage.vacationRentalFinanceProgram">
            <template #monthlyRevenue>
              <b>{{
                $filters.currency(
                  4000 * currencyMultiplier(currencyByCorpCountry),
                  currencyByCorpCountry,
                  false,
                )
              }}</b>
            </template>
          </i18n-t>
        </p>
      </div>
      <div v-else-if="business.type === 'ecom'">
        <p>
          <i18n-t keypath="applicationPage.eCommerceFinancingProgram">
            <template #monthlyRevenue>
              <b>{{
                $filters.currency(
                  20000 * currencyMultiplier(currencyByCorpCountry),
                  currencyByCorpCountry,
                  false,
                )
              }}</b>
            </template>
          </i18n-t>
        </p>
        <p>
          {{ $t('applicationPage.doesNotQualifyGrowthFinancing') }}
        </p>
      </div>
      <p class="bold">{{ $t('applicationPage.keepGrowing') }}</p>
      <p>
        {{ $t('applicationPage.thingsMightChange') }}
      </p>
    </div>
    <div v-else-if="advance.rejectedReason === 'other_business'">
      <p>{{ $t('applicationPage.primaryPrograms') }}</p>
      <p class="bold">{{ $t('applicationPage.vacationRentals') }}</p>
      <p>
        {{ $t('applicationPage.growthCapital') }}
      </p>
      <p class="bold">{{ $t('applicationPage.eCommerce') }}</p>
      <p>
        {{ $t('applicationPage.eCommerceBusinesses') }}
      </p>
      <p>
        {{ $t('applicationPage.provideInitialGrowthCapital') }}
      </p>
    </div>
    <div v-if="advance.rejectedNotes">
      <p>
        {{ $t('applicationPage.specificFeedback') }}<br /><span
          class="small italic"
          >"{{ advance.rejectedNotes }}"</span
        >
      </p>
    </div>
    <p class="bold">
      {{ $t('applicationPage.specificQuestions') }}
    </p>
    <p class="rejection-notice__account-rep-details">
      <icon name="regular/address-card" scale="1.2" /> -
      <strong>{{ advance.admin.fullName }}</strong> -
      <a
        :href="`mailto:${advance.admin.email}?subject=Clearco%20Advance%20%23${advance.id}`"
        rel="noopener noreferrer"
        target="_blank"
        >{{ advance.admin.email }}</a
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  determineCurrencyMultiplier,
  currencyCodeByCorpCountry,
} from '@/utils/currency';

export default {
  props: {
    advance: Object,
  },
  computed: {
    ...mapGetters(['business']),
    currencyByCorpCountry() {
      return currencyCodeByCorpCountry(this.business.corpCountry);
    },
  },
  methods: {
    currencyMultiplier(currencyCode) {
      return determineCurrencyMultiplier(currencyCode);
    },
  },
};
</script>

<style lang="less">
.rejection-notice {
  border: 1px solid @error-red-border;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 7px;
  background: #f7f1f1;

  .rejection-notice__account-rep-details {
    margin: 0;
  }
}
</style>
