<template>
  <div :class="$style['info-container']">
    <template>
      <ContentToggle
        :active-section="activeSection"
        :sections="[
          { label: 'Required Items', sectionToToggle: 'requirements' },
          {
            label: 'What\'s New?',
            sectionToToggle: 'whats-new',
            alignRight: true,
          },
        ]"
        @toggleSection="(clickedSection) => (activeSection = clickedSection)"
      />
      <WhatsNewContent v-if="activeSection === 'whats-new'" />
    </template>

    <div v-if="activeSection === 'requirements'" :class="$style['info-box']">
      <div :class="$style['info-item']">
        <div :class="$style['info-item--icon']" v-html="photoidSVG" />
        <div :class="$style['info-item--text']">
          {{ $t('paymentsPage.photoIdCopy') }}
        </div>
      </div>
      <div :class="$style['info-item']">
        <div :class="$style['info-item--icon']" v-html="einSVG" />
        <div :class="$style['info-item--text']">
          {{ $t('paymentsPage.einAndAOI') }}
        </div>
      </div>
      <div :class="$style['info-item']">
        <div :class="$style['info-item--icon']" v-html="bankSVG" />
        <div :class="$style['info-item--text']">
          {{ $t('paymentsPage.bankConnectionInfo') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContentToggle from '@/components/ContentToggle';
import { WhatsNewContent } from '@/components';

export default {
  components: {
    ContentToggle,
    WhatsNewContent,
  },
  data() {
    return {
      photoidSVG: require('@/assets/icons/photoid.svg'),
      einSVG: require('@/assets/icons/ein.svg'),
      bankSVG: require('@/assets/icons/bank.svg'),
      activeSection: 'requirements',
    };
  },
};
</script>

<style lang="less" module>
.info-container {
  flex: 1;
  margin-right: 2rem;

  .info-box {
    background: linear-gradient(0deg, rgba(97, 47, 255, 0.08) 25%, #fff 100%);
    border: 1px solid @color-purple-light-2022;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: @spacing-20;

    .info-heading {
      font-weight: 500;
      font-family: @gerstner-font;
      margin: 0;
    }

    .info-item {
      display: flex;

      .info-item--icon {
        flex: 1;

        svg {
          width: 2em;
          padding-top: @spacing-4;
        }
      }

      .info-item--text {
        flex: 6;
        font-size: @font-size-12;

        @media only screen and (max-width: 800px) {
          flex: 5;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    padding: unset;
    margin: 2rem 0 0 0;
  }
}
</style>
