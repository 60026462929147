<template>
  <LayoutAuth
    :error="errorMessage"
    :success-message="successMessage"
    title="You're almost done"
    :class="$style['verification-container']"
  >
    <template #header>
      <ProgressBar
        v-if="!isPageLoaded || isLoading"
        mode="indeterminate"
        style="height: 0.5em; width: 100%"
        aria-label="Loading progress"
        data-cy="progress-bar"
      />
      <Message
        data-cy="message-banner"
        :closable="false"
        :class="$style['message-banner']"
      >
        <p>
          For any questions or assistance, please contact us at
          <a href="mailto:support@clear.co">support@clear.co</a>
        </p>
      </Message>
    </template>
    <template #body>
      <div
        v-if="!getEmailVerificationRequest.wasRequested"
        :class="$style['loading-content']"
      >
        <LoadingScreen />
      </div>
      <div v-else :class="$style['confirmation-content']">
        <p>
          A verification link was sent to your email<br />
          <span v-if="isPageLoaded">
            <span>{{ user?.email }}</span>
            <br />
            on {{ sentAt }}.
          </span>
        </p>
        <p>Click the link in the email to complete signup.</p>
        <div data-cy="actions-panel" :class="$style['actions-panel']">
          <button
            data-cy="verification-btn"
            :disabled="isLoading"
            @click="sendEmailVerification"
          >
            Resend Verification Link
          </button>
          <button data-cy="logout-btn" :disabled="isLoading" @click="onLogout">
            Sign In with a Different Account
          </button>
          <button
            data-cy="refresh-btn"
            :disabled="isLoading"
            @click="redirectToHome"
          >
            I've verified my email
          </button>
        </div>
      </div>
    </template>
  </LayoutAuth>
</template>

<script>
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { format } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-vue';
import ProgressBar from '@clearbanc/clear-components/progressbar';
import LoadingScreen from '@/components/LoadingScreen';
import Message from '@clearbanc/clear-components/message';
import LayoutAuth from '../components/layouts/LayoutAuth';

export default {
  setup() {
    const { user, getAccessTokenSilently } = useAuth0();
    return {
      user,
      getAccessTokenSilently,
    };
  },
  components: {
    ProgressBar,
    LayoutAuth,
    Message,
    LoadingScreen,
  },
  data() {
    return {
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
      errorMessage: null,
      successMessage: null,
      sentAt: null,
      expiresAt: null,
      reloadingToken: false,
    };
  },
  async mounted() {
    if (this.$route.query?.message && this.$route.query.success === 'false') {
      this.errorMessage = this.$route.query?.message;
    }
    await this.getAccessTokenSilently();

    if (this.user?.email_verified || this.user?.userId) {
      this.$store.commit('EMAIL_VERIFIED');
      await this.redirectToHome();
    } else {
      await this.fetchEmailVerification();
    }
  },
  methods: {
    async fetchEmailVerification() {
      const response = await this.$store.dispatchApiAction(
        'GET_EMAIL_VERIFICATION',
      );
      if (this.getEmailVerificationRequest.isSuccess) {
        this.updateMailDates(response.sentAt, response.expiresAt);
      }
      if (this.getEmailVerificationRequest.isError) {
        this.errorMessage =
          "We couldn't get your email verification status. If the problem persists, please contact our support team.";
      }
    },
    async redirectToHome() {
      this.reloadingToken = true;
      await this.getAccessTokenSilently({
        ignoreCache: true,
      });
      this.$router.push({ name: 'home' });
      this.reloadingToken = false;
    },
    async sendEmailVerification() {
      this.errorMessage = null;
      this.successMessage = null;

      const response = await this.$store.dispatchApiAction(
        'SEND_EMAIL_VERIFICATION',
      );
      if (this.sendEmailVerificationRequest.isSuccess) {
        this.updateMailDates(response.sentAt, response.expiresAt);
        this.successMessage = 'New verification email sent successfully';
      }
      if (this.sendEmailVerificationRequest.isError) {
        const { errorCode, expiresAt, sentAt } =
          this.sendEmailVerificationRequest.error ?? {};

        // There's an active verification link
        if (errorCode === 'AUTH006') {
          this.updateMailDates(sentAt, expiresAt);
          this.errorMessage = `You've already requested a verification link. Check your inbox for the email or try again after ${this.expiresAt}`;
        } else if (errorCode === 'AUTH007') {
          // Email already verified
          await this.redirectToHome();
        } else {
          this.errorMessage =
            "We couldn't send your email verification request. If the problem persists, please contact our support team via support@clear.co.";
        }
      }
    },
    onLogout() {
      this.$router.push({ name: 'logout' });
    },
    updateMailDates(sentAt, expiresAt) {
      this.sentAt = format(new Date(sentAt), "MMMM d, 'at' h:mm a");
      this.expiresAt = format(new Date(expiresAt), 'h:mm a');
    },
  },
  computed: {
    ...mapRequestStatuses({
      getEmailVerificationRequest: 'GET_EMAIL_VERIFICATION',
      sendEmailVerificationRequest: 'SEND_EMAIL_VERIFICATION',
    }),
    isPageLoaded() {
      return !!this.user && !!this.sentAt;
    },
    isLoading() {
      return (
        this.reloadingToken ||
        this.getEmailVerificationRequest.isPending ||
        this.sendEmailVerificationRequest.isPending
      );
    },
  },
};
</script>

<style lang="less" module>
.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-banner {
  margin: 20px 20px 0 20px !important;
  max-width: 700px;
}

.confirmation-content {
  span {
    font-weight: 500;
  }
}

.loading-content {
  margin: 30px auto;
}

.actions-panel {
  border-top: 1px solid @color-gray;
  padding-top: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 18px;

  > button {
    margin-top: 8px;
    display: inline-block;
    color: @lighter-link-blue;
  }
}
</style>
