const provincesByCountry = {
  US: [
    { value: 'AL', label: 'AL - Alabama', name: () => 'Alabama' },
    { value: 'AK', label: 'AK - Alaska', name: () => 'Alaska' },
    { value: 'AS', label: 'AS - American Samoa', name: () => 'American Samoa' },
    { value: 'AZ', label: 'AZ - Arizona', name: () => 'Arizona' },
    { value: 'AR', label: 'AR - Arkansas', name: () => 'Arkansas' },
    { value: 'CA', label: 'CA - California', name: () => 'California' },
    { value: 'CO', label: 'CO - Colorado', name: () => 'Colorado' },
    { value: 'CT', label: 'CT - Connecticut', name: () => 'Connecticut' },
    { value: 'DE', label: 'DE - Delaware', name: () => 'Delaware' },
    { value: 'DC', label: 'DC - District Of Columbia', name: () => 'Columbia' },
    {
      value: 'FM',
      label: 'FM - Federated States Of Micronesia',
      name: () => 'Federated States Of Micronesia',
    },
    { value: 'FL', label: 'FL - Florida', name: () => 'Florida' },
    { value: 'GA', label: 'GA - Georgia', name: () => 'Georgia' },
    { value: 'GU', label: 'GU - Guam', name: () => 'Guam' },
    { value: 'HI', label: 'HI - Hawaii', name: () => 'Hawaii' },
    { value: 'ID', label: 'ID - Idaho', name: () => 'Idaho' },
    { value: 'IL', label: 'IL - Illinois', name: () => 'Illinois' },
    { value: 'IN', label: 'IN - Indiana', name: () => 'Indiana' },
    { value: 'IA', label: 'IA - Iowa', name: () => 'Iowa' },
    { value: 'KS', label: 'KS - Kansas', name: () => 'Kansas' },
    { value: 'KY', label: 'KY - Kentucky', name: () => 'Kentucky' },
    { value: 'LA', label: 'LA - Louisiana', name: () => 'Louisiana' },
    { value: 'ME', label: 'ME - Maine', name: () => 'Maine' },
    {
      value: 'MH',
      label: 'MH - Marshall Islands',
      name: () => 'Marshall Islands',
    },
    { value: 'MD', label: 'MD - Maryland', name: () => 'Maryland' },
    { value: 'MA', label: 'MA - Massachusetts', name: () => 'Massachusetts' },
    { value: 'MI', label: 'MI - Michigan', name: () => 'Michigan' },
    { value: 'MN', label: 'MN - Minnesota', name: () => 'Minnesota' },
    { value: 'MS', label: 'MS - Mississippi', name: () => 'Mississippi' },
    { value: 'MO', label: 'MO - Missouri', name: () => 'Missouri' },
    { value: 'MT', label: 'MT - Montana', name: () => 'Montana' },
    { value: 'NE', label: 'NE - Nebraska', name: () => 'Nebraska' },
    { value: 'NV', label: 'NV - Nevada', name: () => 'Nevada' },
    { value: 'NH', label: 'NH - New Hampshire', name: () => 'New Hampshire' },
    { value: 'NJ', label: 'NJ - New Jersey', name: () => 'New Jersey' },
    { value: 'NM', label: 'NM - New Mexico', name: () => 'New Mexico' },
    { value: 'NY', label: 'NY - New York', name: () => 'New York' },
    { value: 'NC', label: 'NC - North Carolina', name: () => 'North Carolina' },
    { value: 'ND', label: 'ND - North Dakota', name: () => 'North Dakota' },
    {
      value: 'MP',
      label: 'MP - Northern Mariana Islands',
      name: () => 'Northern Mariana Islands',
    },
    { value: 'OH', label: 'OH - Ohio', name: () => 'Ohio' },
    { value: 'OK', label: 'OK - Oklahoma', name: () => 'Oklahoma' },
    { value: 'OR', label: 'OR - Oregon', name: () => 'Oregon' },
    { value: 'PW', label: 'PW - Palau', name: () => 'Palau' },
    { value: 'PA', label: 'PA - Pennsylvania', name: () => 'Pennsylvania' },
    { value: 'PR', label: 'PR - Puerto Rico', name: () => 'Puerto Rico' },
    { value: 'RI', label: 'RI - Rhode Island', name: () => 'Rhode Island' },
    { value: 'SC', label: 'SC - South Carolina', name: () => 'South Carolina' },
    { value: 'SD', label: 'SD - South Dakota', name: () => 'South Dakota' },
    { value: 'TN', label: 'TN - Tennessee', name: () => 'Tennessee' },
    { value: 'TX', label: 'TX - Texas', name: () => 'Texas' },
    { value: 'UT', label: 'UT - Utah', name: () => 'Utah' },
    { value: 'VT', label: 'VT - Vermont', name: () => 'Vermont' },
    { value: 'VI', label: 'VI - Virgin Islands', name: () => 'Virgin Islands' },
    { value: 'VA', label: 'VA - Virginia', name: () => 'Virginia' },
    { value: 'WA', label: 'WA - Washington', name: () => 'Washington' },
    { value: 'WV', label: 'WV - West Virginia', name: () => 'West Virginia' },
    { value: 'WI', label: 'WI - Wisconsin', name: () => 'Wisconsin' },
    { value: 'WY', label: 'WY - Wyoming', name: () => 'Wyoming' },
  ],
  PR: [
    // Puerto Rico is not a country, it is a territory of the US
    // However, PR is both a ISO 3166-1 alpha-2 code and a ISO 3166-2 code
    { value: 'PR', label: 'PR - Puerto Rico', name: () => 'Puerto Rico' },
  ],
  CA: [
    { value: 'AB', label: 'AB - Alberta', name: () => 'Alberta' },
    {
      value: 'BC',
      label: 'BC - British Columbia',
      name: () => 'British Columbia',
    },
    { value: 'MB', label: 'MB - Manitoba', name: () => 'Manitoba' },
    { value: 'NB', label: 'NB - New Brunswick', name: () => 'New Brunswick' },
    {
      value: 'NL',
      label: 'NL - Newfoundland and Labrador',
      name: () => 'Newfoundland and Labrador',
    },
    {
      value: 'NT',
      label: 'NT - Northwest Territories',
      name: () => 'Northwest Territories',
    },
    { value: 'NS', label: 'NS - Nova Scotia', name: () => 'Nova Scotia' },
    { value: 'NU', label: 'NU - Nunavut', name: () => 'Nunavut' },
    { value: 'ON', label: 'ON - Ontario', name: () => 'Ontario' },
    {
      value: 'PE',
      label: 'PE - Prince Edward Island',
      name: () => 'Prince Edward Island',
    },
    { value: 'QC', label: 'QC - Quebec', name: () => 'Quebec' },
    { value: 'SK', label: 'SK - Saskatchewan', name: () => 'Saskatchewan' },
    { value: 'YT', label: 'YT - Yukon', name: () => 'Yukon' },
  ],
  GB: [
    { value: 'NI', label: 'Northern Ireland', name: () => 'Northern Ireland' },
    { value: 'SC', label: 'Scotland', name: () => 'Scotland' },
    { value: 'WA', label: 'Wales', name: () => 'Wales' },
    { value: 'EN', label: 'England', name: () => 'England' },
  ],
  AU: [
    {
      value: 'ACT',
      label: 'Australian Capital Territory',
      name: () => 'Australian Capital Territory',
    },
    { value: 'NSW', label: 'New South Wales', name: () => 'New South Wales' },
    {
      value: 'NT',
      label: 'Northern Territory',
      name: () => 'Northern Territory',
    },
    { value: 'QLD', label: 'Queensland', name: () => 'Queensland' },
    { value: 'SA', label: 'South Australia', name: () => 'South Australia' },
    { value: 'TAS', label: 'Tasmania', name: () => 'Tasmania' },
    { value: 'VIC', label: 'Victoria', name: () => 'Victoria' },
    {
      value: 'WA',
      label: 'Western Australia',
      name: () => 'Western Australia',
    },
  ],
  NL: [
    { value: 'DR', label: 'Drenthe', name: () => 'Drenthe' },
    { value: 'FL', label: 'Flevoland', name: () => 'Flevoland' },
    { value: 'FR', label: 'Fryslan', name: () => 'Fryslan' },
    { value: 'GE', label: 'Gelderland', name: () => 'Gelderland' },
    { value: 'GR', label: 'Groningen', name: () => 'Groningen' },
    { value: 'LI', label: 'Limburg', name: () => 'Limburg' },
    { value: 'NB', label: 'Noord-Brabant', name: () => 'Noord-Brabant' },
    { value: 'NH', label: 'Noord-Holland', name: () => 'Noord-Holland' },
    { value: 'OV', label: 'Overijssel', name: () => 'Overijssel' },
    { value: 'UT', label: 'Utrecht', name: () => 'Utrecht' },
    { value: 'ZE', label: 'Zeeland', name: () => 'Zeeland' },
    { value: 'ZH', label: 'Zuid-Holland', name: () => 'Zuid-Holland' },
  ],
  SG: [
    {
      value: '01',
      label: 'Central Singapore',
      name: () => 'Central Singapore',
    },
    { value: '02', label: 'North East', name: () => 'North East' },
    { value: '03', label: 'North West', name: () => 'North West' },
    { value: '04', label: 'South East', name: () => 'South East' },
    { value: '05', label: 'South West', name: () => 'South West' },
  ],
  DE: [
    {
      value: 'BW',
      label: 'Baden-Württemberg',
      name: () => 'Baden-Württemberg',
    },
    { value: 'BY', label: 'Bayern', name: () => 'Bayern' },
    { value: 'BE', label: 'Berlin', name: () => 'Berlin' },
    { value: 'BB', label: 'Brandenburg', name: () => 'Brandenburg' },
    { value: 'HB', label: 'Bremen', name: () => 'Bremen' },
    { value: 'HH', label: 'Hamburg', name: () => 'Hamburg' },
    { value: 'HE', label: 'Hessen', name: () => 'Hessen' },
    {
      value: 'MV',
      label: 'Mecklenburg-Vorpommern',
      name: () => 'Mecklenburg-Vorpommern',
    },
    { value: 'NI', label: 'Niedersachsen', name: () => 'Niedersachsen' },
    {
      value: 'NW',
      label: 'Nordrhein-Westfalen',
      name: () => 'Nordrhein-Westfalen',
    },
    { value: 'RP', label: 'Rheinland-Pfalz', name: () => 'Rheinland-Pfalz' },
    { value: 'SL', label: 'Saarland', name: () => 'Saarland' },
    { value: 'SN', label: 'Sachsen', name: () => 'Sachsen' },
    { value: 'ST', label: 'Sachsen-Anhalt', name: () => 'Sachsen-Anhalt' },
    {
      value: 'SH',
      label: 'Schleswig-Holstein',
      name: () => 'Schleswig-Holstein',
    },
    { value: 'TH', label: 'Thüringen', name: () => 'Thüringen' },
  ],
  IE: [
    { name: () => 'Carlow', label: 'Carlow', value: 'CW' },
    { name: () => 'Cavan', label: 'Cavan', value: 'CN' },
    { name: () => 'Clare', label: 'Clare', value: 'CE' },
    { name: () => 'Connaught', label: 'Connaught', value: 'C' },
    { name: () => 'Cork', label: 'Cork', value: 'CO' },
    { name: () => 'Donegal', label: 'Donegal', value: 'DL' },
    { name: () => 'Dublin', label: 'Dublin', value: 'D' },
    { name: () => 'Galway', label: 'Galway', value: 'G' },
    { name: () => 'Kerry', label: 'Kerry', value: 'KY' },
    { name: () => 'Kildare', label: 'Kildare', value: 'KE' },
    { name: () => 'Kilkenny', label: 'Kilkenny', value: 'KK' },
    { name: () => 'Laois', label: 'Laois', value: 'LS' },
    { name: () => 'Leinster', label: 'Leinster', value: 'L' },
    { name: () => 'Leitrim', label: 'Leitrim', value: 'LM' },
    { name: () => 'Limerick', label: 'Limerick', value: 'LK' },
    { name: () => 'Longford', label: 'Longford', value: 'LD' },
    { name: () => 'Louth', label: 'Louth', value: 'LH' },
    { name: () => 'Mayo', label: 'Mayo', value: 'MO' },
    { name: () => 'Meath', label: 'Meath', value: 'MH' },
    { name: () => 'Monaghan', label: 'Monaghan', value: 'MN' },
    { name: () => 'Munster', label: 'Munster', value: 'M' },
    { name: () => 'Offaly', label: 'Offaly', value: 'OY' },
    { name: () => 'Roscommon', label: 'Roscommon', value: 'RN' },
    { name: () => 'Sligo', label: 'Sligo', value: 'SO' },
    { name: () => 'Tipperary', label: 'Tipperary', value: 'TA' },
    { name: () => 'Ulster', label: 'Ulster', value: 'U' },
    { name: () => 'Waterford', label: 'Waterford', value: 'WD' },
    { name: () => 'Westmeath', label: 'Westmeath', value: 'WH' },
    { name: () => 'Wexford', label: 'Wexford', value: 'WX' },
    { name: () => 'Wicklow', label: 'Wicklow', value: 'WW' },
  ],
  AT: [
    { name: () => 'Burgenland', label: 'Burgenland', value: 'AT-1' },
    { name: () => 'Kärnten', label: 'Kärnten', value: 'AT-2' },
    {
      name: () => 'Niederösterreich',
      label: 'Niederösterreich',
      value: 'AT-3',
    },
    { name: () => 'Oberösterreich', label: 'Oberösterreich', value: 'AT-4' },
    { name: () => 'Salzburg', label: 'Salzburg', value: 'AT-5' },
    { name: () => 'Steiermark', label: 'Steiermark', value: 'AT-6' },
    { name: () => 'Tirol', label: 'Tirol', value: 'AT-7' },
    { name: () => 'Vorarlberg', label: 'Vorarlberg', value: 'AT-8' },
    { name: () => 'Wien', label: 'Wien', value: 'AT-9' },
  ],
  BE: [
    { name: () => 'Antwerpen', label: 'Antwerpen', value: 'AN' },
    { name: () => 'Limburg', label: 'Limburg', value: 'LI' },
    { name: () => 'Vlaams Brabant', label: 'Vlaams Brabant', value: 'VB' },
    { name: () => 'Oost-Vlaanderen', label: 'Oost-Vlaanderen', value: 'OV' },
    { name: () => 'West-Vlaanderen', label: 'West-Vlaanderen', value: 'WV' },
    { name: () => 'Hainaut', label: 'Hainaut', value: 'HT' },
    { name: () => 'Brabant wallon', label: 'Brabant wallon', value: 'BW' },
    { name: () => 'Namur', label: 'Namur', value: 'NA' },
    { name: () => 'Liège ', label: 'Liège ', value: 'LG' },
    { name: () => 'Luxembourg ', label: 'Luxembourg ', value: 'LX' },
  ],
  FI: [
    {
      name: () => 'Åland',
      label: 'Åland',
      value: 'AX',
    },
    {
      name: () => 'Central Finland',
      label: 'Central Finland',
      value: 'CF',
    },
    {
      name: () => 'Central Ostrobothnia ',
      label: 'Central Ostrobothnia ',
      value: 'CO',
    },
    {
      name: () => 'Kainuu',
      label: 'Kainuu',
      value: 'KA',
    },
    {
      name: () => 'Kanta-Häme ',
      label: 'Kanta-Häme ',
      value: 'KH',
    },
    {
      name: () => 'Kymenlaakso ',
      label: 'Kymenlaakso ',
      value: 'KY',
    },
    {
      name: () => 'Lapland',
      label: 'Lapland',
      value: 'LA',
    },
    {
      name: () => 'North Karelia',
      label: 'North Karelia',
      value: 'NK',
    },
    {
      name: () => 'North Ostrobothnia',
      label: 'North Ostrobothnia',
      value: 'NO',
    },
    {
      name: () => 'North Savo',
      labelame: 'North Savo',
      value: 'NS',
    },
    {
      name: () => 'Ostrobothnia',
      label: 'Ostrobothnia',
      value: 'OS',
    },
    {
      name: () => 'Päijät-Häme',
      label: 'Päijät-Häme',
      value: 'PH',
    },
    {
      name: () => 'Satakunta',
      label: 'Satakunta',
      value: 'SA',
    },
    {
      name: () => 'South Karelia',
      label: 'South Karelia',
      value: 'SK',
    },
    {
      name: () => 'South Ostrobothnia',
      labelame: 'South Ostrobothnia',
      value: 'SO',
    },
    {
      name: () => 'South Savo',
      label: 'South Savo',
      value: 'SS',
    },
    {
      name: () => 'Southwest Finland',
      label: 'Southwest Finland',
      value: 'SF',
    },
    {
      name: () => 'Tampere Region',
      labelame: 'Tampere Region',
      value: 'TR',
    },
    {
      name: () => 'Uusimaa',
      label: 'Uusimaa',
      value: 'US',
    },
  ],
  CN: [
    { value: 'AH', label: 'AH - Anhui', name: () => 'Anhui' },
    { value: 'BJ', label: 'BJ - Beijing', name: () => 'Beijing' },
    { value: 'CQ', label: 'CQ - Chongqing', name: () => 'Chongqing' },
    { value: 'FJ', label: 'FJ - Fujian', name: () => 'Fujian' },
    { value: 'GS', label: 'GS - Gansu', name: () => 'Gansu' },
    { value: 'GD', label: 'GD - Guangdong', name: () => 'Guangdong' },
    { value: 'GX', label: 'GX - Guangxi Zhuang', name: () => 'Guangxi Zhuang' },
    { value: 'GZ', label: 'GZ - Guizhou', name: () => 'Guizhou' },
    { value: 'HI', label: 'HI - Hainan', name: () => 'Hainan' },
    { value: 'HE', label: 'HE - Hebei', name: () => 'Hebei' },
    { value: 'HL', label: 'HL - Heilongjiang', name: () => 'Heilongjiang' },
    { value: 'HA', label: 'HA - Henan', name: () => 'Henan' },
    { value: 'HK', label: 'HK - Hong Kong', name: () => 'Hong Kong' },
    { value: 'HB', label: 'HB - Hubei', name: () => 'Hubei' },
    { value: 'HN', label: 'HN - Hunan', name: () => 'Hunan' },
    { value: 'NM', label: 'NM - Inner Mongolia', name: () => 'Inner Mongolia' },
    { value: 'JS', label: 'JS - Jiangsu', name: () => 'Jiangsu' },
    { value: 'JX', label: 'JX - Jiangxi', name: () => 'Jiangxi' },
    { value: 'JL', label: 'JL - Jilin', name: () => 'Jilin' },
    { value: 'LN', label: 'LN - Liaoning', name: () => 'Liaoning' },
    { value: 'MO', label: 'MO - Macau', name: () => 'Macau' },
    { value: 'NX', label: 'NX - Ningxia', name: () => 'Ningxia' },
    { value: 'QH', label: 'QH - Qinghai', name: () => 'Qinghai' },
    { value: 'SN', label: 'SN - Shaanxi', name: () => 'Shaanxi' },
    { value: 'SD', label: 'SD - Shandong', name: () => 'Shandong' },
    { value: 'SH', label: 'SH - Shanghai', name: () => 'Shanghai' },
    { value: 'SX', label: 'SX - Shanxi', name: () => 'Shanxi' },
    { value: 'SC', label: 'SC - Sichuan', name: () => 'Sichuan' },
    { value: 'TW', label: 'TW - Taiwan', name: () => 'Taiwan' },
    { value: 'TJ', label: 'TJ - Tianjin', name: () => 'Tianjin' },
    { value: 'XZ', label: 'XZ - Tibet', name: () => 'Tibet' },
    { value: 'XJ', label: 'XJ - Xinjiang', name: () => 'Xinjiang' },
    { value: 'YN', label: 'YN - Yunnan', name: () => 'Yunnan' },
    { value: 'ZJ', label: 'ZJ - Zhejiang', name: () => 'Zhejiang' },
  ],
  HK: [
    // Hong Kong is not a country, it is a city.
    // However, HK is both a ISO 3166-1 alpha-2 code and a ISO 3166-2 code
    { value: 'HK', label: 'HK - Hong Kong', name: () => 'Hong Kong' },
  ],
  SA: [
    { value: '01', label: '01 - Ar Riyāḑ', name: () => 'Ar Riyāḑ' },
    {
      value: '02',
      label: '02 - Makkah al Mukarramah',
      name: () => 'Makkah al Mukarramah',
    },
    {
      value: '03',
      label: '03 - Al Madīnah al Munawwarah',
      name: () => 'Al Madīnah al Munawwarah',
    },
    { value: '04', label: '04 - Ash Sharqīyah', name: () => 'Ash Sharqīyah' },
    { value: '05', label: '05 - Al Qaşīm', name: () => 'Al Qaşīm' },
    { value: '06', label: "06 - Ḩā'il", name: () => "Ḩā'il" },
    { value: '07', label: '07 - Tabūk', name: () => 'Tabūk' },
    {
      value: '08',
      label: '08 - Al Ḩudūd ash Shamālīyah',
      name: () => 'Al Ḩudūd ash Shamālīyah',
    },
    { value: '09', label: '09 - Jāzān', name: () => 'Jāzān' },
    { value: '10', label: '10 - Najrān', name: () => 'Najrān' },
    { value: '11', label: '11 - Al Bāḩah', name: () => 'Al Bāḩah' },
    { value: '12', label: '12 - Al Jawf', name: () => 'Al Jawf' },
    // There does not appear to be a SA-13 ISO 3166-1 alpha-2 code
    { value: '14', label: "14 - 'Asīr", name: () => "'Asīr" },
  ],
  LK: [
    {
      value: '1',
      label: '1 - Western Province',
      name: () => 'Western Province',
    },
    {
      value: '2',
      label: '3 - Central Province',
      name: () => 'Central Province',
    },
    {
      value: '3',
      label: '3 - Southern Provicen',
      name: () => 'Southern Province',
    },
    {
      value: '4',
      label: '4 - Northern Province',
      name: () => 'Northern Province',
    },
    {
      value: '5',
      label: '5 - Eastern Province',
      name: () => 'Eastern Province',
    },
    {
      value: '6',
      label: '6 - North Western Province',
      name: () => 'North Western Province',
    },
    {
      value: '7',
      label: '7 - North Central Province',
      name: () => 'North Central Province',
    },
    { value: '8', label: '8 - Uva Province', name: () => 'Uva Province' },
    {
      value: '9',
      label: '9 - Sabaragamuwa Province',
      name: () => 'Sabaragamuwa Province',
    },
  ],
};
export default provincesByCountry;
