// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid-banner_d627j{grid-row-start:1;grid-row-end:2;grid-column-start:1;grid-column-end:13;padding-bottom:16px}.grid-banner_d627j span[class*=p-message-icon]{margin-top:5px}.grid-header_GjP5x{grid-row-start:2;grid-row-end:3;grid-column-start:1;grid-column-end:13;padding-bottom:16px;padding-left:24px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}.grid-item-a_ZDm4V{grid-column-start:1;grid-column-end:5;margin-bottom:32px}.grid-item-a_ZDm4V,.grid-item-b_Gu6gT{grid-row-start:3;grid-row-end:4}.grid-item-b_Gu6gT{grid-column-start:5;grid-column-end:13;margin-bottom:24px;overflow-x:auto}.grid-item-b_Gu6gT>h4{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;padding-bottom:22px;padding-left:24px}.grid-item-b_Gu6gT>div:last-child{padding-top:24px;display:flex;justify-content:center}@media only screen and (max-width:1024px){.grid-item-a_ZDm4V{grid-column-end:13}.grid-item-b_Gu6gT{grid-column-start:1;grid-row-start:4;grid-row-end:5}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-banner": "grid-banner_d627j",
	"grid-header": "grid-header_GjP5x",
	"grid-item-a": "grid-item-a_ZDm4V",
	"grid-item-b": "grid-item-b_Gu6gT"
};
export default ___CSS_LOADER_EXPORT___;
