<template>
  <LayoutFormPage :show-error-message="showError">
    <template #default>
      <FormRegisteredAddress
        ref="form"
        :event-tracking-fields="commonTrackingFields"
        @validationSuccess="handleValidationSuccess"
        @validationFailure="showError = true"
        @goBack="goBack"
      />
    </template>
  </LayoutFormPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';
import { FormRegisteredAddress, LayoutFormPage } from '@/components';

export default {
  components: {
    FormRegisteredAddress,
    LayoutFormPage,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      showError: false,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel', 'business', 'user']),
    ...mapStateModuleGetters(['steps', 'previousStepName', 'experimentName']),
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.currentRoute,
        experiment: this.experimentName,
      };
    },
  },
  methods: {
    async handleValidationSuccess() {
      this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });
      this.$router.push({
        name: this.steps[this.currentRoute].nextRoute.name,
        params: { id: this.user.id },
      });
    },
    goBack() {
      // using local storage here because if the user goes to the previous step and clicks the Next button
      // the backend will update the business and the state will be overwritten in the store
      localStorage.setItem('business', JSON.stringify(this.business));
      this.$router.push({ name: this.previousStepName(this.currentRoute) });
    },
  },
};
</script>
