// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-container_kCI95{flex:1;margin-right:2rem}.info-container_kCI95 .info-box_bGYd_{background:linear-gradient(0deg,rgba(97,47,255,.08) 25%,#fff);border:1px solid #caa8ff;border-bottom-left-radius:4px;border-bottom-right-radius:4px;margin:auto;padding:2rem;display:flex;flex-direction:column;row-gap:20px}.info-container_kCI95 .info-box_bGYd_ .info-heading_aDQKo{font-weight:500;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;margin:0}.info-container_kCI95 .info-box_bGYd_ .info-item_gKEP7{display:flex}.info-container_kCI95 .info-box_bGYd_ .info-item_gKEP7 .info-item--icon_EkZhI{flex:1}.info-container_kCI95 .info-box_bGYd_ .info-item_gKEP7 .info-item--icon_EkZhI svg{width:2em;padding-top:4px}.info-container_kCI95 .info-box_bGYd_ .info-item_gKEP7 .info-item--text__2NN5{flex:6;font-size:12px}@media only screen and (max-width:800px){.info-container_kCI95 .info-box_bGYd_ .info-item_gKEP7 .info-item--text__2NN5{flex:5}.info-container_kCI95{padding:unset;margin:2rem 0 0 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-container": "info-container_kCI95",
	"info-box": "info-box_bGYd_",
	"info-heading": "info-heading_aDQKo",
	"info-item": "info-item_gKEP7",
	"info-item--icon": "info-item--icon_EkZhI",
	"info-item--text": "info-item--text__2NN5"
};
export default ___CSS_LOADER_EXPORT___;
