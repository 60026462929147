import { createAuth0 } from '@auth0/auth0-vue';

const search = window.location.search.substring(1);

const params = new URLSearchParams(search);

const screen = params.get('initialScreen');

export const auth0 = createAuth0({
  domain: `${process.env.AUTH0_DOMAIN}`,
  client_id: `${process.env.AUTH0_CLIENT_ID}`,
  redirect_uri: `${window.location.origin}/login${window.location.search}`,
  audience: `${process.env.AUTH0_AUDIENCE}`,
  ...(screen ? { initialScreen: screen } : {}),
});
