<template>
  <div :class="$style.wrapper">
    <section :class="$style.section">
      <h2 :class="[$style['heading-secondary'], $style.underline]">
        How Does Funding Work?
      </h2>
      <ProcessSteps :steps="processSteps" />
    </section>
    <section
      v-if="advertisedRates && advertisedRates.rates"
      :class="$style.section"
    >
      <h2 :class="$style['heading-secondary']">
        <span :class="$style.underline">What are the Estimated Fees?</span
        ><span :class="$style['heading-secondary__disclaimer']">*</span>
      </h2>
      <Calculator :show-bill-submit-cta="!isBusinessProfileComplete" />
      <p :class="$style.disclaimer">
        * The Extension Plans shown here are indicative amounts, and based on
        the information you have provided to us, as well as the typical fees we
        charge for the corresponding extension period. Additional terms and
        conditions apply. You may not be approved for your preferred Extension
        Plan, and your fees may be different based on the circumstances of your
        business. Funding is conditional on successful application. If approved,
        you will be informed of the option we may be able to provide to you, and
        you will have an opportunity to approve/reject such option. All currency
        conversion amounts are indicative amounts based on current rates, and
        are subject to change. Additionally, FX commissions may apply. All
        amounts are finalized prior to contract execution.
      </p>
    </section>
    <section :class="$style.section">
      <h2 :class="[$style['heading-secondary'], $style.underline]">
        What Can I Fund?
      </h2>
      <button
        :class="[$style.toggle, toggleActive ? $style['toggle--right'] : '']"
        @click="toggleFundType"
      >
        <span :class="$style.toggle__label">Invoices</span>
        <span :class="$style.toggle__label">Receipts</span>
      </button>
      <transition name="fade" mode="out-in">
        <AcceptanceCriteria
          v-if="!toggleActive"
          :content="acceptanceInvoices"
        />
        <AcceptanceCriteria v-else :content="acceptanceReceipts" />
      </transition>
      <p :class="$style.disclaimer">
        * Subject to approval. Additional terms and conditions apply.
      </p>
    </section>
    <section v-if="!isBusinessProfileComplete" :class="$style.section">
      <Cta />
    </section>
  </div>
</template>

<script>
import ProcessSteps from '@/components/how-it-works/ProcessSteps';
import Calculator from '@/components/how-it-works/Calculator';
import AcceptanceCriteria from '@/components/how-it-works/AcceptanceCriteria';
import Cta from '@/components/how-it-works/Cta';
import { mapGetters } from 'vuex';

const submissionStep = {
  description:
    'Submit an invoice or receipt and apply for extended payment terms',
  icon: 'file_present',
};

export default {
  components: { ProcessSteps, Calculator, AcceptanceCriteria, Cta },
  data() {
    return {
      toggleActive: false,
      submissionStep,
      processSteps: [
        {
          description:
            'We calculate your Funding Capacity based on your connected bank and revenue accounts',
          icon: 'query_stats',
        },
        {
          description:
            'Your Funding Capacity is ensured for 30 days from the time it is approved',
          icon: 'lock',
        },
        {
          ...submissionStep,
        },
        {
          description:
            'Your application will typically be reviewed within 2 business days',
          icon: 'nest_clock_farsight_analog',
        },
        {
          description:
            'Once approved, your Funding Capacity will be used towards your invoice or receipt',
          icon: 'price_change',
        },
        {
          description: 'Repay weekly over time via direct debit',
          icon: 'credit_score',
        },
      ],
      acceptanceInvoices: {
        criteria: [
          'Invoice cannot be more than 60 days overdue',
          'Invoice must be addressed to the customer we are funding',
          'Vendor name, country of operation and description of spend must be present',
          'Amount submitted for funding is equal or less than the total amount denoted on the invoice',
          'Payment must be issued in one of our supported currencies (EUR, GBP, CAD, USD)',
        ],
        categories: [
          'Inventory',
          'Marketing',
          'Shipping and Logistics',
          'Professional Services',
          'Other',
        ],
        categoriesLink: '',
        ctaLabel: 'Read more details here',
        ctaLink:
          'https://help.clear.co/s/article/How-to-submit-an-invoice?language=en_US',
      },
      acceptanceReceipts: {
        criteria: [
          'Receipt cannot be more than 60 days past the day of purchase',
          'Receipt must be addressed to the customer we are funding',
          'Vendor name, country of operation and description of spend must be present',
          'Receipt must be from the initial vendor, we do not accept credit card receipts',
          'We are unable to fund more than $1M worth of receipts in any given month',
        ],
        categories: [
          'Inventory',
          'Marketing',
          'Shipping and Logistics',
          'Professional Services',
          'Other',
        ],
        categoriesLink: '',
        ctaLabel: 'List of supported vendors',
        ctaLink:
          'https://help.clear.co/s/article/Approved-vendors?language=en_US',
      },
    };
  },
  computed: {
    ...mapGetters(['advertisedRates', 'isBusinessProfileComplete']),
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_ADVERTISED_FEES');
  },
  beforeMount() {
    this.moveSubmitInvoiceStepToFirstPosition();
  },
  methods: {
    toggleFundType() {
      this.toggleActive = !this.toggleActive;
    },
    moveSubmitInvoiceStepToFirstPosition() {
      this.moveStepToFirstPosition(this.submissionStep, this.processSteps);
    },
    moveStepToFirstPosition(step, stepsArray) {
      const index = stepsArray.findIndex(
        (s) => s.description === step.description,
      );
      if (index !== -1) {
        // Remove the step from the array and add it to the beginning
        stepsArray.splice(index, 1);
        stepsArray.unshift(step);
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="less" module>
.wrapper {
  max-width: 90%;
  margin: 0 auto;
}

@media screen and (min-width: 500px) {
  .wrapper {
    max-width: 85%;
  }
}

.section {
  padding: 50px 0 60px;
}

.section + .section {
  border-top: 1px solid #e2e2e2;
  box-shadow: inset 0 1px 0 #fff;
}

.underline {
  text-decoration-line: underline;
}

.wrapper h2.heading-secondary {
  color: #111111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: -10px 0 36px 0;
  position: relative;
}

.heading-secondary__disclaimer {
  color: #767676;
  transform: translateX(3px);
  display: inline-block;
}

.disclaimer {
  color: #767676;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin: 0;
}

.toggle {
  background: #fff;
  border-radius: 25px;
  display: inline-flex;
  position: relative;
  margin-bottom: 37px;
}

.toggle:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: #7f30ff;
  content: '';
  border-radius: 25px;
  transform: translateZ(-1px);
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1), background 0.2s;
}

.toggle:hover:before {
  background: #5b00f0;
}

.toggle--right:before {
  transform: translateX(100%);
}

.toggle__label {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1; /* 27px */
  padding: 16px 40px;
  transform-style: preserve-3d;
  transition: color 0.6s;
}

.toggle__label:first-child {
  color: #fff;
}

.toggle--right .toggle__label:first-child {
  color: #111;
}

.toggle--right .toggle__label:last-child {
  color: #fff;
}
</style>
