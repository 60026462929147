<template>
  <div>
    <h2 :class="$style.header__heading">Payment Obligations</h2>
  </div>

  <div
    v-if="
      currentDebtObligationDateChunk &&
      businessDebtObligations?.created_at &&
      debtObligationsRequired
    "
    :class="$style.prompt"
  >
    <i class="pi pi-clock"></i>
    Please provide your payment obligations for this period. You will be
    required to update these after
    {{ format(currentDebtObligationDateChunk.endDate, 'PPP') }}.
  </div>

  <div :class="$style.instructions">
    <h3 :class="$style.instructions__heading">
      <i
        class="pi pi-info-circle"
        :class="$style.instructions__heading__icon"
      ></i>
      What do I include?
    </h3>
    <div :class="$style.instructions__body">
      <p>
        We require you to update your payment obligations each month. For
        non-monthly payment obligations, please estimate the monthly payment.
        You can always return to add any missed items.
      </p>
      <p>
        <strong>Examples:</strong> Small Business Loans, Business Loans,
        Merchant Cash Advances, Revenue Share Agreements, Auto loans
      </p>
      <p>
        <a
          href="https://help.clear.co/s/article/How-to-apply-to-Clearco?language=en_US"
          target="_blank"
          >Learn More</a
        >
      </p>
    </div>
  </div>

  <div :class="$style['no-obligations']">
    <Checkbox
      v-model="userSelectedNoObligations"
      input-id="no-obligations"
      invalid
      binary
    />
    <label for="no-obligations"
      >I do <strong><u>not</u></strong> have payment obligations</label
    >
  </div>

  <div v-if="!userSelectedNoObligations" :class="$style['table-wrapper']">
    <table :class="$style.table">
      <tr>
        <th>Capital Provider</th>
        <th>Monthly Amount</th>
        <th></th>
      </tr>
      <tr v-if="isLoading">
        <td colspan="3">
          <i class="pi pi-spin pi-spinner"></i>
        </td>
      </tr>
      <tr v-for="(entry, index) in debtObligations">
        <td
          v-if="
            entry.name !== null &&
            (entry.name === 'Other' || !vendors.includes(entry.name))
          "
          :class="$style.table__vendor"
        >
          <InputText
            v-model="entry.name"
            type="text"
            :class="(entry.name === 'Other' || !entry.name) && $style.error"
            placeholder="Enter a Provider"
            @input="validateInput(entry)"
          />
          <i
            v-if="entry.adminOverride"
            class="pi pi-exclamation-triangle"
            :class="$style['admin-override__icon']"
          ></i>
        </td>
        <td v-else :class="$style.table__vendor">
          <Dropdown
            v-model="entry.name"
            :options="vendors"
            :class="!entry.name && $style.error"
            placeholder="Select a Provider"
            @change="nameDropdownChange(entry)"
          />
          <i
            v-if="entry.adminOverride"
            class="pi pi-exclamation-triangle"
            :class="$style['admin-override__icon']"
          ></i>
        </td>
        <td :class="$style.table__amount">
          <InputNumber
            v-model="entry.valueInDollars"
            mode="currency"
            currency="USD"
            placeholder="$0.00"
            :class="
              (!entry.valueInDollars || entry.valueInDollars === 0) &&
              $style.error
            "
            @input="(event) => numberInput(event, entry)"
          />
        </td>
        <td>
          <button
            :class="$style.table__remove"
            @click="removeObligation(index)"
          >
            <i class="pi pi-times-circle"></i>
          </button>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <button :class="$style.table__add" @click="addObligation">
            <i class="pi pi-plus"></i> Add An Item
          </button>
        </td>
        <td></td>
      </tr>
      <tr>
        <td><strong>Total</strong></td>
        <td>
          <strong>{{ formatCurrency(totalObligations) }}</strong>
        </td>
        <td></td>
      </tr>
      <tr v-if="adminOverrideEntryExists(debtObligations)">
        <td colspan="3">
          <div :class="$style['admin-override']">
            <i
              class="pi pi-exclamation-triangle"
              :class="$style['admin-override__icon']"
            ></i>
            <p :class="$style['admin-override__text']">
              Updated by Internal Team
            </p>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div
    class="c-form"
    :style="{
      textAlign: 'center',
    }"
  >
    <div class="c-form__controls justify-center" :style="{ margin: '0' }">
      <DSButton
        class="p-button-outlined"
        :label="$t('common.buttons.back')"
        @click="
          $router.push({
            name: isInFinancialDocsSegment
              ? PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS
              : PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
          })
        "
      />
      <DSButton
        :disabled="!userSelectedNoObligations && !alldebtObligationsAreValid"
        :label="$t('common.buttons.submit')"
        :loading="isSubmitting"
        @click="submitObligations"
      />
    </div>
    <DSButton
      :label="$t('common.buttons.skipForNow')"
      class="p-button-text p-button-info"
      severity="info"
      :style="{ marginTop: '1rem' }"
      @click="skipForNow"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import Checkbox from '@clearbanc/clear-components/checkbox';
import InputNumber from '@clearbanc/clear-components/inputnumber';
import InputText from '@clearbanc/clear-components/inputtext';
import Dropdown from '@clearbanc/clear-components/dropdown';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { centsToDollars, dollarsToCents } from '@/utils/currency';
import analytics from '@/utils/analytics';
import { format } from 'date-fns';

export default {
  components: { DSButton, Checkbox, InputText, InputNumber, Dropdown },
  props: {
    section: { type: String, required: true },
    theme: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      PAYMENTS_ROUTE_NAMES,
      debtObligations: [],
      userSelectedNoObligations: false,
      vendors: [
        '8fig',
        'Ampla',
        'Bank direct',
        'Bluevine',
        'Forward Finance',
        'Kabbage',
        'Ondeck Capital',
        'Parafin',
        'SellersFi',
        'Shopify',
        'Wayflyer',
        'Other',
      ],
      inputKey: 0, // This key is used to force re-render the InputText component
    };
  },
  computed: {
    ...mapGetters([
      'isInFinancialDocsSegment',
      'businessDebtObligations',
      'currentDebtObligationDateChunk',
      'businessId',
      'debtObligationsRequired',
    ]),
    totalObligations() {
      return this.debtObligations?.reduce(
        (acc, entry) => acc + entry.valueInDollars,
        0,
      );
    },
    alldebtObligationsAreValid() {
      if (!this.debtObligations?.length) {
        return false;
      }

      return this.debtObligations.every((entry) => {
        return entry?.name !== 'Other' && entry?.name && entry?.valueInDollars;
      });
    },
  },
  methods: {
    format,
    adminOverrideEntryExists(debtObligations) {
      return debtObligations.some((entry) => entry.adminOverride);
    },
    async submitObligations() {
      this.isSubmitting = true;

      if (this.userSelectedNoObligations) {
        await this.$store.dispatchApiAction('SAVE_DEBT_OBLIGATIONS', {
          debts: [],
        });
      } else {
        const obligationsToSubmit = this.convertToCents(
          this.clearAdminOverrides(this.debtObligations),
        );

        await this.$store.dispatchApiAction('SAVE_DEBT_OBLIGATIONS', {
          debts: obligationsToSubmit,
        });
      }

      this.isSubmitting = false;
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
    numberInput(event, obligationsEntry) {
      const entryToUpdate = this.debtObligations.find(
        (obligation) => obligation === obligationsEntry,
      );
      entryToUpdate.valueInDollars = event.value;
    },
    formatCurrency(value) {
      return value?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    validateInput(obligationToEdit) {
      if (!obligationToEdit || !obligationToEdit.name) {
        return;
      }

      const selectedObligation = this.debtObligations.find(
        (obligation) => obligationToEdit === obligation,
      );

      const sanitizedVendor = obligationToEdit.name.replace(
        /[^a-zA-Z0-9\s-]/g,
        '',
      );

      const updatedObligation = {
        ...selectedObligation,
        name: sanitizedVendor,
      };

      const index = this.debtObligations.indexOf(selectedObligation);
      if (index !== -1) {
        this.debtObligations.splice(index, 1, updatedObligation);
      }
    },
    convertToDollars(debtObligations) {
      return debtObligations.map((obligation) => {
        const updatedObligation = {
          ...obligation,
          valueInDollars: centsToDollars(obligation.valueInCents),
        };
        delete updatedObligation.valueInCents;
        return updatedObligation;
      });
    },
    convertToCents(debtObligations) {
      return debtObligations.map((obligation) => {
        const updatedObligation = {
          ...obligation,
          valueInCents: dollarsToCents(obligation.valueInDollars),
        };
        delete updatedObligation.valueInDollars;
        return updatedObligation;
      });
    },
    clearAdminOverrides(debtObligations) {
      return debtObligations.map((obligation) => {
        const updatedObligation = {
          ...obligation,
          adminOverride: false,
        };
        return updatedObligation;
      });
    },
    removeObligation(index) {
      this.debtObligations.splice(index, 1);
    },
    addObligation() {
      this.debtObligations.push({
        name: null,
        valueInDollars: null,
        adminOverride: false,
      });
    },
    nameDropdownChange(entry) {
      const entryIndex = this.debtObligations.findIndex(
        (obligation) => obligation === entry,
      );

      if (entry.name === 'Other') {
        this.debtObligations[entryIndex].name = '';
      }
    },
    skipForNow() {
      analytics.track('if_payment_obligations_skip', {
        businessId: this.businessId,
      });

      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_DEBT_OBLIGATIONS');

    if (this.businessDebtObligations?.debts) {
      this.debtObligations = this.convertToDollars(
        this.businessDebtObligations.debts,
      );
    }
  },
};
</script>

<style>
.justify-center {
  justify-content: center !important;
}
</style>

<style lang="less" module>
h2.header__heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 40px;
}

@media screen and (max-width: 500px) {
  .header__heading {
    text-align: left;
  }
}

.instructions {
  border: 1px solid #e0e0e1;
  border-radius: 4px;
  background: #f8f8fa;
  padding: 30px;
  max-width: 560px;
  margin: 0 auto 40px;
  text-align: left;
}

h3.instructions__heading {
  all: unset;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

i.instructions__heading__icon {
  font-size: 22px;
  margin-right: 10px;
}

.instructions__body {
  border-left: 1px solid #dfdfdf;
  padding-left: 20px;
  margin-left: 10px;
}

.instructions__body p a {
  color: #7f30ff;
}

.instructions__body p a:hover {
  color: #5b00f0;
}

// ---------------

.prompt {
  text-align: left;
  border-radius: 4px;
  padding: 20px 30px;
  background: #ffecbc;
  line-height: 1.4;
  display: flex;
  align-items: center;
  margin: 0 auto 20px;
  max-width: 560px;
  color: #684d25;
}

.prompt i {
  margin-right: 14px;
  color: #e8a524;
  font-size: 22px;
}

.table-wrapper {
  border-radius: 4px;
  border: 1px solid #e0e0e1;
  display: inline-block;
  margin: 0 auto 40px;
  width: 100%;
  max-width: 560px;
  overflow: scroll;
}

.table {
  width: 100%;
  text-align: right;
}

.table input {
  text-align: right;
}

.table th {
  border-bottom: 1px solid #e0e0e1;
}

table th,
table td {
  position: relative;
}

.table th:first-child,
.table th:first-child input,
.table td:first-child,
.table td:first-child input {
  text-align: left;
}

.table th,
.table td {
  padding: 10px 16px;
}

.table th + th,
.table td + td {
  padding-left: 0;
}

.table tr + tr {
  border-top: 1px dashed #e0e0e1;
}

.table tr + tr:nth-last-child(-n + 2) {
  border-style: solid;
}

.table td:last-child {
  text-align: center;
}

.table__add {
  color: #7f30ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.table__add:hover {
  color: #5b00f0;
}

.table__add i {
  margin-right: 6px;
}

.table__remove {
  color: #7f30ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
}

.table__remove:hover {
  color: #5b00f0;
}

.table__remove i {
  font-size: 22px;
}

.table__vendor {
  width: 50%;
  min-width: 200px;
}

.table__amount {
  width: 50%;
  min-width: 200px;
}

.error,
.error:hover,
.error:focus,
.error input {
  border-color: #fa2d40 !important;
}

.admin-override {
  display: flex;
  align-items: center;
}

.admin-override__text {
  margin: 0;
}

.admin-override__icon {
  position: absolute;
  top: 50%;
  right: calc(100% - 26px);
  transform: translateY(-50%);
  margin: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: green;
}

.admin-override .admin-override__icon {
  position: static;
  display: inline-block;
  transform: translateY(0);
  margin-right: 10px;
}

.no-obligations {
  max-width: 560px;
  text-align: left;
  margin: 0 auto 40px;
  display: flex;
  align-items: center;
}

.no-obligations label {
  margin-left: 10px;
  cursor: pointer;
}

@media (min-width: 500px) {
  .table-wrapper {
    overflow: visible;
  }

  .admin-override__icon {
    right: calc(100% - 9px);
  }
}
</style>
