<template>
  <div class="file-upload-widget">
    <template v-if="!noList">
      <div v-if="fetchUploadsRequest.isPending">
        {{ $t('common.buttons.loading') }}
      </div>
      <ul
        v-else
        class="upload-list"
        :class="deleteUploadRequest.isPending ? 'is-loading' : ''"
      >
        <li v-for="file in filteredUploads" :key="file.id">
          <span class="file-upload-widget__file-name">{{ file.name }}</span
          ><span class="file-upload-widget__upload-date">{{
            $t('components.uploadFileWidget.uploadedAt', {
              createdAt: file.createdAt,
              date: date,
            })
          }}</span>
          <v-button
            icon="trash"
            theme="transparent-dark"
            size="small"
            inline="inline"
            @click="deleteUpload(file.id)"
          >
            {{ $t('common.remove') }}
          </v-button>
        </li>
      </ul>
    </template>
    <div
      ref="dropzone"
      class="dropzone"
      :class="{
        hidden: hideable && singleUpload && fileIds.length > 0,
        'clearpay-dropzone': theme === 'onboarding',
      }"
      :style="getStyle"
      @click="$emit('dropzoneClicked')"
      @keyup.enter="$emit('dropzoneClicked')"
    >
      <div class="dz-message" data-dz-message>
        <span>{{ emptyPrompt }}</span>
      </div>
      <svg
        v-if="theme === 'onboarding' && fileCount === 0"
        class="icon"
        v-html="require('@/assets/icons/cloud-upload.svg')"
      ></svg>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { inject } from 'vue';
import 'dropzone/dist/dropzone.css';
import Dropzone from 'dropzone';
import * as Sentry from '@sentry/browser';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

export default {
  props: {
    emptyPrompt: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('components.uploadFileWidget.emptyPrompt');
      },
    },
    maxFilesizeMb: { type: Number, default: 10 },
    acceptedFileTypes: {
      type: String,
      default:
        'image/jpeg,image/png,application/pdf,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    uploadFilters: Object,
    displayFilters: { type: Object, required: false },
    addRemoveLinks: { type: Boolean, default: false },
    processLocally: { type: Boolean, default: false },
    noList: Boolean,
    singleUpload: { type: Boolean, default: false },
    theme: { type: String, default: 'default' },
    hideable: { type: Boolean, default: true },
    autoUpload: { type: Boolean, default: true },
  },
  data() {
    return {
      uploadDelayMs: 0,
      processDelayMs: 0,
      fileIds: [],
      fileCount: 0,
      isDragUpload: false,
      numDocsAdded: 0,
      numFailedDocsAdded: 0,
      errorOccurred: false,
    };
  },
  computed: {
    ...mapRequestStatuses({
      fetchUploadsRequest: 'FETCH_USER_UPLOADS',
      getUploadUrlRequest: 'GET_SIGNED_UPLOAD_URL',
      addUploadRequest: 'ADD_USER_UPLOAD',
      deleteUploadRequest: 'DELETE_USER_UPLOAD',
    }),
    filteredUploads() {
      return this.$store.getters.uploadsMatching(
        this.displayFilters ?? this.uploadFilters,
      );
    },
    getStyle() {
      if (this.theme === 'onboarding') {
        return {
          backgroundColor: '#FCFCFC',
          'border-width': '1px',
          'border-radius': '5px',
        };
      }
      return {};
    },
  },
  mounted() {
    Dropzone.autoDiscover = false;
    // see http://www.dropzonejs.com/#configuration-options
    const options = {
      method: this.processLocally ? 'head' : 'put', // PUT upload to S3 directly
      url: '/', // we will fill in URL and headers for each file
      header: '',
      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        const _send = xhr.send; // eslint-disable-line
        xhr.send = () => {
          _send.call(xhr, file);
        };
      },
      parallelUploads: 1,
      uploadMultiple: false,
      ...(this.singleUpload && { maxFiles: 1 }),
      maxFilesize: this.maxFilesizeMb,
      addRemoveLinks: this.addRemoveLinks,
      resizeWidth: 1200,
      resizeHeight: 1200,
      // createImageThumbnails: false,
      // Customize the wording
      ...(this.emptyPrompt && { dictDefaultMessage: this.emptyPrompt }),
      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,
      acceptedFiles: this.acceptedFileTypes,
      // Here we request a signed upload URL when a file being accepted
      accept: this.handleNewFile,
    };
    // Instantiate Dropzone
    this.dropzone = new Dropzone(this.$refs.dropzone, options);
    // Set signed upload URL for each file
    this.dropzone.on('processing', (file) => {
      this.$store.commit('SET_BROWSER_UPLOADING_FILE', true);
      this.dropzone.options.url = file.uploadUrl;
    });
    this.dropzone.on('addedfile', (file) => {
      this.$emit('uploadAttempt');
      if (this.singleUpload) {
        // remove file if it exists
        if (this.dropzone.files[1] != null) {
          this.dropzone.removeFile(this.dropzone.files[0]);
        }
      }
      if (this.processLocally) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit('complete', e.target.result);
        };
        reader.readAsText(this.dropzone.files[0]);
      }
      this.fileCount++;
    });
    this.dropzone.on('complete', async (file) => {
      if (!this.processLocally && file.uploadUrl && !this.errorOccurred) {
        const urlObj = new URL(file.uploadUrl);
        const pathParts = urlObj.pathname.split('/');
        file.bucket = pathParts[1];
        file.key = pathParts[2];
        file.storage = urlObj.hostname;
        await this.createUploadInDB(file);
        this.dropzone.removeFile(file);
        this.$emit('docUploaded', file);
      }
      this.$store.commit('SET_BROWSER_UPLOADING_FILE', false);
    });
    this.dropzone.on('queuecomplete', (file) => {
      const numSuccessfulDocsAdded =
        this.numDocsAdded - this.numFailedDocsAdded;
      if (numSuccessfulDocsAdded > 0) {
        this.$emit('docsUploaded', {
          numDocs: numSuccessfulDocsAdded,
          isDragUpload: this.isDragUpload,
        });
      }
      this.isDragUpload = false;
      this.numDocsAdded = 0;
      this.numFailedDocsAdded = 0;
    });
    this.dropzone.on('error', (file, message) => {
      this.dropzone.removeFile(file);
      this.numFailedDocsAdded++;
      this.$emit('uploadFailed', {
        errorMessage: message,
        isDragUpload: this.isDragUpload,
      });
      if (message !== 'Upload canceled.') {
        this.errorOccurred = true;
        this.$toast.add({
          severity: 'error',
          detail:
            'File upload failed, please try again or contact support if the issue persists',
          life: 3000,
        });
      }
      Sentry.captureException(new Error('File upload failed'), {
        extra: { message, file },
      });
    });
    this.dropzone.on('removedfile', (file) => {
      this.fileCount--;
    });
    this.dropzone.on('drop', (event) => {
      this.isDragUpload = true;
    });
    this.dropzone.on('addedfiles', (files) => {
      this.numDocsAdded = files.length;
      if (this.isDragUpload) {
        this.$emit('uploadDragged', this.numDocsAdded);
      }
    });
  },
  beforeUnmount() {
    this.dropzone.destroy();
  },
  methods: {
    async handleNewFile(file, done) {
      this.errorOccurred = false;
      // we dont use async/await here because dropzone expects this fn to use a callback
      if (!this.processLocally && !this.autoUpload) {
        this.$emit('docUploaded', file);
        done();
      } else if (!this.processLocally) {
        try {
          const url = await this.getSignedUrl(file);
          file.uploadUrl = url;
          setTimeout(() => this.dropzone.processFile(file));
          done();
        } catch (err) {
          this.$emit('uploadFailed', {
            errorMessage: err.message,
            isDragUpload: this.isDragUpload,
          });
          done('Failed to get signed upload URL', err);
        }
      } else {
        file.uploadUrl = '/'; // upload to nowhere
        setTimeout(() => this.dropzone.processFile(file));
        done();
      }
    },
    async processFiles(uploadFilters) {
      const dropzoneUploadPromises = [];

      if (uploadFilters) {
        Object.assign(this.uploadFilters, uploadFilters);
      }

      this.dropzone.getQueuedFiles().map(async (file) => {
        file.uploadUrl = await this.getSignedUrl(file);
        dropzoneUploadPromises.push(
          new Promise((resolve) => {
            this.dropzone.processFile(file);
            resolve();
          }),
        );
      });

      await Promise.all(dropzoneUploadPromises);
    },
    async getSignedUrl(file) {
      const response = await this.$store.dispatchApiAction(
        'GET_SIGNED_UPLOAD_URL',
        {
          ..._.pick(file, 'name', 'size'),
          contentType: file.type,
        },
      );
      return response.uploadUrl;
    },
    async createUploadInDB(file) {
      const upload = await this.$store.dispatchApiAction('ADD_USER_UPLOAD', {
        ..._.pick(file, 'name', 'size', 'key', 'url'),
        contentType: file.type,
        ...this.uploadFilters,
      });
      if (!this.addUploadRequest.isSuccess) {
        this.$emit('apiError', this.addUploadRequest.error);
        return;
      }
      this.fileIds.push(upload.id);
      if (this.fileIds.length) {
        if (this.fileCount === 1) {
          this.$emit('complete', this.fileIds[0]); // backward compatibility
        } else {
          this.$emit('complete', this.fileIds);
        }
      }
    },
    deleteUpload(id) {
      if (!this.processLocally) {
        this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
      }
    },
    removeAllFiles() {
      this.dropzone.removeAllFiles();
    },
  },
};
</script>

<style lang="less">
.file-upload-widget {
  .button {
    max-width: 200px;
  }
  .dropzone {
    border: 2px dashed @clearpay-upload-zone-black;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    min-height: 60px;
    &:hover {
      background: @clearpay-upload-zone-black;
    }
    &.hidden {
      display: none;
    }
    .icon {
      height: 35px;
      width: 42px;
      pointer-events: none;
    }
    .dz-message {
      font-style: normal;
      font-size: 12px;
      line-height: 1.4em;
      margin: 0;
      opacity: 0.8;
    }
  }
  .clearpay-dropzone {
    border: 1px solid @clearpay-upload-zone-black !important;
    flex-flow: column;
    .dz-message {
      color: @medium-gray;
      font-size: 1rem;
      margin: 15px 0;
    }
  }
  .upload-list {
    padding-left: 20px;
    .upload-date {
      font-style: italic;
      font-size: 12px;
      line-height: 1.4em;
    }
    &.is-loading {
      opacity: 0.7;
      pointer-events: none;
    }
  }
  .file-upload-widget__file-name {
    margin-right: 10px;
  }
  .file-upload-widget__upload-date {
    font-size: 14px;
    font-style: italic;
    margin-right: 10px;
  }
}
</style>
