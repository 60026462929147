// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heading_GC_T9{margin-bottom:2rem}.heading_GC_T9 p{margin:0}.form_HiyLm{text-align:left;padding:0}.error_h34BW{margin:.5rem 0 0}button.back-button_sX7M6{margin-right:auto;padding:0}.error_h34BW{margin-top:.5rem;font-weight:400}.icon_ZOYuz{height:12px;width:12px;cursor:pointer}.tooltip_L1CMv{color:#000;padding:4px;cursor:help}.link_knj1D{color:#145bce}@media only screen and (min-width:768px){.bill-details-form_DGg_U{min-width:560px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading_GC_T9",
	"form": "form_HiyLm",
	"error": "error_h34BW",
	"back-button": "back-button_sX7M6",
	"icon": "icon_ZOYuz",
	"tooltip": "tooltip_L1CMv",
	"link": "link_knj1D",
	"bill-details-form": "bill-details-form_DGg_U"
};
export default ___CSS_LOADER_EXPORT___;
