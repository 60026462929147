// example:
// export const MUTATION_NAME = 'MUTATION_NAME';

import { makeAsyncMutationTypes } from '@/utils/vuex-api-utils';

export const CREATE_ADVANCE = makeAsyncMutationTypes('CREATE_ADVANCE');
export const GET_USER_ADVANCES = makeAsyncMutationTypes('GET_USER_ADVANCES');
export const CREATE_BNPL_ADVANCE = makeAsyncMutationTypes(
  'CREATE_BNPL_ADVANCE',
);
export const SELECT_ADVANCE_OFFER = makeAsyncMutationTypes(
  'SELECT_ADVANCE_OFFER',
);
export const SIGN_ADVANCE_CONTRACT = makeAsyncMutationTypes(
  'SIGN_ADVANCE_CONTRACT',
);

export const ADJUDICATE_BUSINESS = makeAsyncMutationTypes(
  'ADJUDICATE_BUSINESS',
);

export const GET_ADJUDICATION = makeAsyncMutationTypes('GET_ADJUDICATION');

export const SIGN_CLEARCARD_CONTRACT = makeAsyncMutationTypes(
  'SIGN_CLEARCARD_CONTRACT',
);

export const REFRESH_ADVANCE_SIGNATURE_STATUS = makeAsyncMutationTypes(
  'REFRESH_ADVANCE_SIGNATURE_STATUS',
);

export const OAUTH_CONNECT = makeAsyncMutationTypes('OAUTH_CONNECT');
export const GET_ECOM_REVENUE = makeAsyncMutationTypes('GET_ECOM_REVENUE');

export const FETCH_FACEBOOK_AD_ACCOUNT_INFO = makeAsyncMutationTypes(
  'FETCH_FACEBOOK_AD_ACCOUNT_INFO',
);

export const SET_AUTOCOMPLETE = 'SET_AUTOCOMPLETE';
export const GLOBAL_AUTOCOMPLETE_LOADED = 'GLOBAL_AUTOCOMPLETE_LOADED';

// regular (non async/api related) actions
export const SET_SIGNUP_PARAMS = 'SET_SIGNUP_PARAMS';

export const SET_SIGNED_AT = 'SET_SIGNED_AT';

export const SET_CURRENT_STATE_MODULE = 'SET_CURRENT_STATE_MODULE';
