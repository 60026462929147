<template>
  <div
    v-if="shownBankAccounts?.length"
    :class="$style['bank-accounts-wrapper']"
  >
    <fieldset
      v-if="visibleBankAccounts.length"
      :class="$style['bank-accounts']"
    >
      <legend :class="$style['bank-accounts__legend']">
        <BaseIcon
          icon-name="Arrow"
          :class="$style['bank-accounts__legend__icon']"
        />
        <h2 :class="$style['bank-accounts__legend__heading']">
          Select Primary Account
        </h2>
      </legend>
      <ConnectedBankAccount
        v-for="account in visibleBankAccounts"
        :key="account.id"
        :account-id="account.id"
        :bank-name="account.bankName"
        :account-name="account.subtype"
        :account-number="`*** *** ${account.accountNumberLast4}`"
        :status="account.connection.plaidStatus.summary"
        :action="account.connection.plaidStatus.action"
        :is-primary="account.id === bankAccountIdToBePrimary"
        :plaid-checks="allBankAccountChecks[account.id]"
        :contract-signed="getContractSignedStatus(account)"
        :can-sign-contract="checkForContractSignatureRequirements(account)"
        :has-contract="hasContract(account.id)"
        @bank-account-clicked="handleClick(account)"
        @relink="$emit('relink', account)"
        @connect-new="$emit('connectNew')"
        @sign-contract="handleSignContract(account.id)"
      />
    </fieldset>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConnectedBankAccount from '@/components/ConnectedBankAccount';
import BaseIcon from '@/components/icons/base-icon';
import { useContextRoot } from '@/utils/context-root';
import contractUtils from '@/composables/contracts';

export default {
  components: {
    ConnectedBankAccount,
    BaseIcon,
  },
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { signAchContract } = contractUtils(context, ctxRoot);
    return { signAchContract };
  },
  emits: [
    'relink',
    'connectNew',
    'showActionRequiredModal',
    'update:bankAccountIdToBePrimary',
  ],
  props: {
    bankAccountIdToBePrimary: {
      required: true,
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  computed: {
    ...mapGetters([
      'visibleBankAccounts',
      'businessId',
      'currentBankAccountToBePrimary',
      'primaryBankAccount',
      'shownBankAccounts',
      'allBankAccountChecks',
      'isReorderBankingStepsEnabled',
      'billContracts',
      'bankAccountContracts',
    ]),
    redirectedFromDocusign() {
      return this.$route.query.event === 'signing_complete';
    },
    bankAccountIdInUrl() {
      return Number(this.$route.query.bankAccountId);
    },
  },
  async mounted() {
    await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
    await this.updateBankAccountChecks();

    if (!this.isReorderBankingStepsEnabled) {
      this.setBankAccountIdToBePrimary(
        this.currentBankAccountToBePrimary?.id || this.primaryBankAccount?.id,
      );
    } else if (this.visibleBankAccounts.length) {
      const bankAccountId =
        this.redirectedFromDocusign && this.bankAccountIdInUrl
          ? this.bankAccountIdInUrl
          : this.primaryBankAccount?.id;
      this.setBankAccountIdToBePrimary(bankAccountId);
    }
  },
  methods: {
    hasContract(bankAccountId) {
      return !!this.bankAccountContracts[bankAccountId];
    },
    getContractSignedStatus(account) {
      const combinedBankAccountAndBillContract = this.billContracts.find(
        (billContract) => billContract.bankAccountsId === account.id,
      );
      return (
        !!this.bankAccountContracts[account.id]?.userSignedAt ||
        !!combinedBankAccountAndBillContract?.userSignedAt
      );
    },
    checkForContractSignatureRequirements(account) {
      return !!(account.accountNumber && account.routingNumber);
    },
    async handleSignContract(bankAccountId) {
      await this.signAchContract(bankAccountId);
    },
    handleClick(account) {
      const contractSigned = this.getContractSignedStatus(account);
      const canSignContractForAccount =
        this.checkForContractSignatureRequirements(account);
      if (
        this.isReorderBankingStepsEnabled &&
        !contractSigned &&
        canSignContractForAccount
      ) {
        this.$emit('showActionRequiredModal');
      }
      this.$emit('update:bankAccountIdToBePrimary', account.id);
    },
    setBankAccountIdToBePrimary(id) {
      this.$emit('update:bankAccountIdToBePrimary', id);
    },
    async updateBankAccountChecks() {
      const promises = [];

      Object.keys(this.visibleBankAccounts).forEach((key) => {
        const item = this.visibleBankAccounts[key];

        promises.push(
          this.$store.dispatchApiAction('GET_BANK_ACCOUNT_CHECKS', {
            businessId: item.businessId,
            bankAccountId: item.id,
          }),
        );
      });

      await Promise.all(promises);
    },
  },
};
</script>

<style lang="less" module>
.container {
  max-width: 350px;
  margin: 0 auto;
  .bank-tile-container {
    width: 350px;
    border-radius: 7px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border: 0.75px solid @todo-section-border-gray;
    margin: 10px auto 20px;
    padding: 30px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.non-primary {
      background: @whiter-gray;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .label {
        text-align: left;
        font-weight: 500;
        max-width: 50%;
      }

      .primary-container {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;

        .primary-text {
          font-size: 14px;
          text-align: center;
          color: @cc-light-brown;
        }

        .checkbox {
          padding-left: 2px;
          margin-bottom: unset;
        }
      }
    }

    .card-number {
      padding-top: 25px;
      font-weight: 500;
      text-align: left;
      margin: unset;
    }

    @media only screen and (max-width: 500px) {
      width: unset;
      padding: 30px 20px;
    }
  }

  .button {
    margin-top: 25px;
    width: 100%;
    @media only screen and (max-width: 500px) {
      float: unset;
    }
  }
}

.bank-accounts-wrapper {
  margin-top: 40px;
  margin-bottom: 60px;
}

.bank-accounts {
  margin-bottom: 60px;
}

.bank-accounts__legend {
  text-align: left;
  padding: 0 24px 20px;
  display: flex;
  align-items: center;
}

.bank-accounts__legend__icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-left: 6px;
  transform: translateY(8px);
  svg {
    fill: #e8e8ea;
    stroke: none;
  }
}

.bank-accounts__legend h2.bank-accounts__legend__heading {
  font-family: @gerstner-font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #000000;
  margin: 0;
}
</style>
