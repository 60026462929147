<template>
  <Modal
    v-model:visible="showModal"
    :modal="true"
    :draggable="false"
    class="modal-bill-details-actions"
    append-to="self"
    @hide="done"
  >
    <template #header>
      <span v-if="bill.isReceipt" class="p-dialog-title">Receipt Details</span>
      <SelectButton
        v-else
        v-model="selectedTab"
        :options="switchableOptions"
        option-label="label"
        option-value="value"
      />
    </template>
    <div :class="$style.timeline">
      <div v-if="bill.isReceipt">
        <p v-if="step === 0" :class="$style['timeline__header--sm']">
          {{
            $t('account.pages.pageVendors.billDetails.id', {
              type: uploadType,
            })
          }}:
          <span :class="$style['timeline__header--b']"
            >#{{ getShortBillId(this.bill.id) }}</span
          >
        </p>
        <BillStatusTimeline
          v-if="step === 0"
          :class="$style['status-timeline']"
          :bill-contract="billContract"
          :loading="refreshBnplContractRequest.isPending"
          :bill="bill"
          @goToVendorTab="selectedTab = 'vendor'"
        />
        <BillDetailsPaymentsTimeline
          v-if="step === 0 && scheduledTransactions.length > 0"
          :scheduled-transactions="scheduledTransactions"
        />
        <BillDetailsSummary
          v-if="step === 0"
          :bill="bill"
          :bill-contract="billContract"
        />
        <LinkCancelBill v-if="step === 0" :bill="bill" @next-tab="next" />
        <CancelBillConfirmation
          v-if="step === 1"
          :due-date="bill.dueDate"
          :bill-id="bill.id"
          is-receipt
          @prev-tab="prev"
          @done="done"
          @newPayment="this.$emit('newPayment')"
        />
      </div>
      <div v-else>
        <div v-if="selectedTab === 'bill'">
          <p v-if="step === 0" :class="$style['timeline__header--sm']">
            {{
              $t('account.pages.pageVendors.billDetails.id', {
                type: uploadType,
              })
            }}:
            <span :class="$style['timeline__header--b']"
              >#{{ getShortBillId(this.bill.id) }}</span
            >
          </p>
          <BillStatusTimeline
            v-if="step === 0"
            :bill-contract="billContract"
            :loading="refreshBnplContractRequest.isPending"
            :class="$style['status-timeline']"
            :bill="bill"
            @goToVendorTab="selectedTab = 'vendor'"
          />
          <BillDetailsPaymentsTimeline
            v-if="step === 0 && scheduledTransactions.length > 0"
            :scheduled-transactions="scheduledTransactions"
          />
          <BillDetailsSummary
            v-if="step === 0"
            :bill="bill"
            :bill-contract="billContract"
          />
          <LinkCancelBill v-if="step === 0" :bill="bill" @next-tab="next" />
          <CancelBillConfirmation
            v-if="step === 1"
            :due-date="bill.dueDate"
            :is-receipt="bill.isReceipt"
            :bill-id="bill.id"
            @prev-tab="prev"
            @done="done"
          />
        </div>
        <BeneficiaryDetails v-else v-model:bill="bill" />
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import analytics from '@/utils/analytics';
import SelectButton from '@clearbanc/clear-components/selectbutton';
import Modal from '@clearbanc/clear-components/dialog';
import { cloneDeep } from 'lodash';

import BeneficiaryDetails from '@/components/payments/BeneficiaryDetails';

import {
  BillPaymentMethods,
  ScheduledTransactionType,
  CLEARPAY_SEGMENT_EVENTS,
} from '@/data/payments';
import { getShortBillId } from '@/utils/payments';
import BillStatusTimeline from './BillStatusTimeline';

import CancelBillConfirmation from './CancelBillConfirmation';
import LinkCancelBill from './LinkCancelBill';
import BillDetailsSummary from './BillDetailsSummary';
import BillDetailsPaymentsTimeline from './BillDetailsPaymentsTimeline';

export default {
  components: {
    BeneficiaryDetails,
    Modal,
    SelectButton,
    CancelBillConfirmation,
    BillStatusTimeline,
    BillDetailsSummary,
    BillDetailsPaymentsTimeline,
    LinkCancelBill,
  },
  data() {
    return {
      bill: null,
      step: 0,
      showModal: false,
      selectedTab: 'bill',
      switchableOptions: [
        {
          label: this.$t(
            'account.pages.pageVendors.billDetails.invoiceDetails',
          ),
          value: 'bill',
        },
        {
          label: this.$t('account.pages.pageVendors.billDetails.vendorDetails'),
          value: 'vendor',
        },
      ],
      billContract: null,
    };
  },
  computed: {
    ...mapGetters([
      'scheduledTransactions',
      'selectedBillBeneficiary',
      'business',
      'bankAccounts',
      'currentBankAccountToBePrimary',
    ]),
    ...mapRequestStatuses({
      refreshBnplContractRequest: ['REFRESH_BNPL_CONTRACT'],
    }),
    uploadType() {
      return this.bill.isReceipt
        ? this.$t('account.pages.pageVendors.billDetails.receipt')
        : this.$t('account.pages.pageVendors.billDetails.invoice');
    },
  },
  async mounted() {
    // when users return from docusign, we need to extract the result (signed/declined) and the bill to open
    const docusignEvent = this.$route?.query?.event;
    const billId = this.$route?.query?.billId;

    // Clean up the url
    if (docusignEvent) {
      let newURL = window.location.href.split('?')[0];
      newURL = newURL.substring(0, newURL.lastIndexOf('/payments') + 9);
      this.$route.query.event = undefined;
      window.history.pushState('object', document.title, newURL);
    }

    if (docusignEvent && billId) {
      await this.handleDocusignEvent(docusignEvent, billId);
    }
  },
  methods: {
    getShortBillId,
    done() {
      this.step = 0;
      this.showModal = false;
      this.$emit('done');
    },
    async open(bill) {
      this.bill = await this.$store.dispatchApiAction('GET_BILL', {
        id: bill.id,
      });
      this.selectedTab = 'bill';
      this.getFileUpload(this.bill.uploadId);
      this.getBillContract(this.bill.id);
      if (this.bill.paymentMethod === BillPaymentMethods.BNPL) {
        await this.getScheduledTransactions(this.bill.id);
      }
      this.showModal = true;
    },
    async getFileUpload(uploadId) {
      await this.$store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
        id: uploadId,
        download: true,
      });
    },
    async getBillContract(billId) {
      const response = await this.$store.dispatchApiAction(
        'GET_BNPL_CONTRACT',
        { billId },
      );
      if (response?.billContract) {
        this.billContract = response.billContract;
      } else {
        this.billContract = null;
      }
    },
    async getScheduledTransactions(billId) {
      await this.$store.dispatchApiAction('GET_TRANSACTIONS_BY_BILL_ID', {
        billId,
        type: ScheduledTransactionType.PAYMENT,
      });
    },
    close() {
      this.step = 0;
      this.$emit('done');
    },
    async next() {
      this.step++;
    },
    prev() {
      if (this.step) this.step--;
    },
    async updatePrimaryBankAccount() {
      const accountToBePrimary = this.currentBankAccountToBePrimary;

      if (accountToBePrimary) {
        const clonedDetails = cloneDeep(accountToBePrimary).details;
        delete clonedDetails?.setAsPrimary;

        await this.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
          id: accountToBePrimary.id,
          action: 'set-primary',
          isPrimary: true,
        });

        await this.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
          id: accountToBePrimary.id,
          details: Object.keys(clonedDetails).length ? clonedDetails : null,
        });
      }
    },
    async handleDocusignEvent(event, id) {
      if (
        event === 'signing_complete' ||
        event === 'cancel' ||
        event === 'decline' ||
        event === 'viewing_complete'
      ) {
        const response = await this.$store.dispatchApiAction(
          'REFRESH_BNPL_CONTRACT',
          {
            billId: id,
          },
        );
        if (response.billContract) {
          this.billContract = response.billContract;
        }
        if (event === 'signing_complete') {
          await this.updatePrimaryBankAccount();

          analytics.track(CLEARPAY_SEGMENT_EVENTS.SIGNED_BNPL_CONTRACT, {
            businessId: this.business.id,
            businessName: this.business.name,
            shortBillId: getShortBillId(id),
            vendorName: this.selectedBillBeneficiary.name,
          });
          analytics.track('if_contract_signed', {
            businessId: this.business.id,
            businessName: this.business.name,
            shortBillId: getShortBillId(id),
            billId: id,
            vendorName: this.selectedBillBeneficiary.name,
          });
        } else if (event === 'cancel') {
          analytics.track('if_contract_exit', {
            businessId: this.business.id,
            businessName: this.business.name,
            shortBillId: getShortBillId(id),
            billId: id,
            vendorName: this.selectedBillBeneficiary.name,
          });
        }
      }
    },
  },
};
</script>

<style lang="less">
.p-dialog.modal-bill-details-actions {
  .p-dialog-header {
    justify-content: space-between;

    @media only screen and (min-width: 340px) {
      justify-content: center;
    }

    .p-selectbutton {
      @media only screen and (min-width: 501px) {
        margin: 0 2.5rem;
      }

      .p-button {
        @media only screen and (max-width: 380px) {
          padding: 0.5rem;
        }
      }
    }

    .p-dialog-header-icons {
      @media only screen and (max-width: 340px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
<style lang="less" scoped>
:deep(.p-panel) {
  max-width: 382px;
  margin: 2rem auto;

  @media only screen and (max-width: 500px) {
    padding: 0 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

:deep(.p-timeline-event-opposite) {
  display: none;
}

:deep(.p-timeline-event-content) {
  padding: 0 0.5rem !important;
}
</style>
<style lang="less" module>
.container {
  padding: 0;
  margin: 0 auto;
  min-height: unset;

  h2,
  h3,
  h4 {
    font-family: @gerstner-font;
  }

  @media only screen and (max-width: 500px) {
    text-align: left;
  }
}

.timeline {
  .timeline__header--sm {
    font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: @lighter-black;
    margin: 0 0 4px 0;
  }

  .timeline__header--b {
    font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: @black;
    text-align: center;
    margin: 0 0 23px 0;
  }

  .timeline__cancel {
    text-align: center;

    a {
      font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
      font-size: 16px;
      font-weight: 300;
      cursor: pointer;
      color: @color-info-400;
    }
  }
}
</style>
