import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default function (context) {
  const { $store, $router } = context.root;

  const rerouteUser = async () => {
    await $store.dispatch('refreshAdvances');
    await $store.dispatch('UPDATE_ALL_PAYMENTS_ONBOARDING_STEPS');
    /**
     * Routing is based https://docs.google.com/spreadsheets/d/1IZxSUOd6E4yb2qnkXSro2OI9JhicVDCFlke7DTZgBJE/edit#gid=0
     *
     * At this point in time the logic is as follows:
     * Case 1. If the user hasnt completed sign up, lets force them through that flow.
     * Case 2. Net New
     *  a. ALL = /pay-vendors/payments
     * Case 3. ACTIVE MCA aka "ValidAdvance"
     *  a. ALL = /spend
     * Case 4. NO ACTIVE MCA BUT HAS PAST MCA
     *  a. US/CA = /pay-vendors/payments
     *  b. INT = /advances
     * Case 5. CLOSED / LOST
     *  a. ALL = /pay-vendors/payments
     */

    // Check if theyve completed clearpay sign up
    if (
      $store.getters.firstIncompletePaymentsOnboardingStep.name !==
      PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE
    ) {
      // Case 1
      $router
        .push({
          name: $store.getters.firstIncompletePaymentsOnboardingStep.name,
        })
        .catch(() => {});
    } else if ($store.getters.activeValidAdvanceOrAvailableValidSpend) {
      // Case 3
      $router.push({ name: 'spend' }).catch(() => {});
    } else if ($store.getters.userHasSettledValidAdvances) {
      // Case 4A/B
      const routeName =
        $store.getters.isCABusiness || $store.getters.isUSBusiness
          ? PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE
          : 'advances';
      $router.push({ name: routeName }).catch(() => {});
    } else if ($store.getters.isClosedLostValidAdvanceUser) {
      // Case 5
      $router
        .push({ name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE })
        .catch(() => {});
    } else {
      // Case 2
      $router
        .push({
          name: $store.getters.firstIncompletePaymentsOnboardingStep.name,
        })
        .catch(() => {});
    }
  };

  return {
    rerouteUser,
  };
}
