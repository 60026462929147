<template>
  <div :class="$style['container']">
    <div v-show="!isLoading">
      <Message
        v-if="isBeneficiaryUpdated"
        severity="success"
        :closable="false"
        :class="$style.message"
      >
        <p>{{ $t('paymentsPage.vendorUpdateSuccess') }}</p>
      </Message>

      <p v-if="isInvoiceUploadStep" :class="$style.description">
        {{ $t('paymentsPage.confirmYourVendorsBusiness') }}
      </p>

      <VeeForm
        ref="form"
        v-slot="{ setFieldValue, setValues, values }"
        class="c-form"
        :class="$style.form"
        :validation-schema="schema"
        :initial-values="initialValues"
        @submit="onSubmit"
      >
        <div class="c-group">
          <div class="c-group__header">
            <h3 class="c-group__header__title">
              {{ $t('paymentsPage.businessDetails') }}
            </h3>
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <InputTextGroup
                name="name"
                :label="$t('paymentsPage.vendorName')"
                :disabled="!editable"
                @blur="() => addBeneficiaryFormField({ name: values.name })"
              />
            </div>
            <div class="col-span-6">
              <InputTextGroup
                name="website"
                :label="$t('common.website')"
                type="url"
                :disabled="!editable"
                @blur="
                  () => addBeneficiaryFormField({ website: values.website })
                "
              />
            </div>
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <InputTextGroup
                name="email"
                :label="$t('common.email')"
                :disabled="!editable"
                type="email"
                @blur="() => addBeneficiaryFormField({ email: values.email })"
              />
            </div>
            <div class="col-span-6">
              <InputTextGroup
                name="phone"
                :label="$t('common.phone')"
                :disabled="!editable"
                type="tel"
                @blur="() => addBeneficiaryFormField({ phone: values.phone })"
              />
            </div>
          </div>
          <div class="c-form__row grid">
            <div :class="$style.divider"></div>
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <AutoCompleteAddress
                name="address"
                :label="$t('common.address.addressLine1')"
                :disabled="!editable"
                @addressSelected="addressSelect($event, setValues, values)"
              />
            </div>
            <div class="col-span-6">
              <InputTextGroup
                name="addressLine2"
                :label="$t('common.address.addressLine2')"
                :disabled="!editable"
                @blur="
                  () =>
                    addBeneficiaryFormField({
                      addressLine2: values.addressLine2,
                    })
                "
              />
            </div>
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <DropdownGroup
                name="country"
                :label="$t('common.address.country')"
                reset-filter-on-hide
                :filter="true"
                option-label="nameString"
                :options="countries"
                :placeholder="$t('common.selectOption')"
                :disabled="!editable"
                append-to="self"
                @change="
                  ({ value }) => {
                    setValues({
                      ...values,
                      country: value,
                      state: null,
                      postalCode: null,
                    });
                    addBeneficiaryFormField({ country: value });
                  }
                "
              />
            </div>
            <div class="col-span-6">
              <DropdownGroup
                v-if="stateOptionsForCountry(values.country?.value)"
                name="state"
                :label="stateLabel(values.country)"
                :filter="true"
                reset-filter-on-hide
                option-label="nameString"
                :options="states(values.country?.value)"
                :placeholder="$t('common.selectOption')"
                :disabled="!editable"
                append-to="self"
                @change="
                  ({ value }) => {
                    setFieldValue('state', value);
                    addBeneficiaryFormField({ state: value });
                  }
                "
              />
              <InputTextGroup
                v-else
                name="state"
                :label="stateLabel(values.country)"
                :disabled="!editable"
                @blur="() => addBeneficiaryFormField({ state: values.state })"
              />
            </div>
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <InputTextGroup
                name="city"
                :label="$t('common.address.city')"
                :disabled="!editable"
                @blur="() => addBeneficiaryFormField({ city: values.city })"
              />
            </div>
            <div class="col-span-6">
              <InputTextGroup
                v-show="
                  values.country &&
                  isCountryWithPostalCode(values.country?.value)
                "
                name="postalCode"
                :label="postalCodeLabel(values.country?.value)"
                :disabled="!editable"
                @blur="
                  () =>
                    addBeneficiaryFormField({ postalCode: values.postalCode })
                "
              />
            </div>
          </div>
        </div>
        <div class="c-group">
          <div class="c-group__header">
            <h3 class="c-group__header__title">
              {{ $t('paymentsPage.bankDetails') }}
            </h3>
          </div>
          <div class="c-form__row grid grid-cols-12">
            <div class="col-span-6">
              <DropdownGroup
                name="bankCountry"
                :label="$t('common.bankInfo.bankCountry')"
                :filter="true"
                reset-filter-on-hide
                option-label="nameString"
                :options="countries"
                :placeholder="$t('common.selectOption')"
                :disabled="!editable"
                append-to="self"
                @change="
                  ({ value }) => {
                    setFieldValue('bankCountry', value);
                    onBankCountryChange(value);
                  }
                "
              />
            </div>
            <div
              v-if="showWireOption"
              :class="$style['wire-checkbox']"
              class="col-span-6"
            >
              <Checkbox
                v-if="editable"
                id="chosenPaymentScheme"
                v-model="useWire"
                binary
              />
              <Checkbox
                v-else
                id="chosenPaymentScheme"
                :model-value="useWire"
                binary
              />
              <label :class="$style['label']" for="chosenPaymentScheme">
                I require this payment be sent via wire
                <button
                  v-tooltip.focus="wireTooltipText"
                  type="button"
                  class="pi pi-info-circle"
                />
              </label>
            </div>
            <div class="col-span-6">
              <InputTextGroup
                name="accountHolderName"
                :label="$t('paymentsPage.accountHolderName')"
                :disabled="!editable"
              />
            </div>
            <div
              v-for="{ mappingField, label } in bankPaymentFields.displayFields"
              :key="mappingField"
              class="col-span-6"
            >
              <InputTextGroup
                :name="mappingField"
                :label="label"
                :disabled="!editable"
              />
            </div>
          </div>
          <div v-if="vendorUsesLegacyDataStructure" class="c-form__row">
            <TextareaGroup
              name="paymentDetails"
              :label="$t('paymentsPage.bankDetails')"
              :placeholder="$t('paymentsPage.exampleBankName')"
              :rows="5"
              :disabled="!editable"
            />
          </div>
        </div>
        <Message
          v-if="showError"
          severity="error"
          :closable="false"
          class="m-t-30"
        >
          <p>{{ errorMessage }}</p>
        </Message>
        <div v-if="isInvoiceUploadStep" :class="$style['button-container']">
          <DSButton
            label="Next"
            :class="$style['back-button']"
            type="submit"
            @click="handleNextClick"
          />
        </div>
        <div v-if="isInvoiceUploadStep" :class="$style['link']">
          <DSButton
            label="Go back"
            class="p-button-link m-t-10"
            @click="$emit('prev-tab')"
          />
        </div>
        <div v-if="!isInvoiceUploadStep && editable" class="c-form__controls">
          <DSButton type="submit" :disabled="isLoading">{{
            $t('paymentsPage.saveChanges')
          }}</DSButton>
        </div>
      </VeeForm>
    </div>
    <LoadingScreen v-show="isLoading" :class="$style['spinner-container']" />
  </div>
</template>

<script>
import { Form as VeeForm } from 'vee-validate';
import { string, object, mixed, lazy } from 'yup';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { mapGetters } from 'vuex';

import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import TextareaGroup from '@clearbanc/clear-components/textareagroup';
import Checkbox from '@clearbanc/clear-components/checkbox';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import AutoCompleteAddress from '@/components/AutoCompleteAddress';
import LoadingScreen from '@/components/LoadingScreen';

import {
  postalCodeLabel,
  stateLabel,
  stateOptionsForCountry,
  getPostalCodeRegexAccordingToCountry,
} from '@/utils/local';

import {
  PAYMENT_SCHEME_TYPES,
  PAY_IN_PAY_OUT_STATUS_LOCKED_BY_DILIGENCE,
} from '@/data/payments';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import {
  isCountryWithPostalCode,
  unsanctionedCountryOptionsWithPriority,
} from '@/data/country-code-with-names';

export default {
  components: {
    LoadingScreen,
    Message,
    VeeForm,
    InputTextGroup,
    AutoCompleteAddress,
    DropdownGroup,
    TextareaGroup,
    Checkbox,
    DSButton,
  },
  props: {
    bill: {
      type: Object,
      required: true,
    },
    isInvoiceUploadStep: {
      type: Boolean,
      default: false,
    },
    processedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initialValues: {},
      isLoading: false,
      isBeneficiaryUpdated: false,
      isPendingBankFields: false,
      bankFields: [],
      bankPaymentOptions: [],
      useWire: false,
      wireTooltipText: `Specific payment instructions for wires may have been provided by your vendor on your invoice.`,
      vendorUsesLegacyDataStructure: false,
      showError: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      'createdBill',
      'selectedBillBeneficiary',
      'getV2BeneficiaryById',
      'businessId',
      'beneficiaryFormInfo',
    ]),
    ...mapRequestStatuses({
      updateBeneficiaryRequest: 'UPDATE_BENEFICIARY_BY_BILL_ID',
      createBeneficiaryRequest: 'CREATE_BENEFICIARY',
      updateV2BeneficiaryRequest: 'UPDATE_V2_BENEFICIARY',
    }),
    schema() {
      const fieldSchema = {
        name: string().required(this.$t('common.required')),
        website: string()
          .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            this.$t('common.invalidUrl'),
          )
          .required(this.$t('common.required')),
        email: string()
          .matches(
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            this.$t('common.invalidEmailAddress'),
          )
          .required(this.$t('common.required')),
        phone: string()
          .nullable()
          .when('country', (country, schema) => {
            // https://www.npmjs.com/package/libphonenumber-js
            if (country) {
              return schema
                .test(
                  'is-valid-phone-number',
                  this.$t('common.phoneNumberNotValid'),
                  (value) => {
                    return (
                      isPossiblePhoneNumber(value || '', country?.value) &&
                      isValidPhoneNumber(value || '', country?.value)
                    );
                  },
                )
                .required(this.$t('common.required'));
            }

            return schema.required(this.$t('common.required'));
          }),
        address: mixed().nullable().required(this.$t('common.required')),
        country: object().nullable().required(this.$t('common.required')),
        state: lazy((value) =>
          typeof value === 'object'
            ? object()
                .nullable()
                .required(this.$t('common.required'))
                .typeError(this.$t('common.required'))
            : string().required(this.$t('common.required')),
        ),
        city: string().nullable().required(this.$t('common.required')),
        postalCode: string()
          .nullable()
          .when('country', (country, schema) => {
            if (country && isCountryWithPostalCode(country.value)) {
              const countrySpecificRegex = getPostalCodeRegexAccordingToCountry(
                country.value,
              );

              if (countrySpecificRegex) {
                return schema
                  .matches(
                    countrySpecificRegex,
                    this.$t('common.address.invalidPostalCode'),
                  )
                  .required(this.$t('common.required'));
              }
            }

            return schema;
          }),
        bankCountry: object().nullable().required(this.$t('common.required')),
        accountHolderName: string().required(this.$t('common.required')),
      };
      return object(fieldSchema);
    },
    editable() {
      const v2BeneLinked =
        this.selectedBillBeneficiary &&
        this.selectedBillBeneficiary.beneficiaryServiceId;

      const payOutStatusLocked =
        PAY_IN_PAY_OUT_STATUS_LOCKED_BY_DILIGENCE.includes(
          this.bill.payOutStatus,
        );

      return !v2BeneLinked && !payOutStatusLocked;
    },
    countries() {
      const countryList = unsanctionedCountryOptionsWithPriority;

      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = countryList.map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
    showWireOption() {
      return (
        this.bankPaymentOptions.length > 1 &&
        !!this.bankPaymentOptions.find(
          (payment) => payment.type === PAYMENT_SCHEME_TYPES.PRIORITY,
        )
      );
    },
    bankPaymentFields() {
      if (!this.bankPaymentOptions.length || !this.bankFields.length) return [];

      const chosenScheme = this.useWire
        ? PAYMENT_SCHEME_TYPES.PRIORITY
        : PAYMENT_SCHEME_TYPES.REGULAR;

      const chosenSchemeOption = this.bankPaymentOptions.find(
        (option) => option.type === chosenScheme,
      );
      const displayFields = [];
      const readonlyFields = [];
      chosenSchemeOption.fields.forEach((field) => {
        if (field?.readonly) {
          readonlyFields.push(field);
        } else {
          displayFields.push(field);
        }
      });
      return {
        displayFields: [...this.bankFields, ...displayFields],
        readonlyFields,
      };
    },
    isEditingForm() {
      return (
        this.bill.beneficiaryId && this.bill.beneficiaryId !== '+newvendor'
      );
    },
  },
  methods: {
    stateLabel,
    stateOptionsForCountry,
    isCountryWithPostalCode,
    postalCodeLabel,
    addBeneficiaryFormField(value) {
      // Only store the form info if the user is not editing an existing vendor
      if (!this.isEditingForm) {
        this.$store.commit('ADD_BENEFICIARY_FORM_FIELD', value);
      }
    },
    async onBankCountryChange({ value }) {
      try {
        if (!value) return;

        this.isPendingBankFields = true;

        const { fields, paymentOptions } = await this.$store.dispatchApiAction(
          'GET_BANK_DETAILS_FIELDS',
          {
            bankCountry: value,
            beneficiaryCountry: value,
            currencyCode: this.bill.payOutCurrency,
            paymentAmountCents: this.bill.amountCents,
          },
        );

        this.bankFields = fields;
        this.bankPaymentOptions = paymentOptions;

        const preselectedScheme = this.getDefaultPaymentScheme();
        if (preselectedScheme === PAYMENT_SCHEME_TYPES.PRIORITY) {
          this.useWire = true;
        }
      } finally {
        this.isPendingBankFields = false;
      }
    },
    getDefaultPaymentScheme() {
      if (this.bankPaymentOptions.length === 1) {
        return this.bankPaymentOptions[0].type;
      }
      return PAYMENT_SCHEME_TYPES.REGULAR;
    },
    async onSubmit(values) {
      if (!values) return;
      const payload = {
        name: values.name,
        website: values.website,
        email: values.email,
        phone: values.phone,
        address: values.address,
        addressLine2: values.addressLine2,
        state: values.state.value || values.state,
        city: values.city,
        country: values.country.value,
        postalCode: values.postalCode ? values.postalCode : ' ',
        accountHolderName: values.accountHolderName,
        bankCountry: values.bankCountry.value,
      };

      let preventSubmission;
      const paymentDetails = {};
      if (this.vendorUsesLegacyDataStructure && values.paymentDetails) {
        paymentDetails.legacyPaymentDetails = values.paymentDetails;
      }
      const { displayFields, readonlyFields } = this.bankPaymentFields;

      displayFields.forEach((field) => {
        const isFieldValid = !!values[field.mappingField];
        const regex = RegExp(field.regex);
        if (!isFieldValid) {
          preventSubmission = true;
          this.$refs.form.setFieldError(
            field.mappingField,
            this.$t('common.required'),
          );
        } else if (!regex.test(values[field.mappingField])) {
          preventSubmission = true;
          this.$refs.form.setFieldError(
            field.mappingField,
            this.$t(`Invalid ${field.label}`),
          );
        }
        paymentDetails[field.mappingField] = values[field.mappingField];
        if (field.mappingFieldType) {
          Object.assign(paymentDetails, field.mappingFieldType);
        }
      });
      // add readonly properties
      readonlyFields.forEach((field) => {
        paymentDetails[field.mappingField] = field.value;
      });
      payload.paymentDetails = JSON.stringify(paymentDetails);

      if (preventSubmission) return;

      this.isLoading = true;
      this.isBeneficiaryUpdated = false;
      let beneficiaryResponse;

      const beneficiaryPayload = {
        businessId: this.businessId.toString(),
        label: payload.name,
        accountName: payload.accountHolderName,
        currencyCode: this.bill.payOutCurrency,
        bankCountry: payload.bankCountry,
        companyName: payload.name,
        streetAddress1: payload.address,
        streetAddress2: payload.addressLine2,
        city: payload.city,
        state: payload.state,
        postCode: payload.postalCode,
        countryCode: payload.country,
        website: payload.website,
        email: payload.email,
        phone: payload.phone,
        ...paymentDetails,
        paymentType: this.useWire
          ? [PAYMENT_SCHEME_TYPES.PRIORITY]
          : [PAYMENT_SCHEME_TYPES.LOCAL],
      };

      let createOrUpdateRequest;
      let { id: beneficiaryId } = this.selectedBillBeneficiary;
      if (beneficiaryId) {
        beneficiaryResponse = await this.$store.dispatchApiAction(
          'UPDATE_V2_BENEFICIARY',
          { ...beneficiaryPayload, beneficiaryId },
        );
        createOrUpdateRequest = this.updateV2BeneficiaryRequest;
      } else {
        beneficiaryResponse = await this.$store.dispatchApiAction(
          'CREATE_BENEFICIARY',
          beneficiaryPayload,
        );
        if (beneficiaryResponse) {
          beneficiaryId = beneficiaryResponse.id;
        }
        createOrUpdateRequest = this.createBeneficiaryRequest;
      }

      if (createOrUpdateRequest.isError) {
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = createOrUpdateRequest.errorMessage;
        return;
      }

      await this.$store.dispatchApiAction('GET_BENEFICIARY_BY_ID', {
        beneficiaryId,
      });

      await this.$store.dispatchApiAction('GET_BILLS');

      if (!this.isInvoiceUploadStep) {
        await this.$store.dispatch('UPDATE_SELECTED_BILL', {
          ...this.bill,
          beneficiaryId: beneficiaryResponse.id,
        });
      } else {
        this.$emit('beneficiaryId', beneficiaryResponse.id);
      }

      let origin = '';
      if (this.isInvoiceUploadStep) {
        origin = 'bill upload';
      } else if (!this.isInvoiceUploadStep && !this.bill.beneficiaryId) {
        origin = 'bill details';
      }

      if (origin) {
        this.$emit('vendor-created');
      }

      this.isBeneficiaryUpdated = true;
      this.isLoading = false;

      if (this.isInvoiceUploadStep && !this.updateBeneficiaryRequest.isError) {
        this.$emit('next-tab');
      }
    },
    handleNextClick() {
      this.onSubmit();
    },
    addressSelect(autocomplete, setValues, values) {
      const addressComponents = {};
      const obj = {
        ...values,
        address: null,
        addressLine2: null,
        country: null,
        state: null,
        city: null,
        postalCode: null,
      };

      // Convert the raw API data into something more useable
      // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
      autocomplete.address_components.forEach((component) => {
        addressComponents[component.types[0]] = {
          long_name: component.long_name,
          short_name: component.short_name,
        };
      });

      // Populate our form fields using the API data

      // Not all addresses from the Google API are complete and formatted equally
      // Although we can't cover them all, the combination of if statements below cover the most common variations
      if (addressComponents.street_number || addressComponents.route) {
        obj.address = `${addressComponents.street_number?.long_name || ''} ${
          addressComponents.route?.long_name || ''
        }`;
      } else if (addressComponents.neighborhood?.long_name) {
        obj.address = addressComponents.neighborhood?.long_name;
      } else if (addressComponents.sublocality?.long_name) {
        obj.address = addressComponents.sublocality?.long_name;
      } else if (addressComponents.sublocality_level_1?.long_name) {
        obj.address = addressComponents.sublocality_level_1?.long_name;
      } else if (addressComponents.locality?.long_name) {
        obj.address = addressComponents.locality?.long_name;
      }

      if (addressComponents.subpremise) {
        obj.addressLine2 = `#${addressComponents.subpremise.long_name}`;
      }

      if (addressComponents.postal_town) {
        obj.city = addressComponents.postal_town.long_name;
      } else if (addressComponents.locality) {
        obj.city = addressComponents.locality.long_name;
      } else if (addressComponents.administrative_area_level_2) {
        obj.city = addressComponents.administrative_area_level_2.long_name;
      }

      if (addressComponents.administrative_area_level_1) {
        const states = this.states(addressComponents.country?.short_name);
        let selectedState;

        if (states) {
          selectedState = states.find((state) => {
            return (
              state.value ===
              addressComponents.administrative_area_level_1.short_name
            );
          });
        }

        obj.state =
          selectedState ||
          addressComponents.administrative_area_level_1.short_name;
      }

      if (addressComponents.country) {
        obj.country = this.countries.find(
          (country) => country.value === addressComponents.country.short_name,
        );
      }

      if (addressComponents.postal_code) {
        obj.postalCode = addressComponents.postal_code.long_name;
      }
      this.addBeneficiaryFormField(obj);
      setValues(obj);
    },
    states(country) {
      const states = stateOptionsForCountry(country);

      if (!states) return null;

      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = states.map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
    parsePaymentDetails(paymentDetails) {
      try {
        return JSON.parse(paymentDetails);
      } catch (err) {
        return undefined;
      }
    },
    updateInitialValues(payload, _v2Beneficiary) {
      let initialValues;

      const v2Beneficiary =
        _v2Beneficiary && Object.keys(_v2Beneficiary).length
          ? _v2Beneficiary
          : payload.v2BenePayload;

      if (v2Beneficiary && Object.keys(v2Beneficiary).length) {
        initialValues = {
          // map fields with different names in bills-svc and spread the rest
          name: v2Beneficiary.label,
          address: v2Beneficiary.streetAddress1,
          addressLine2: v2Beneficiary.streetAddress2,
          country: v2Beneficiary.countryCode,
          postalCode: v2Beneficiary.postCode,
          accountHolderName: v2Beneficiary.accountName,
          ...JSON.parse(JSON.stringify(v2Beneficiary)),
        };

        this.useWire = this.bill.paymentOption === 'SWIFT';
      } else {
        initialValues = { ...JSON.parse(JSON.stringify(payload)) };

        const parsedPaymentDetails = this.parsePaymentDetails(
          payload.paymentDetails,
        );

        if (parsedPaymentDetails) {
          Object.keys(parsedPaymentDetails).forEach((key) => {
            initialValues[key] = parsedPaymentDetails[key];
          });
          if (parsedPaymentDetails.useWire) {
            this.useWire = true;
          }
        } else if (payload.paymentDetails) {
          this.vendorUsesLegacyDataStructure = true;
        }
      }

      const countryCode = initialValues.country;
      const presetCountry = this.countries.find(
        (country) => country.value === countryCode,
      );
      if (presetCountry) {
        initialValues.country = presetCountry;
      }

      const states = this.states(countryCode);
      let presetState;
      if (states) {
        presetState = states.find(
          (state) => state.value === initialValues.state,
        );
      }
      if (presetState) {
        initialValues.state = presetState;
      }

      const presetBankCountry = this.countries.find(
        (country) => country.value === initialValues.bankCountry,
      );
      if (presetBankCountry) {
        initialValues.bankCountry = presetBankCountry;
      }

      this.initialValues = initialValues;
    },
    async fetchV2Beneficiary(beneficiaryV2Id) {
      if (beneficiaryV2Id) {
        await this.$store.dispatchApiAction('GET_V2_BENEFICIARY', {
          beneficiaryV2Id,
        });
      }
    },
  },
  async mounted() {
    this.isLoading = true;

    const { beneficiaryId, beneficiaryV2Id } = this.bill;
    // Indicates we are using the modal to edit a vendor
    if (this.isEditingForm) {
      this.$store.commit('RESET_BENEFICIARY_FORM_FIELD');
      await this.$store.dispatchApiAction('GET_BENEFICIARY_BY_ID', {
        beneficiaryId,
      });
      await this.fetchV2Beneficiary(beneficiaryV2Id);
      await this.onBankCountryChange({
        value: this.selectedBillBeneficiary.bankCountry,
      });
      this.updateInitialValues(
        this.selectedBillBeneficiary,
        this.getV2BeneficiaryById(beneficiaryV2Id),
      );
    } else if (
      // ^ Adding a new vendor: returning to form (prev page or attempt abandon exit)
      // display the info they had been filling out
      this.beneficiaryFormInfo &&
      Object.keys(this.beneficiaryFormInfo).length
    ) {
      this.initialValues = this.beneficiaryFormInfo;
    } else if (Object.keys(this.processedData ?? {}).length) {
      // ^ Adding new vendor with OCR prefill data present
      this.initialValues = {
        website: this.processedData?.supplierWebsite,
        email: this.processedData?.supplierEmail,
        name: this.processedData?.supplierName,
        phone: this.processedData?.supplierPhone,
        city: this.processedData?.supplierCity,
      };
      this.$store.commit('ADD_BENEFICIARY_FORM_FIELD', this.initialValues);
    }
    this.isLoading = false;
  },
  watch: {
    selectedBillBeneficiary: {
      async handler() {
        if (!this.isLoading) {
          await this.onBankCountryChange({
            value: this.selectedBillBeneficiary.bankCountry,
          });

          const { beneficiaryV2Id } = this.bill;
          this.updateInitialValues(
            this.selectedBillBeneficiary,
            this.getV2BeneficiaryById(beneficiaryV2Id),
          );
        }
      },
    },
  },
};
</script>

<style>
.c-form {
  text-align: left;
}
</style>

<style lang="less" module>
.container {
  margin: 0 auto;

  h3 {
    font-size: 17px;
  }

  .description {
    margin: 0;
  }

  .message {
    margin: 0 0 2rem 0;
  }
}

.form {
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }

  @media only screen and (min-width: 768px) {
    width: 685px;
  }
}

.divider {
  border-top: 1px dotted rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
  padding-top: 1rem;
}

.spinner-container {
  > div {
    margin: 150px auto !important;
  }
}

.wire-checkbox {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .label {
    margin-left: 12px;
    line-height: 1;
  }
}

.link {
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
}
</style>
