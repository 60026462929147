<template>
  <VeeForm
    v-slot="{ values, setValues, setFieldValue, validate }"
    :class="$style.container"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
    @invalid-submit="onInvalidSubmit"
  >
    <Message
      v-if="invalidSubmission || apiError"
      ref="message"
      severity="error"
      :closable="false"
      :class="$style['page-message']"
    >
      <p v-if="invalidSubmission">
        You must provide additional business details to continue. Having issues?
        You can reach us by phone at +1 (415) 610-5166 (9AM - 5PM ET) or by
        email at <a href="mailto:support@clear.co">support@clear.co</a>.
      </p>
      <p v-if="apiError">
        {{ CONTACT_SUPPORT_MSG(this.contactDetails.number) }}
      </p>
    </Message>

    <section :class="$style.section">
      <h2 :class="$style.section__header">Basic Business Details</h2>
      <div :class="$style.section__content">
        <div class="c-form">
          <div class="c-group">
            <div class="c-form__row grid grid-cols-12">
              <div class="col-span-4 md:col-span-12">
                <InputTextGroup
                  name="company"
                  label="Company Name"
                  @blur="
                    (e) => {
                      trackBusinessNameChange(e.target.value);
                    }
                  "
                />
              </div>
              <div class="col-span-4 md:col-span-12">
                <InputTextGroup name="website" label="Website" />
              </div>
              <div class="col-span-4 md:col-span-12">
                <FormPhoneInput
                  :dropdown-attrs="{
                    name: 'callingCode',
                    options: countryCallingCodes,
                    optionLabel: 'nameString',
                    placeholder: 'Select',
                    filter: true,
                  }"
                  :input-attrs="{
                    name: 'phone',
                    label: 'Phone Number',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="c-group">
            <div class="c-group__header">
              <h4
                class="c-group__header__title"
                :class="$style['c-group__header__title']"
              >
                Mailing Address
              </h4>
            </div>
            <div class="c-form__row grid grid-cols-12">
              <div class="col-span-4 md:col-span-12">
                <AutoCompleteAddress
                  name="line1"
                  label="Address Line 1"
                  @addressSelected="
                    addressSelect($event, setValues, values, validate)
                  "
                />
              </div>
              <div class="col-span-4 md:col-span-12">
                <InputTextGroup name="line2" label="Address Line 2" />
              </div>
              <div class="col-span-4 md:col-span-12">
                <InputTextGroup name="city" label="City" />
              </div>
            </div>
            <div class="c-form__row grid grid-cols-12">
              <div class="col-span-4 md:col-span-12">
                <DropdownGroup
                  name="country"
                  label="Country"
                  :options="countries"
                  :reset-filter-on-hide="true"
                  :filter="true"
                  option-label="nameString"
                  @change="
                    (e) => {
                      setFieldValue('state', null);
                      setFieldValue('postalCode', null);
                      trackCountryChange(e.value.value);
                    }
                  "
                />
              </div>
              <div
                v-if="stateOptionsForCountry(values.country?.value)"
                class="col-span-4 md:col-span-12"
              >
                <DropdownGroup
                  name="state"
                  :label="jurisdictionLabel(values.country.value)"
                  :options="states(values.country.value)"
                  :reset-filter-on-hide="true"
                  :filter="true"
                  option-label="nameString"
                />
              </div>
              <div class="col-span-4 md:col-span-12">
                <InputTextGroup
                  v-show="isCountryWithPostalCode(values.country?.value)"
                  name="postalCode"
                  :label="postalCodeLabel(values.country?.value)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section :class="$style.section">
      <h2 :class="$style.section__header">Incorporation Details</h2>
      <div :class="$style.section__content">
        <p
          v-if="isPersonaCompletedOrVerified"
          :class="$style['incorporation-message']"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="$style['incorporation-message__icon']"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.391 11.0615C15.7931 10.0909 16 9.05058 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 9.05058 0.206926 10.0909 0.608964 11.0615C1.011 12.0321 1.60028 12.914 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C9.05058 16 10.0909 15.7931 11.0615 15.391C12.0321 14.989 12.914 14.3997 13.6569 13.6569C14.3997 12.914 14.989 12.0321 15.391 11.0615ZM11.2769 6.43599C11.5177 6.09893 11.4396 5.63052 11.1026 5.38976C10.7655 5.149 10.2971 5.22707 10.0563 5.56413L7.23761 9.51036L5.86364 8.13639C5.57075 7.8435 5.09588 7.8435 4.80298 8.1364C4.51009 8.42929 4.51009 8.90416 4.80298 9.19706L6.80298 11.1971C6.95887 11.3529 7.17546 11.4324 7.39517 11.4142C7.61489 11.396 7.81547 11.2821 7.94361 11.1027L11.2769 6.43599Z"
              fill="url(#paint0_linear_8011_29291)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_8011_29291"
                x1="-8.10251e-08"
                y1="16"
                x2="16"
                y2="-8.10251e-08"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3DD47F" />
                <stop offset="1" stop-color="#23AA71" />
              </linearGradient>
            </defs>
          </svg>
          Incorporation details complete
        </p>
        <p
          v-if="!isPersonaCompletedOrVerified"
          :class="$style['incorporation-message']"
        >
          Before we can complete your application, we’ll need to verify your
          incorporation details.
        </p>
        <DSButton
          v-if="!isPersonaCompletedOrVerified"
          :class="$style['incorporation-button']"
          label="Add incorporation details"
          @click="openPersonaModal"
        />
      </div>
    </section>
    <section :class="$style['page-controls']">
      <DSButton label="Done" type="submit" :disabled="disableProgression" />
    </section>
  </VeeForm>
</template>

<script>
import {
  countryOptionsWithPriority,
  isCountryWithPostalCode,
} from '@/data/country-code-with-names';
import {
  jurisdictionLabel,
  jurisdictionsOptionsForCountry,
  postalCodeLabel,
  stateOptionsForCountry,
  getPostalCodeRegexAccordingToCountry,
} from '@/utils/local';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { mapStateModuleGetters } from '@/utils/state-modules';
import { getAllCallingCodes } from '@/utils/phone-numbers';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import stateModuleAware from '@/mixins/state-module-aware';
import analytics from '@/utils/analytics';
import { PERSONA_TEMPLATE_NAMES } from '@/data/persona';
import usePersonaVerification from '@/composables/persona';

import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';

import { Form as VeeForm } from 'vee-validate';
import { string, object, mixed } from 'yup';
import Message from '@clearbanc/clear-components/message';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import FormPhoneInput from '@/components/forms/FormPhoneInput';
import AutoCompleteAddress from '@/components/AutoCompleteAddress';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    VeeForm,
    Message,
    InputTextGroup,
    FormPhoneInput,
    AutoCompleteAddress,
    DropdownGroup,
    DSButton,
  },
  setup() {
    const { initPersonaClient, openPersonaModal, personaCompleteStatus } =
      usePersonaVerification();
    initPersonaClient({ template: PERSONA_TEMPLATE_NAMES.BUSINESS });
    return { openPersonaModal, initPersonaClient, personaCompleteStatus };
  },
  data() {
    return {
      invalidSubmission: false,
      isSubmitting: false,
      apiError: false,
      countryCallingCodes: getAllCallingCodes(),
      CONTACT_SUPPORT_MSG,
      schema: object({
        company: string().required('Required'),
        website: string()
          .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            this.$t('common.invalidUrl'),
          )
          .required('Required'),
        phone: string()
          .nullable()
          .when('callingCode', (callingCode, schema) => {
            // https://www.npmjs.com/package/libphonenumber-js
            if (callingCode) {
              return schema
                .test(
                  'is-valid-phone-number',
                  this.$t('common.phoneNumberNotValid'),
                  (value) => {
                    return (
                      isPossiblePhoneNumber(value || '', callingCode?.value) &&
                      isValidPhoneNumber(value || '', callingCode?.value)
                    );
                  },
                )
                .required('Required');
            }

            return schema.required('Required');
          }),
        line1: mixed().nullable().required('Required'),
        line2: mixed().nullable(),
        city: string().nullable().required('Required'),
        country: object().nullable().required('Required'),
        state: object()
          .nullable()
          .when('country', (country, schema) => {
            if (country) {
              const countryHasStates = this.stateOptionsForCountry(
                country.value,
              );
              if (countryHasStates) {
                return schema.required(this.$t('common.required'));
              }
            }
            return schema;
          }),
        postalCode: string()
          .nullable()
          .when('country', (country, schema) => {
            if (country) {
              const countrySpecificRegex = getPostalCodeRegexAccordingToCountry(
                country.value,
              );

              if (countrySpecificRegex) {
                return schema
                  .matches(
                    countrySpecificRegex,
                    this.$t('common.address.invalidPostalCode'),
                  )
                  .required('Required');
              }

              if (isCountryWithPostalCode(country?.value)) {
                return schema.required(this.$t && this.$t('common.required'));
              }
            }

            return schema;
          }),
      }),
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'business',
      'contactDetails',
      'isPersonaCorporationVerificationComplete',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: 'UPDATE_USER_BUSINESS',
    }),
    ...mapStateModuleGetters(['steps']),
    isPersonaCompletedOrVerified() {
      return (
        this.isPersonaCorporationVerificationComplete ||
        this.personaCompleteStatus === 'completed'
      );
    },
    currentStep() {
      return this.steps[this.$route.name];
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.$route.name,
        experiment: this.experimentName,
        page: this.$route.path,
      };
    },
    initialValues() {
      const parsedPhoneNumber =
        (this.business.phone || this.user.phoneNumber) &&
        this.parsePhoneNumber(
          this.business.phone || this.user.phoneNumber,
          this.business.addressCountryCode || COUNTRY_CODES.US,
        );

      const callingCode = this.countryCallingCodes.find((item) => {
        return item.value === (parsedPhoneNumber?.country || COUNTRY_CODES.US);
      });

      return {
        company: this.business.name || null,
        website: this.business.website || null,
        callingCode,
        phone: parsedPhoneNumber?.nationalNumber
          ? parsedPhoneNumber.nationalNumber
          : null,
        ...this.initialAddressFields,
      };
    },
    countries() {
      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = countryOptionsWithPriority().map(
        (value) => {
          return {
            ...value,
            nameString: value.name(),
          };
        },
      );

      return optionsWithStringName;
    },
    disableProgression() {
      return this.isSubmitting || !this.isPersonaCompletedOrVerified;
    },
    initialAddressFields() {
      const defaultCountry = this.countries.find(
        (country) => country.value === 'US',
      );

      const businessAddress = {
        line1: this.business.addressStreet1 || null,
        line2: this.business.addressStreet2 || null,
        city: this.business.addressCity || null,
        postalCode: this.business.addressPostalCode || null,
        country: this.business.addressCountryCode
          ? this.countries.find(
              (country) => country.value === this.business.addressCountryCode,
            )
          : defaultCountry,
        state:
          this.business.addressCountryCode &&
          this.stateOptionsForCountry(this.business.addressCountryCode)
            ? this.states(this.business.addressCountryCode)?.find(
                (state) => state.value === this.business.addressState,
              )
            : null,
      };

      const shippingAddress = {
        line1: this.business.shippingAddress?.line1 || null,
        line2: this.business.shippingAddress?.line2 || null,
        city: this.business.shippingAddress?.city || null,
        postalCode: this.business.shippingAddress?.postalCode || null,
        country: this.business.shippingAddress?.country
          ? this.countries.find(
              (country) =>
                country.value === this.business.shippingAddress?.country,
            )
          : defaultCountry,
        state:
          this.business.shippingAddress?.country &&
          this.stateOptionsForCountry(this.business.shippingAddress?.country)
            ? this.states(this.business.shippingAddress?.country).find(
                (state) => state.value === this.business.shippingAddress?.state,
              )
            : null,
      };

      const shippingAddressIsComplete =
        !!shippingAddress.line1 &&
        !!shippingAddress.city &&
        !!shippingAddress.country;

      const addressToUse = shippingAddressIsComplete
        ? shippingAddress
        : businessAddress;

      return addressToUse;
    },
  },
  methods: {
    jurisdictionLabel,
    postalCodeLabel,
    isCountryWithPostalCode,
    stateOptionsForCountry,
    parsePhoneNumber,
    async onSubmit(values) {
      this.isSubmitting = true;

      await this.$store.dispatchApiAction('UPDATE_USER_BUSINESS', {
        name: values.company,
        website: values.website,
        phone: `+${values.callingCode.callingCode}${values.phone}`,
        shippingAddress: {
          line1: values.line1,
          line2: values.line2,
          country: values.country.value,
          state: values.state?.value,
          city: values.city,
          postalCode: values.postalCode,
        },
        shippingAddressSame: false,
      });

      if (this.updateBusinessRequest.isError) {
        this.apiError = true;
      } else {
        this.dispatchToStateModule('UPDATE_STEP', {
          step: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS_PERSONA,
        });

        this.$router.push({
          name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
        });
      }

      this.isSubmitting = false;
    },
    onInvalidSubmit(values) {
      this.isSubmitting = true;
      this.invalidSubmission = true;
      this.$nextTick(() => {
        document.querySelector('div[class^="right-side"]').scrollTo({
          top: this.$refs.message.$el.offsetTop - 40,
          behavior: 'smooth',
        });
      });
      this.isSubmitting = false;
    },
    states(country) {
      const states = jurisdictionsOptionsForCountry(country);

      if (!states) return null;

      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = states.map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
    addressSelect(autocomplete, setValues, values, validate, addressType) {
      const addressComponents = {};
      const obj = {
        line1: null,
        line2: null,
        country: null,
        state: null,
        city: null,
        postalCode: null,
      };

      // Convert the raw API data into something more useable
      // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
      autocomplete.address_components.forEach((component) => {
        addressComponents[component.types[0]] = {
          long_name: component.long_name,
          short_name: component.short_name,
        };
      });

      // Populate our form fields using the API data

      // Not all addresses from the Google API are complete and formatted equally
      // Although we can't cover them all, the combination of if statements below cover the most common variations
      if (addressComponents.street_number || addressComponents.route) {
        obj.line1 = `${addressComponents.street_number?.long_name || ''} ${
          addressComponents.route?.long_name || ''
        }`;
      } else if (addressComponents.neighborhood?.long_name) {
        obj.line1 = addressComponents.neighborhood?.long_name;
      } else if (addressComponents.sublocality?.long_name) {
        obj.line1 = addressComponents.sublocality?.long_name;
      } else if (addressComponents.sublocality_level_1?.long_name) {
        obj.line1 = addressComponents.sublocality_level_1?.long_name;
      } else if (addressComponents.locality?.long_name) {
        obj.line1 = addressComponents.locality?.long_name;
      }

      if (addressComponents.subpremise) {
        obj.line2 = `#${addressComponents.subpremise.long_name}`;
      }

      if (addressComponents.postal_town) {
        obj.city = addressComponents.postal_town.long_name;
      } else if (addressComponents.locality) {
        obj.city = addressComponents.locality.long_name;
      } else if (addressComponents.administrative_area_level_2) {
        obj.city = addressComponents.administrative_area_level_2.long_name;
      }

      if (addressComponents.administrative_area_level_1) {
        const states = this.states(addressComponents.country?.short_name);
        let selectedState;

        if (states) {
          selectedState = states.find((state) => {
            return (
              state.value ===
              addressComponents.administrative_area_level_1.short_name
            );
          });
        }

        obj.state =
          selectedState ||
          addressComponents.administrative_area_level_1.short_name;
      }

      if (addressComponents.country) {
        obj.country = this.countries.find(
          (country) => country.value === addressComponents.country.short_name,
        );
      }

      if (addressComponents.postal_code) {
        obj.postalCode = addressComponents.postal_code.long_name;
      }

      setValues({ ...values, ...obj });

      if (addressType === 'registered') {
        for (const [name, val] of Object.entries(obj)) {
          this.updateBusinessAddressParams(val?.value || val, name);
        }
        this.trackEvent('fe_registered_address_capture');
      } else if (addressType === 'shipping') {
        for (const [name, val] of Object.entries(obj)) {
          if (val?.value || val) {
            this.updateBusinessShippingAddressParams(
              val?.value || val,
              name.replace('Shipping', ''),
            );
          }
        }
        this.trackEvent('fe_mailing_address_capture');
      }

      this.$nextTick(() => {
        validate();
      });
    },
    trackCountryChange(country) {
      analytics.track('fe_country_changed', {
        ...this.commonTrackingFields,
        country,
        type: 'incorporation country',
      });
    },
    trackBusinessNameChange(val) {
      analytics.track('fe_registered_business_name', {
        ...this.commonTrackingFields,
        name: val,
      });
    },
  },
  mixins: [stateModuleAware],
  mounted() {
    analytics.track('fe_country_incorp_default', {
      ...this.commonTrackingFields,
      countryIp: this.business.corpCountry,
    });
  },
};
</script>

<style lang="less" scoped module>
.container {
  padding: 48px 0 0 0;
  text-align: left;
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    padding: 60px 18px 30px;
  }
}

.page-message {
  margin: 0 0 48px 0;
}

.page-message p {
  margin: 0;
}

.header + .page-message {
  margin-top: -24px;
}

@media (min-width: 768px) {
  .page-message {
    margin: 0 0 60px 0;
  }

  .header + .page-message {
    margin-top: -36px;
  }
}

.section + .section {
  margin-top: 60px;
}

.section__header {
  margin: 0 0 48px;
  font-family: @gerstner-font;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: @color-grey-500;
  border-bottom: 1px solid @color-grey-200;
  padding: 0 0 11px 0;
}

.page-controls {
  margin-top: 120px;
  text-align: center;
}

.incorporation-message {
  display: flex;
  align-items: center;
  margin: 0;
}

.incorporation-message__icon {
  margin-right: 16px;
}

.incorporation-message + .incorporation-button {
  margin-top: 48px;
}
</style>
