<template>
  <div :class="$style['grid-wrap']">
    <div :class="$style['financing-grid-layout']">
      <slot />
    </div>
  </div>
</template>

<style lang="less" module>
.grid-wrap {
  width: 100%;
  background-color: @color-grey-50;
  padding-top: 50px;
}

.financing-grid-layout {
  max-width: 1440px;
  margin: auto;
  padding: 0 @spacing-40;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, min-content);
  grid-column-gap: @spacing-24;
  // tablet
  @media only screen and (min-width: 513px) and (max-width: 1024px) {
    padding: 0 @spacing-80;
  }

  // mobile
  @media only screen and (max-width: 512px) {
    padding: 0 @spacing-16;
  }
}
</style>
