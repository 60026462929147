<template>
  <div>
    <UiMsg
      v-if="
        updateBusinessRequest.isError ||
        updateUserRequest.isError ||
        showUploadError
      "
      type="error"
    >
      {{ computedErrorMsg }}
    </UiMsg>
    <Box :class="$style.box">
      <VeeForm
        class="c-form"
        :class="$style.form"
        :initial-values="initialValues"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="c-form__row">
          <InputTextGroup
            name="firstName"
            :label="$t('common.firstName')"
            :disabled="isPrimary && !!advanceContractUserSigned"
            @keydown="validateName"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'firstName');
                trackEvent('first_name_capture');
              }
            "
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="lastName"
            :label="$t('common.lastName')"
            :disabled="isPrimary && !!advanceContractUserSigned"
            @keydown="validateName"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'lastName');
              }
            "
          />
        </div>
        <div class="c-form__row grid"></div>
        <div class="c-form__row">
          <p class="c-form__row__description">{{ idRequirements }}</p>
        </div>
        <div class="c-form__row">
          <UploadFileWithDownloadableFileList
            ref="uploadFileWithDownloadableFileList"
            :upload-filters="uploadFilters"
            :display-filters="displayFilters"
            :empty-prompt="uploadButtonText"
            theme="onboarding"
            :accepted-file-types="acceptedFileTypes"
            no-list
            :files="userUploads"
            @trackEvent="trackEvent"
            @dropzoneClicked="trackEvent('upload_doc_click')"
            @uploadDragged="trackEvent('upload_dragged')"
            @docsUploaded="trackDocsUploaded"
            @uploadFailed="trackUploadFailed"
            @trackUploadedDocClicked="trackEvent('uploaded_doc_clicked')"
            @fileRemove="removeUpload"
          />
        </div>
        <div class="c-form__controls">
          <DSButton
            ref="submit"
            label="Continue"
            type="submit"
            :class="$style.button"
          />
        </div>
      </VeeForm>
    </Box>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { isMobileMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import { idRequirementsByCountry } from '@/utils/local';
import { ENTITY_TYPE, ID_TYPE } from '@clearbanc/data-common-types';
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';
import UiMsg from '@/components/UiMsg';
import Box from '@/components/Box';
import { UploadFileWithDownloadableFileList } from '@/components';
import { ENVIRONMENTS } from '@/data/environments';

import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    UiMsg,
    Box,
    UploadFileWithDownloadableFileList,
    VeeForm,
    InputTextGroup,
    DSButton,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    acceptedFileTypes: {
      type: String,
      default: 'image/jpeg,image/png,application/pdf',
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      showUploadError: false,
      idValidationErrorMsg: this.$t(
        'components.formOwnersStepOne.idValidationErrorMsg',
      ),
      schema: object({
        firstName: string().nullable().required(this.$t('common.required')),
        lastName: string().nullable().required(this.$t('common.required')),
      }),
    };
  },
  computed: {
    ...mapGetters([
      'selectedOwner',
      'user',
      'business',
      'advanceInNegotiation',
      'advanceContracts',
      'business',
      'productSegmentLabel',
      'uploads',
      'contactDetails',
      'isDiligenceServiceReadDataEnabled',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: ['UPDATE_USER_BUSINESS'],
      updateUserRequest: 'UPDATE_USER_PROFILE',
      fetchUserUploadDetailsRequest: 'GET_USER_UPLOAD_DETAILS',
    }),
    uploadButtonText() {
      return this.isMobile()
        ? this.$t('common.messagePrompt.browse')
        : this.$t('common.messagePrompt.dragAndDropFilesOrBrowse');
    },
    computedErrorMsg() {
      return this.showUploadError
        ? this.idRequirements
        : CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    isPrimary() {
      return this.selectedOwner?.id === this.user.id;
    },
    corpCountry() {
      return this.business.corpCountry;
    },
    idRequirements() {
      return idRequirementsByCountry(this.corpCountry);
    },
    isMissingUserId() {
      return !this.userUploads.length;
    },
    uploadFilters() {
      return {
        metaId: `${this.selectedOwner?.id}`,
        entity: this.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
        type: 'passport_or_license',
      };
    },
    displayFilters() {
      return this.isDiligenceServiceReadDataEnabled
        ? {
            externalId: `${this.selectedOwner?.id}`,
            externalIdType: this.isPrimary
              ? ENTITY_TYPE.USER
              : ENTITY_TYPE.OWNER,
            documentType: [ID_TYPE.ID, ID_TYPE.PASSPORT],
          }
        : {
            metaId: `${this.selectedOwner?.id}`,
            entity: this.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
            type: 'passport_or_license',
          };
    },
    userUploads() {
      return this.$store.getters.uploadsMatching(this.displayFilters);
    },
    advanceContractUserSigned() {
      return this.advanceContracts[this.advanceInNegotiation.id]?.userSignedAt;
    },
    routerParams() {
      return this.$route.params;
    },
    initialValues() {
      return {
        firstName: this.selectedOwner ? this.selectedOwner.firstName : '',
        lastName: this.selectedOwner ? this.selectedOwner.lastName : '',
      };
    },
  },
  watch: {
    async routerParams(newValue) {
      if (newValue.id && !this.selectedOwner) {
        await this.$store.dispatch(
          'SET_SELECTED_OWNER',
          this.$route.params?.id,
        );
      }
    },
  },
  async beforeMount() {
    if (!this.selectedOwner && this.$route.params?.id) {
      await this.$store.dispatch('SET_SELECTED_OWNER', this.$route.params.id);
    }
  },
  methods: {
    validateName(event) {
      const nameRegex = /^([a-z.']+\s?)+$/i;
      if (!nameRegex.test(event.key)) {
        event.preventDefault();
      }
    },
    updateOwnerParams(val, name) {
      this.$store.dispatch('UPDATE_OWNER_PARAMS', { [name]: val });
    },
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.eventTrackingFields, ...props });
    },
    async saveUser(owner) {
      const { firstName, lastName } = owner;
      await this.$store.dispatchApiAction('UPDATE_USER_PROFILE', {
        firstName,
        lastName,
      });
      if (!this.updateUserRequest.isSuccess) {
        const errorMessage = this.updateUserRequest.error.errorCode
          ? this.$t(
              `backendError.${this.updateUserRequest.error.errorCode}`,
              this.updateUserRequest.error.details,
            )
          : this.updateUserRequest.errorMessage;
        this.$emit('validationError', errorMessage); // check if this is going to a segment event
        return;
      }
      this.$emit('validationSuccess');
    },
    async saveBusiness(owner) {
      const { owners } = this.business;
      const isNewOwner = !owners.some(
        (existingOwner) => existingOwner.id === owner.id,
      );
      const updatedOwners = isNewOwner
        ? owners.concat(owner)
        : owners.reduce(
            (acc, val) => acc.concat(val.id === owner.id ? owner : val),
            [],
          );
      await this.$store.dispatchApiAction('UPDATE_USER_BUSINESS', {
        owners: updatedOwners,
      });
      if (this.updateBusinessRequest.error) {
        const errorMessage = this.updateBusinessRequest.error.errorCode
          ? this.$t(
              `backendError.${this.updateBusinessRequest.error.errorCode}`,
              this.updateBusinessRequest.error.details,
            )
          : this.updateBusinessRequest.error.message;
        this.$emit('validationError', errorMessage);
        return;
      }
      this.$emit('validationSuccess');
    },
    removeUpload(id) {
      this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
      this.trackEvent('doc_deleted');
    },
    trackDocsUploaded(uploadData) {
      this.showUploadError = false;
      this.trackEvent('docs_uploaded', { numDocs: uploadData.numDocs });
    },
    trackUploadFailed(uploadData) {
      this.trackEvent('upload_failed', {
        userId: this.user.id,
        businessId: this.business.id,
        errorMsg: uploadData.errorMsg,
      });
    },
    async onSubmit(values) {
      this.showUploadError = false;
      if (this.isMissingUserId) {
        this.showUploadError = true;
        this.trackEvent('error_banner_displayed', {
          errorMsg: this.idValidationErrorMsg,
        });
        return;
      }
      // skip check in unit tests
      if (process.env.CLEARBANC_ENV !== ENVIRONMENTS.TEST) {
        if (await this.hasError()) {
          return;
        }
      }
      window.scrollTo(0, 0);
      this.isPrimary
        ? await this.saveUser(this.selectedOwner)
        : await this.saveBusiness(this.selectedOwner);
    },
  },
};
</script>

<style lang="less" module>
.form {
  max-width: 330px;
}

.button {
  min-width: 186px;
}
</style>
