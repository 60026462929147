<template>
  <div
    :class="[$style.wrapper, isContentDisabled && $style['disable-content']]"
  >
    <div :class="$style.search">
      <InputText
        v-model="searchQuery"
        :class="$style.search__input"
        placeholder="Find your bank"
        @input="getSearchResults"
      />
      <i class="pi pi-search" :class="$style.search__icon" />
    </div>

    <LoadingScreen v-if="isLoading" />

    <div v-if="noSearchResults" :class="$style['no-results']">
      No banks found for <span>{{ searchQuery }}</span>
    </div>

    <div v-if="!isLoading" :class="$style.banks">
      <button
        v-for="bank in bankList"
        :class="$style.banks__item"
        @click="checkIfBankIsOnWhitelist(bank)"
      >
        <span :class="$style.banks__item__logo">
          <img
            v-if="bank.logo"
            :src="getInlineImageData(bank.logo)"
            :alt="`${bank.name} logo`"
          />

          <span v-else>{{ getBankShortInitials(bank.name) }}</span>
        </span>
        <span :class="$style.banks__item__name">{{ bank.name }}</span>
      </button>
    </div>
  </div>

  <BankingRoutingModal
    :visible="isRoutingNumberModalVisible"
    @close="closeRoutingNumberModal"
    @routingNumber="checkIfUsersBankIsOnWhitelist"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import InputText from '@clearbanc/clear-components/inputtext';
import LoadingScreen from '@/components/LoadingScreen';
import { PLAID_DEFAULT_BANKS } from '@/data/plaid-default-banks';
import BankingRoutingModal from '@/components/BankingRoutingModal';

export default {
  emits: ['open-plaid-modal', 'show-add-manual-bank-account'],
  components: {
    InputText,
    LoadingScreen,
    BankingRoutingModal,
  },
  data() {
    return {
      isLoading: false,
      isContentDisabled: false,
      searchQuery: '',
      timeout: null,
      banks: [],
      defaultBanks: PLAID_DEFAULT_BANKS,
      isRoutingNumberModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(['banksOnPlaidWhitelist']),
    bankList() {
      if (this.searchQuery) {
        return this.banks;
      }

      return this.defaultBanks;
    },
    noSearchResults() {
      return !this.isLoading && !this.banks.length && this.searchQuery;
    },
  },
  methods: {
    getInlineImageData(image) {
      return `data:image/png;base64,${image}`;
    },
    getBankShortInitials(bankName) {
      const words = bankName.split(' ').filter((word) => word !== '');
      const letters = words.map((word) => word[0]).join('');
      return letters.slice(0, 2);
    },
    getSearchResults() {
      this.isLoading = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(async () => {
        if (this.searchQuery) {
          const response = await this.$store.dispatchApiAction(
            'POST_PLAID_PROXY',
            {
              method: 'institutionsSearch',
              query: this.searchQuery,
              options: {
                include_optional_metadata: true,
              },
            },
          );

          if (response?.institutions?.length) {
            this.banks = response.institutions;
          } else {
            this.banks = [];
          }
        }

        this.isLoading = false;
      }, 500);
    },
    async checkIfBankIsOnWhitelist(bank) {
      this.isContentDisabled = true;
      const { institution } = await this.$store.dispatchApiAction(
        'POST_PLAID_PROXY',
        {
          method: 'institutionsGetById',
          institution_id: bank.institution_id,
        },
      );
      this.clickedBankDetails = institution;

      if (
        this.haveCommonElements(
          institution.routing_numbers,
          this.banksOnPlaidWhitelist,
        )
      ) {
        this.isRoutingNumberModalVisible = true;
        return;
      }

      this.openPlaidModal(institution);
    },
    haveCommonElements(array1, array2) {
      const set1 = new Set(array1);

      for (const element of array2) {
        if (set1.has(element)) {
          return true;
        }
      }

      return false;
    },
    openPlaidModal(institution) {
      this.$emit('open-plaid-modal', institution);
      this.isContentDisabled = false;
    },
    checkIfUsersBankIsOnWhitelist(routingNumber) {
      if (this.banksOnPlaidWhitelist.includes(routingNumber)) {
        this.$emit('show-add-manual-bank-account');
        this.isContentDisabled = false;
        return;
      }

      this.openPlaidModal();
    },
    closeRoutingNumberModal() {
      this.isContentDisabled = false;
      this.isRoutingNumberModalVisible = false;
    },
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_BANKS_ON_PLAID_WHITELIST');
  },
};
</script>

<style lang="less" module>
.disable-content {
  pointer-events: none;
}

.wrapper {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 800px) {
  .wrapper {
    margin-bottom: 60px;
  }
}

.search {
  position: relative;
  margin-bottom: 24px;
}

input.search__input {
  padding-left: 44px;
}

.search__icon {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.search__icon::before {
  color: #303032;
}

.no-results {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results span {
  font-weight: bold;
  display: inline-block;
  margin-left: 6px;
  background: #e8e8ea;
  padding: 1px 7px;
  border-radius: 2px;
}

.banks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
}

.banks__item {
  all: unset;
  width: calc(50% - 8px);
  margin: 16px 0 0 16px;
  cursor: pointer;
}

.banks__item:focus {
  outline: none;
}

@media (max-width: 799px) {
  .banks__item {
    width: calc(50% - 8px);
  }

  .banks__item:nth-child(2n + 1) {
    margin-left: 0;
  }

  .banks__item:nth-child(-n + 2) {
    margin-top: 0;
  }
}

@media (min-width: 800px) {
  .banks__item {
    width: calc(25% - 12px);
    margin: 16px 0 0 16px;
  }

  .banks__item:nth-child(4n + 1) {
    margin-left: 0;
  }

  .banks__item:nth-child(-n + 4) {
    margin-top: 0;
  }
}

.banks__item__logo {
  border: 1px solid #e8e8ea;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #fff;
}

.banks__item__logo img,
.banks__item__logo span {
  transition: transform 0.25s;
}

.banks__item__logo img {
  height: 100%;
}

.banks__item__logo span {
  height: 40px;
  width: 40px;
  background: #e8e8ea;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.banks__item:focus .banks__item__logo img,
.banks__item:focus .banks__item__logo span,
.banks__item:hover .banks__item__logo img,
.banks__item:hover .banks__item__logo span {
  transform: scale(0.8);
}

.banks__item__name {
  display: block;
  color: #000;
  text-align: center;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 8px;
}
</style>
