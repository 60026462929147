<template>
  <div data-cy="payment-added-modal">
    <div>
      <div v-if="isBnpl">
        <PaymentAddedModal
          v-if="!isBusinessProfileComplete"
          title="Almost done!"
          :image="require('@/assets/icons/pictogram-profile.svg')"
          :button-primary="{ label: 'Complete business profile' }"
          @primary-click="
            () => {
              $router.push({
                name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
              });
              $emit('done');
            }
          "
        >
          <p>For access to funding, please complete your business profile.</p>
        </PaymentAddedModal>
        <PaymentAddedModal
          v-else-if="
            isInFinancialDocsSegment &&
            !fiscalDocumentsUpToDate &&
            showFinancialDocsModal
          "
          title="Additional Financial Documents Required"
          :image="require('@/assets/icons/pictogram-list.svg')"
          :button-primary="{
            label: 'Upload financial documents',
          }"
          :button-secondary="{ label: 'Skip for now' }"
          @primary-click="
            () => {
              $router.push({
                name: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS,
              });
              $emit('done');
            }
          "
          @secondary-click="showFinancialDocsModal = false"
        >
          <p>
            Your estimated Funding Capacity meets a threshold that requires the
            following financial documents: balance sheets and income/P&L
            statements of <strong>the most recent</strong> complete quarter and
            fiscal year.
          </p>
          <p>
            Need help? Contact us at
            <a href="mailto:support@clear.co">support@clear.co</a> or
            1-(415)-610-5166 10am-6pm EST Monday to Friday.
          </p>
        </PaymentAddedModal>
        <PaymentAddedModal
          v-else-if="
            shouldShowDebtObligationNotifications && showDebtObligationsModal
          "
          title="Update Payment Obligations"
          :image="require('@/assets/icons/pictogram-list.svg')"
          :button-primary="{
            label: 'Update Payment Obligations',
          }"
          :button-secondary="{ label: 'Skip for now' }"
          @primary-click="
            () => {
              $router.push({
                name: PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS,
              });
              $emit('done');
            }
          "
          @secondary-click="showDebtObligationsModal = false"
        >
          <p>
            Please provide the latest information regarding your payment
            obligations to proceed with your funding application.
          </p>
          <p>
            Need help? Contact us at
            <a href="mailto:support@clear.co">support@clear.co</a> or
            1-(415)-610-5166 10am-6pm EST Monday to Friday.
          </p>
        </PaymentAddedModal>
        <PaymentAddedModal
          v-else-if="
            (!salesAccountsConnected || hasDisconnectedSalesAccounts) &&
            showVerifyRevenueModal
          "
          title="Funding Application Submitted"
          :image="require('@/assets/icons/pictogram-document.svg')"
          :button-primary="{
            label: 'Verify Revenue',
          }"
          :button-secondary="{ label: 'Done' }"
          @primary-click="$emit('verifyRevenue')"
          @secondary-click="$emit('done')"
        >
          <p>
            Please verify your revenue in order to complete your funding
            application.
          </p>
          <p>
            Need help? Contact us at
            <a href="mailto:support@clear.co">support@clear.co</a> or
            1-(415)-610-5166 10am-6pm EST Monday to Friday.
          </p>
        </PaymentAddedModal>
        <PaymentAddedModal
          v-else
          title="Funding Application Submitted"
          :image="require('@/assets/icons/pictogram-document.svg')"
          :button-primary="getSubmitInvoiceButton()"
          :button-secondary="{ label: 'Done' }"
          @primary-click="$emit('addAnotherInvoice')"
          @secondary-click="$emit('done')"
        >
          <p>
            Your funding application has been submitted to our team for review
            and we'll typically reach out to you within 1-2 business days on
            next steps.
          </p>
          <p>
            Need help? Contact us at
            <a href="mailto:support@clear.co">support@clear.co</a> or
            1-(415)-610-5166 10am-6pm EST Monday to Friday.
          </p>
        </PaymentAddedModal>
      </div>
      <div v-else-if="isBusinessProfileComplete">
        <div :class="$style['bill-pay-popup-container']">
          <div :class="$style['header-wrapper']">
            <h2 class="modal-title">
              {{ $t('account.pages.pageVendors.paymentAdded.title') }}
            </h2>
            <img :src="paymentDone" :alt="$t('paymentsPage.paymentDone')" />
          </div>
          <p>{{ $t('account.pages.pageVendors.paymentAdded.nextSteps') }}</p>
          <p>
            {{
              $t('account.pages.pageVendors.paymentAdded.nextStepsDescription')
            }}
          </p>
        </div>
        <div :class="$style['paybill-bottom-action']">
          <DSButton
            :label="$t('account.pages.pageVendors.paymentAdded.done')"
            class="p-button-link"
            :class="$style['button-done']"
            @click="$emit('done')"
            @keydown="$emit('done')"
          />
          <DSButton
            v-if="userCanSubmitInvoices"
            :label="$t('account.pages.pageVendors.paymentAdded.submitAnother')"
            :data-cy="`submit-another-${uploadType}`"
            @click="$emit('addAnotherInvoice')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DSButton from '@clearbanc/clear-components/button';
import { BillPaymentMethods, PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { mapGetters } from 'vuex';
import PaymentAddedModal from '@/components/payments/PaymentAddedModal';

export default {
  components: {
    PaymentAddedModal,
    DSButton,
  },
  props: {
    payload: {
      required: true,
    },
  },
  data() {
    return {
      paymentDone: require('@/assets/icons/payment-done-icon.png'),
      PAYMENTS_ROUTE_NAMES,
      showFinancialDocsModal: true,
      showDebtObligationsModal: true,
      showVerifyRevenueModal: true,
    };
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS');
  },
  computed: {
    ...mapGetters([
      'isBusinessProfileComplete',
      'isReceiptFlow',
      'userCanSubmitInvoices',
      'isInFinancialDocsSegment',
      'fiscalDocumentsUpToDate',
      'salesAccountsConnected',
      'hasDisconnectedSalesAccounts',
      'shouldShowDebtObligationNotifications',
    ]),
    isBnpl() {
      return BillPaymentMethods.BNPL === this.payload.paymentMethod;
    },
    uploadType() {
      return this.isReceiptFlow
        ? this.$t('account.pages.pageVendors.billDetails.receipt').toLowerCase()
        : this.$t(
            'account.pages.pageVendors.billDetails.invoice',
          ).toLowerCase();
    },
  },
  methods: {
    goTo(event) {
      this.$emit('go-to', event);
    },
    completeBusinessProfile() {
      this.$emit('done');
      return this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
    getSubmitInvoiceButton() {
      return this.userCanSubmitInvoices
        ? {
            label: 'Add another invoice',
          }
        : undefined;
    },
  },
};
</script>

<style lang="less" module>
.header-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  h2 {
    margin: 30px 0;
  }
  @media only screen and (max-width: 600px) {
    margin: 30px 0;
    flex-flow: row;
    align-items: flex-end;
    h2 {
      margin: 0;
    }
    img {
      margin-left: 15px;
    }
  }
}
.bill-pay-popup-container {
  padding: 0 40px;
  @media only screen and (max-width: 600px) {
    padding: 0 5px;
  }
}
.bnpl-card {
  max-width: 526px;
  max-height: 204px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bnpl-card-title {
  padding-bottom: 15px;
}
.icon-text-group {
  display: flex;
  align-items: center;
  margin: 2px 0;
}
.icon {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}
.paybill-bottom-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto 0;
}

button.button-add-invoice {
  color: @color-purple-2022 !important;
  margin-top: 30px;
}

button.button-done {
  padding: 0;
}
</style>
