/**
 * ERROR TRACKING (sentry.io)
 * https://docs.sentry.io/error-reporting/quickstart
 */
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    whitelistUrls: ['*.clearbanc.com', '*.clear.co'],
    beforeSend(event, hint) {
      const error = hint.originalException;
      const isAxiosError = Boolean(
        error && error.response && error.response.data,
      );
      const updatedEvent = event;
      // Group related errors
      if (isAxiosError) {
        updatedEvent.fingerprint = 'network-error';
      }

      if (error?.constructor?.name !== Error.name) {
        updatedEvent.exception.values[0].value =
          event.extra?.__serialized__?.error?.message ??
          hint.originalException?.message;
      }

      return updatedEvent;
    },
  });
}
