import i18n from '@/plugins/i18n';

export const ContactSupportMethods = {
  phone: {
    display: '+1 (415) 610-5166',
    href: 'tel:+14156105166',
  },
  email: {
    display: 'support@clear.co',
    href: 'mailto:support@clear.co',
  },
  helpCenter: {
    display: () => i18n.t('common.helpCenter'),
    href: process.env.HELP_CENTER_URL,
  },
};
