<template>
  <div :class="$style['dropzone-container']">
    <UploadFileWidget
      data-cy="upload-file-widget"
      tabindex="0"
      :class="$style['upload-zone']"
      :upload-filters="uploadFilters"
      :display-filters="displayFilters"
      :empty-prompt="emptyPrompt"
      :no-list="noList"
      :theme="theme"
      :accepted-file-types="acceptedFileTypes"
      @click="$emit('trackEvent', 'fe_upload_doc_click')"
      @uploadDragged="$emit('uploadDragged', $event)"
      @docsUploaded="$emit('docsUploaded', $event)"
      @uploadFailed="$emit('uploadFailed', $event)"
      @dropzoneClicked="$emit('dropzoneClicked')"
      @uploadAttempt="$emit('uploadAttempt')"
      @complete="$emit('complete')"
      @apiError="$emit('apiError', $event)"
    />
    <DownloadableFileList
      :class="$style['file-list']"
      :files="files"
      :is-closable="isClosable"
      :shorten-file-names="shortenFilenames"
      @trackUploadedDocClicked="$emit('trackUploadedDocClicked')"
      @fileRemove="$emit('fileRemove', $event)"
    />
  </div>
</template>

<script>
import { DownloadableFileList } from '@/components';
import { inject } from 'vue';

export default {
  components: {
    DownloadableFileList,
  },
  props: {
    emptyPrompt: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t(
          'components.uploadFileWithDownloadableFileList.emptyPrompt',
        );
      },
    },
    uploadFilters: Object,
    displayFilters: Object,
    noList: Boolean,
    theme: { type: String, default: 'default' },
    files: { type: Array },
    isClosable: { type: Boolean, default: true },
    shortenFilenames: { type: Boolean, default: false },
    acceptedFileTypes: {
      type: String,
    },
  },
};
</script>
<style lang="less" module>
.dropzone-container {
  text-align: center;

  .upload-zone {
    margin: 0 auto;
    max-width: 350px;
    margin-bottom: 25px;
  }
  .file-list {
    text-align: left;
  }
}
</style>
