import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    pdfStatements: [],
    pdfStatementsCompletionDetails: {
      isComplete: false,
      reason: '',
      errorCode: '',
      params: {},
    },
  },
  getters: {
    pdfStatements: (state) => state.pdfStatements,
    pdfStatementsCompletionDetails: (state) =>
      state.pdfStatementsCompletionDetails,
  },
  ...buildApiActions(
    {
      GET_BANK_STATEMENTS_CHECK: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            url: `/businesses/${ctx.rootGetters.user.businessId}/heron-pdfs/checks?statementStartDate=${payload.statementStartDate}&bankAccountNumber=${payload.bankAccountNumber}`,
          };
        },
        mutation: (state, { response }) => {
          const { errorCode, reason, ...params } = response.details ?? {};
          state.pdfStatementsCompletionDetails = {
            isComplete: response.completeData,
            errorCode,
            reason,
            params,
          };
        },
      },
      GET_BANK_STATEMENTS: {
        action: (ctx) => {
          return {
            method: 'get',
            url: `/businesses/${ctx.rootGetters.user.businessId}/heron-pdfs`,
          };
        },
        mutation: (state, { response }) => {
          state.pdfStatements = response;
        },
      },
    },
    {
      actions: {
        UPDATE_PDF: ({ commit }, updatedPdf) => {
          commit('UPDATE_PDF', updatedPdf);
        },
      },
      mutations: {
        UPDATE_PDF: (state, updatedPdf) => {
          const existingPdfIndex = state.pdfStatements.findIndex(
            (pdf) => pdf.externalId === updatedPdf.externalId,
          );
          if (existingPdfIndex !== -1) {
            state.pdfStatements = state.pdfStatements.map((pdf) => {
              return pdf.externalId === updatedPdf.externalId
                ? { ...pdf, ...updatedPdf }
                : pdf;
            });
          } else {
            state.pdfStatements.push(updatedPdf);
          }
        },
      },
    },
  ),
};
