import formatDate from 'date-fns/format';

import { legacyParse } from '@date-fns/upgrade/v2';
import {
  formatMoney,
  CURRENCY_THAT_USES_DOT_IN_THOUSAND,
} from '@/utils/currency';

const $filters = {
  currency: formatMoney,

  noDecimal: (value, currencyCode) => {
    return value.split(
      // spliting according to the currency type (if is dot or comma in the decimal part)
      // if the currency uses a dot in thousand, then it uses a comma in the decimal part
      CURRENCY_THAT_USES_DOT_IN_THOUSAND.includes(currencyCode) ? ',' : '.',
    )[0];
  },

  // return up to 2 decimals
  // the "+" will get rid of unnecessary trailing zeros
  percent: (value) => `${+(value * 100).toFixed(2)}%`,

  'friendly-date': (value) => {
    if (!value) return '---';
    return formatDate(legacyParse(value), 'MMMM do, yyyy');
  },

  friendlyDate: (value) => {
    if (!value) return '---';
    return formatDate(legacyParse(value), 'MMMM dd, yyyy');
  },

  shortFriendlyDate: (value) => {
    if (!value) return '---';
    let dateValue = value;
    if (typeof value === 'string') {
      const dateParts = value.substr(0, 10).split('-');
      dateValue = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }
    return formatDate(dateValue, 'MMM d, yyyy');
  },

  date: (value) => {
    if (!value) return '---';
    return formatDate(legacyParse(value), 'yyyy-MM-dd');
  },
  datetime: (value) => {
    if (!value) return '---';
    return formatDate(legacyParse(value), 'yyyy-MM-dd @ h:mma');
  },

  capitalize: (value) => {
    if (!value) return '---';
    return value.charAt(0).toUpperCase() + value.slice(1);
  },

  month: (value) => {
    const months = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    };

    return months[value] || 'N/A';
  },
};

export { $filters };
