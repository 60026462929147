import _ from 'lodash';
import i18n from '@/plugins/i18n';

const SANCTIONED_CITIZENSHIP_COUNTRY_CODE = ['CU', 'IR', 'KP', 'SY', 'UA'];

export const WITHOUT_POSTAL_CODE_COUNTRY_CODE = [
  'AO',
  'AG',
  'AW',
  'BS',
  'BZ',
  'BJ',
  'BW',
  'BF',
  'BI',
  'CM',
  'CF',
  'KM',
  'CG',
  'CD',
  'CK',
  'CI',
  'DJ',
  'DM',
  'GQ',
  'ER',
  'FJ',
  'TF',
  'GM',
  'GH',
  'GD',
  'GN',
  'GY',
  'HK',
  'JM',
  'KE',
  'KI',
  'MO',
  'MW',
  'ML',
  'MR',
  'MU',
  'MS',
  'NR',
  'AN',
  'NU',
  'KP',
  'PA',
  'QA',
  'RW',
  'KN',
  'LC',
  'ST',
  'SC',
  'SL',
  'SB',
  'SO',
  'ZA',
  'SR',
  'SY',
  'TZ',
  'TL',
  'TK',
  'TO',
  'TT',
  'TV',
  'UG',
  'AE',
  'VU',
  'YE',
  'ZW',
];

export const COUNTRY_CODE_WITH_NAMES = {
  AD: () => i18n.t('data.countryCodeWithNames.andorra'),
  AE: () => i18n.t('data.countryCodeWithNames.unitedArabEmirates'),
  AF: () => i18n.t('data.countryCodeWithNames.afghanistan'),
  AG: () => i18n.t('data.countryCodeWithNames.antiguaAndBarbuda'),
  AI: () => i18n.t('data.countryCodeWithNames.anguilla'),
  AL: () => i18n.t('data.countryCodeWithNames.albania'),
  AM: () => i18n.t('data.countryCodeWithNames.armenia'),
  AO: () => i18n.t('data.countryCodeWithNames.angola'),
  AQ: () => i18n.t('data.countryCodeWithNames.antarctica'),
  AR: () => i18n.t('data.countryCodeWithNames.argentina'),
  AS: () => i18n.t('data.countryCodeWithNames.americanSamoa'),
  AT: () => i18n.t('data.countryCodeWithNames.austria'),
  AU: () => i18n.t('data.countryCodeWithNames.australia'),
  AW: () => i18n.t('data.countryCodeWithNames.aruba'),
  AX: () => i18n.t('data.countryCodeWithNames.LandIslands'),
  AZ: () => i18n.t('data.countryCodeWithNames.azerbaijan'),
  BA: () => i18n.t('data.countryCodeWithNames.bosniaAndHerzegovina'),
  BB: () => i18n.t('data.countryCodeWithNames.barbados'),
  BD: () => i18n.t('data.countryCodeWithNames.bangladesh'),
  BE: () => i18n.t('data.countryCodeWithNames.belgium'),
  BF: () => i18n.t('data.countryCodeWithNames.burkinaFaso'),
  BG: () => i18n.t('data.countryCodeWithNames.bulgaria'),
  BH: () => i18n.t('data.countryCodeWithNames.bahrain'),
  BI: () => i18n.t('data.countryCodeWithNames.burundi'),
  BJ: () => i18n.t('data.countryCodeWithNames.benin'),
  BL: () => i18n.t('data.countryCodeWithNames.saintBarthLemy'),
  BM: () => i18n.t('data.countryCodeWithNames.bermuda'),
  BN: () => i18n.t('data.countryCodeWithNames.bruneiDarussalam'),
  BO: () => i18n.t('data.countryCodeWithNames.boliviaPlurinationalStateOf'),
  BQ: () => i18n.t('data.countryCodeWithNames.bonaireSintEustatiusAndSaba'),
  BR: () => i18n.t('data.countryCodeWithNames.brazil'),
  BS: () => i18n.t('data.countryCodeWithNames.bahamas'),
  BT: () => i18n.t('data.countryCodeWithNames.bhutan'),
  BV: () => i18n.t('data.countryCodeWithNames.bouvetIsland'),
  BW: () => i18n.t('data.countryCodeWithNames.botswana'),
  BY: () => i18n.t('data.countryCodeWithNames.belarus'),
  BZ: () => i18n.t('data.countryCodeWithNames.belize'),
  CA: () => i18n.t('data.countryCodeWithNames.canada'),
  CC: () => i18n.t('data.countryCodeWithNames.cocosKeelingIslands'),
  CD: () => i18n.t('data.countryCodeWithNames.congoTheDemocraticRepublicOfThe'),
  CF: () => i18n.t('data.countryCodeWithNames.centralAfricanRepublic'),
  CG: () => i18n.t('data.countryCodeWithNames.congo'),
  CH: () => i18n.t('data.countryCodeWithNames.switzerland'),
  CI: () => i18n.t('data.countryCodeWithNames.cTeDIvoire'),
  CK: () => i18n.t('data.countryCodeWithNames.cookIslands'),
  CL: () => i18n.t('data.countryCodeWithNames.chile'),
  CM: () => i18n.t('data.countryCodeWithNames.cameroon'),
  CN: () => i18n.t('data.countryCodeWithNames.china'),
  CO: () => i18n.t('data.countryCodeWithNames.colombia'),
  CR: () => i18n.t('data.countryCodeWithNames.costaRica'),
  CU: () => i18n.t('data.countryCodeWithNames.cuba'),
  CV: () => i18n.t('data.countryCodeWithNames.capeVerde'),
  CW: () => i18n.t('data.countryCodeWithNames.curaAo'),
  CX: () => i18n.t('data.countryCodeWithNames.christmasIsland'),
  CY: () => i18n.t('data.countryCodeWithNames.cyprus'),
  CZ: () => i18n.t('data.countryCodeWithNames.czechRepublic'),
  DE: () => i18n.t('data.countryCodeWithNames.germany'),
  DJ: () => i18n.t('data.countryCodeWithNames.djibouti'),
  DK: () => i18n.t('data.countryCodeWithNames.denmark'),
  DM: () => i18n.t('data.countryCodeWithNames.dominica'),
  DO: () => i18n.t('data.countryCodeWithNames.dominicanRepublic'),
  DZ: () => i18n.t('data.countryCodeWithNames.algeria'),
  EC: () => i18n.t('data.countryCodeWithNames.ecuador'),
  EE: () => i18n.t('data.countryCodeWithNames.estonia'),
  EG: () => i18n.t('data.countryCodeWithNames.egypt'),
  EH: () => i18n.t('data.countryCodeWithNames.westernSahara'),
  ER: () => i18n.t('data.countryCodeWithNames.eritrea'),
  ES: () => i18n.t('data.countryCodeWithNames.spain'),
  ET: () => i18n.t('data.countryCodeWithNames.ethiopia'),
  FI: () => i18n.t('data.countryCodeWithNames.finland'),
  FJ: () => i18n.t('data.countryCodeWithNames.fiji'),
  FK: () => i18n.t('data.countryCodeWithNames.falklandIslandsMalvinas)'),
  FM: () => i18n.t('data.countryCodeWithNames.micronesiaFederatedStatesOf'),
  FO: () => i18n.t('data.countryCodeWithNames.faroeIslands'),
  FR: () => i18n.t('data.countryCodeWithNames.france'),
  GA: () => i18n.t('data.countryCodeWithNames.gabon'),
  GB: () => i18n.t('data.countryCodeWithNames.unitedKingdom'),
  GD: () => i18n.t('data.countryCodeWithNames.grenada'),
  GE: () => i18n.t('data.countryCodeWithNames.georgia'),
  GF: () => i18n.t('data.countryCodeWithNames.frenchGuiana'),
  GG: () => i18n.t('data.countryCodeWithNames.guernsey'),
  GH: () => i18n.t('data.countryCodeWithNames.ghana'),
  GI: () => i18n.t('data.countryCodeWithNames.gibraltar'),
  GL: () => i18n.t('data.countryCodeWithNames.greenland'),
  GM: () => i18n.t('data.countryCodeWithNames.gambia'),
  GN: () => i18n.t('data.countryCodeWithNames.guinea'),
  GP: () => i18n.t('data.countryCodeWithNames.guadeloupe'),
  GQ: () => i18n.t('data.countryCodeWithNames.equatorialGuinea'),
  GR: () => i18n.t('data.countryCodeWithNames.greece'),
  GS: () =>
    i18n.t('data.countryCodeWithNames.southGeorgiaAndTheSouthSandwichIslands'),
  GT: () => i18n.t('data.countryCodeWithNames.guatemala'),
  GU: () => i18n.t('data.countryCodeWithNames.guam'),
  GW: () => i18n.t('data.countryCodeWithNames.guineaBissau'),
  GY: () => i18n.t('data.countryCodeWithNames.guyana'),
  HK: () => i18n.t('data.countryCodeWithNames.hongKong'),
  HM: () => i18n.t('data.countryCodeWithNames.heardIslandAndMcdonaldIslands'),
  HN: () => i18n.t('data.countryCodeWithNames.honduras'),
  HR: () => i18n.t('data.countryCodeWithNames.croatia'),
  HT: () => i18n.t('data.countryCodeWithNames.haiti'),
  HU: () => i18n.t('data.countryCodeWithNames.hungary'),
  ID: () => i18n.t('data.countryCodeWithNames.indonesia'),
  IE: () => i18n.t('data.countryCodeWithNames.ireland'),
  IL: () => i18n.t('data.countryCodeWithNames.israel'),
  IM: () => i18n.t('data.countryCodeWithNames.isleOfMan'),
  IN: () => i18n.t('data.countryCodeWithNames.india'),
  IO: () => i18n.t('data.countryCodeWithNames.britishIndianOceanTerritory'),
  IQ: () => i18n.t('data.countryCodeWithNames.iraq'),
  IR: () => i18n.t('data.countryCodeWithNames.iranIslamicRepublicOf'),
  IS: () => i18n.t('data.countryCodeWithNames.iceland'),
  IT: () => i18n.t('data.countryCodeWithNames.italy'),
  JE: () => i18n.t('data.countryCodeWithNames.jersey'),
  JM: () => i18n.t('data.countryCodeWithNames.jamaica'),
  JO: () => i18n.t('data.countryCodeWithNames.jordan'),
  JP: () => i18n.t('data.countryCodeWithNames.japan'),
  KE: () => i18n.t('data.countryCodeWithNames.kenya'),
  KG: () => i18n.t('data.countryCodeWithNames.kyrgyzstan'),
  KH: () => i18n.t('data.countryCodeWithNames.cambodia'),
  KI: () => i18n.t('data.countryCodeWithNames.kiribati'),
  KM: () => i18n.t('data.countryCodeWithNames.comoros'),
  KN: () => i18n.t('data.countryCodeWithNames.saintKittsAndNevis'),
  KP: () =>
    i18n.t('data.countryCodeWithNames.koreaDemocraticPeopleSRepublicOf'),
  KR: () => i18n.t('data.countryCodeWithNames.koreaRepublicOf'),
  KW: () => i18n.t('data.countryCodeWithNames.kuwait'),
  KY: () => i18n.t('data.countryCodeWithNames.caymanIslands'),
  KZ: () => i18n.t('data.countryCodeWithNames.kazakhstan'),
  LA: () => i18n.t('data.countryCodeWithNames.laoPeopleSDemocraticRepublic'),
  LB: () => i18n.t('data.countryCodeWithNames.lebanon'),
  LC: () => i18n.t('data.countryCodeWithNames.saintLucia'),
  LI: () => i18n.t('data.countryCodeWithNames.liechtenstein'),
  LK: () => i18n.t('data.countryCodeWithNames.sriLanka'),
  LR: () => i18n.t('data.countryCodeWithNames.liberia'),
  LS: () => i18n.t('data.countryCodeWithNames.lesotho'),
  LT: () => i18n.t('data.countryCodeWithNames.lithuania'),
  LU: () => i18n.t('data.countryCodeWithNames.luxembourg'),
  LV: () => i18n.t('data.countryCodeWithNames.latvia'),
  LY: () => i18n.t('data.countryCodeWithNames.libya'),
  MA: () => i18n.t('data.countryCodeWithNames.morocco'),
  MC: () => i18n.t('data.countryCodeWithNames.monaco'),
  MD: () => i18n.t('data.countryCodeWithNames.moldovaRepublicOf'),
  ME: () => i18n.t('data.countryCodeWithNames.montenegro'),
  MF: () => i18n.t('data.countryCodeWithNames.saintMartinFrenchPart)'),
  MG: () => i18n.t('data.countryCodeWithNames.madagascar'),
  MH: () => i18n.t('data.countryCodeWithNames.marshallIslands'),
  MK: () =>
    i18n.t('data.countryCodeWithNames.macedoniaTheFormerYugoslavRepublicOf'),
  ML: () => i18n.t('data.countryCodeWithNames.mali'),
  MM: () => i18n.t('data.countryCodeWithNames.myanmar'),
  MN: () => i18n.t('data.countryCodeWithNames.mongolia'),
  MO: () => i18n.t('data.countryCodeWithNames.macao'),
  MP: () => i18n.t('data.countryCodeWithNames.northernMarianaIslands'),
  MQ: () => i18n.t('data.countryCodeWithNames.martinique'),
  MR: () => i18n.t('data.countryCodeWithNames.mauritania'),
  MS: () => i18n.t('data.countryCodeWithNames.montserrat'),
  MT: () => i18n.t('data.countryCodeWithNames.malta'),
  MU: () => i18n.t('data.countryCodeWithNames.mauritius'),
  MV: () => i18n.t('data.countryCodeWithNames.maldives'),
  MW: () => i18n.t('data.countryCodeWithNames.malawi'),
  MX: () => i18n.t('data.countryCodeWithNames.mexico'),
  MY: () => i18n.t('data.countryCodeWithNames.malaysia'),
  MZ: () => i18n.t('data.countryCodeWithNames.mozambique'),
  NA: () => i18n.t('data.countryCodeWithNames.namibia'),
  NC: () => i18n.t('data.countryCodeWithNames.newCaledonia'),
  NE: () => i18n.t('data.countryCodeWithNames.niger'),
  NF: () => i18n.t('data.countryCodeWithNames.norfolkIsland'),
  NG: () => i18n.t('data.countryCodeWithNames.nigeria'),
  NI: () => i18n.t('data.countryCodeWithNames.nicaragua'),
  NL: () => i18n.t('data.countryCodeWithNames.netherlands'),
  NO: () => i18n.t('data.countryCodeWithNames.norway'),
  NP: () => i18n.t('data.countryCodeWithNames.nepal'),
  NR: () => i18n.t('data.countryCodeWithNames.nauru'),
  NU: () => i18n.t('data.countryCodeWithNames.niue'),
  NZ: () => i18n.t('data.countryCodeWithNames.newZealand'),
  OM: () => i18n.t('data.countryCodeWithNames.oman'),
  PA: () => i18n.t('data.countryCodeWithNames.panama'),
  PE: () => i18n.t('data.countryCodeWithNames.peru'),
  PF: () => i18n.t('data.countryCodeWithNames.frenchPolynesia'),
  PG: () => i18n.t('data.countryCodeWithNames.papuaNewGuinea'),
  PH: () => i18n.t('data.countryCodeWithNames.philippines'),
  PK: () => i18n.t('data.countryCodeWithNames.pakistan'),
  PL: () => i18n.t('data.countryCodeWithNames.poland'),
  PM: () => i18n.t('data.countryCodeWithNames.saintPierreAndMiquelon'),
  PN: () => i18n.t('data.countryCodeWithNames.pitcairn'),
  PR: () => i18n.t('data.countryCodeWithNames.puertoRico'),
  PS: () => i18n.t('data.countryCodeWithNames.palestineStateOf'),
  PT: () => i18n.t('data.countryCodeWithNames.portugal'),
  PW: () => i18n.t('data.countryCodeWithNames.palau'),
  PY: () => i18n.t('data.countryCodeWithNames.paraguay'),
  QA: () => i18n.t('data.countryCodeWithNames.qatar'),
  RE: () => i18n.t('data.countryCodeWithNames.rUnion'),
  RO: () => i18n.t('data.countryCodeWithNames.romania'),
  RS: () => i18n.t('data.countryCodeWithNames.serbia'),
  RU: () => i18n.t('data.countryCodeWithNames.russianFederation'),
  RW: () => i18n.t('data.countryCodeWithNames.rwanda'),
  SA: () => i18n.t('data.countryCodeWithNames.saudiArabia'),
  SB: () => i18n.t('data.countryCodeWithNames.solomonIslands'),
  SC: () => i18n.t('data.countryCodeWithNames.seychelles'),
  SD: () => i18n.t('data.countryCodeWithNames.sudan'),
  SE: () => i18n.t('data.countryCodeWithNames.sweden'),
  SG: () => i18n.t('data.countryCodeWithNames.singapore'),
  SH: () =>
    i18n.t('data.countryCodeWithNames.saintHelenaAscensionAndTristanDaCunha'),
  SI: () => i18n.t('data.countryCodeWithNames.slovenia'),
  SJ: () => i18n.t('data.countryCodeWithNames.svalbardAndJanMayen'),
  SK: () => i18n.t('data.countryCodeWithNames.slovakia'),
  SL: () => i18n.t('data.countryCodeWithNames.sierraLeone'),
  SM: () => i18n.t('data.countryCodeWithNames.sanMarino'),
  SN: () => i18n.t('data.countryCodeWithNames.senegal'),
  SO: () => i18n.t('data.countryCodeWithNames.somalia'),
  SR: () => i18n.t('data.countryCodeWithNames.suriname'),
  SS: () => i18n.t('data.countryCodeWithNames.southSudan'),
  ST: () => i18n.t('data.countryCodeWithNames.saoTomeAndPrincipe'),
  SV: () => i18n.t('data.countryCodeWithNames.elSalvador'),
  SX: () => i18n.t('data.countryCodeWithNames.sintMaartenDutchPart)'),
  SY: () => i18n.t('data.countryCodeWithNames.syrianArabRepublic'),
  SZ: () => i18n.t('data.countryCodeWithNames.swaziland'),
  TC: () => i18n.t('data.countryCodeWithNames.turksAndCaicosIslands'),
  TD: () => i18n.t('data.countryCodeWithNames.chad'),
  TF: () => i18n.t('data.countryCodeWithNames.frenchSouthernTerritories'),
  TG: () => i18n.t('data.countryCodeWithNames.togo'),
  TH: () => i18n.t('data.countryCodeWithNames.thailand'),
  TJ: () => i18n.t('data.countryCodeWithNames.tajikistan'),
  TK: () => i18n.t('data.countryCodeWithNames.tokelau'),
  TL: () => i18n.t('data.countryCodeWithNames.timorLeste'),
  TM: () => i18n.t('data.countryCodeWithNames.turkmenistan'),
  TN: () => i18n.t('data.countryCodeWithNames.tunisia'),
  TO: () => i18n.t('data.countryCodeWithNames.tonga'),
  TR: () => i18n.t('data.countryCodeWithNames.turkey'),
  TT: () => i18n.t('data.countryCodeWithNames.trinidadAndTobago'),
  TV: () => i18n.t('data.countryCodeWithNames.tuvalu'),
  TW: () => i18n.t('data.countryCodeWithNames.taiwanProvinceOfChina'),
  TZ: () => i18n.t('data.countryCodeWithNames.tanzaniaUnitedRepublicOf'),
  UA: () => i18n.t('data.countryCodeWithNames.ukraine'),
  UG: () => i18n.t('data.countryCodeWithNames.uganda'),
  UM: () =>
    i18n.t('data.countryCodeWithNames.unitedStatesMinorOutlyingIslands'),
  US: () => i18n.t('data.countryCodeWithNames.unitedStates'),
  UY: () => i18n.t('data.countryCodeWithNames.uruguay'),
  UZ: () => i18n.t('data.countryCodeWithNames.uzbekistan'),
  VA: () => i18n.t('data.countryCodeWithNames.holySeeVaticanCityState)'),
  VC: () => i18n.t('data.countryCodeWithNames.saintVincentAndTheGrenadines'),
  VE: () => i18n.t('data.countryCodeWithNames.venezuelaBolivarianRepublicOf'),
  VG: () => i18n.t('data.countryCodeWithNames.virginIslandsBritish'),
  VI: () => i18n.t('data.countryCodeWithNames.virginIslands'),
  VN: () => i18n.t('data.countryCodeWithNames.vietNam'),
  VU: () => i18n.t('data.countryCodeWithNames.vanuatu'),
  WF: () => i18n.t('data.countryCodeWithNames.wallisAndFutuna'),
  WS: () => i18n.t('data.countryCodeWithNames.samoa'),
  YE: () => i18n.t('data.countryCodeWithNames.yemen'),
  YT: () => i18n.t('data.countryCodeWithNames.mayotte'),
  ZA: () => i18n.t('data.countryCodeWithNames.southAfrica'),
  ZM: () => i18n.t('data.countryCodeWithNames.zambia'),
  ZW: () => i18n.t('data.countryCodeWithNames.zimbabwe'),
};

export const COUNTRY_CODES_STATE_REQUIRED_FOR_SEARCH = [
  'US',
  'CA',
  // The following countries do not require states for search for Trulioo API
  // but we are keeping them in intentionally
  'GB',
  'NL',
  'AU',
  'SG',
];

export const COUNTRY_CODES_NOT_REQUIRING_PASSPORT = [
  'AU',
  'NL',
  'SG',
  'US',
  'DE',
  'BE',
  'IE',
  'AT',
  'FI',
  'CA',
  'GB',
];

/**
 * Builds an object of country codes based on COUNTRY_CODE_WITH_NAMES
 * @returns { [COUNTRY_CODE]: 'COUNTRY_CODE' }
 * @example
 * const incorporationCountry = 'DE;
 * const isEUCountry = [COUNTRY_CODE.DE, COUNTRY_CODE.NL].includes(incorporationCountry);
 */
export const COUNTRY_CODES = Object.keys(COUNTRY_CODE_WITH_NAMES).reduce(
  function (acc, cur, i) {
    acc[cur] = cur;
    return acc;
  },
  {},
);

// unusual case where Germany has different jurisdiction list than provinces
export const COUNTRY_CODES_JURISDICTION_DIFFER_STATES = ['DE'];

const priorityLookup = {
  US: 1,
  CA: 2,
  AU: 3,
  NL: 4,
  GB: 5,
  DE: 6,
  IE: 7,
  BE: 8,
  AT: 9,
  FI: 10,
  DK: 11,
  CH: 12,
  SE: 13,
};

export const isCountryWithPostalCode = (countryCode) =>
  !!countryCode && !WITHOUT_POSTAL_CODE_COUNTRY_CODE.includes(countryCode);

export const isStateRequiredForCountrySearch = (countryCode) =>
  !!countryCode &&
  COUNTRY_CODES_STATE_REQUIRED_FOR_SEARCH.includes(countryCode);

export const isPassportRequiredForCountry = (countryCode) =>
  !!countryCode && !COUNTRY_CODES_NOT_REQUIRING_PASSPORT.includes(countryCode);

export const isJurisdictionDifferentFromStateAdressForCountry = (countryCode) =>
  !!countryCode &&
  COUNTRY_CODES_JURISDICTION_DIFFER_STATES.includes(countryCode);

function getCountryOptionsFromCountryCodes(countryCodes) {
  return Object.keys(countryCodes).reduce((acc, value, idx) => {
    const name = countryCodes[value];
    return acc.concat({
      key: idx,
      value,
      name: () => name(),
      priority: priorityLookup[value],
    });
  }, []);
}

// does not account for priority
export const PERMITTED_CITIZENSHIP_OPTIONS = _.omit(
  COUNTRY_CODE_WITH_NAMES,
  SANCTIONED_CITIZENSHIP_COUNTRY_CODE,
);

// account for priority
export const PERMITTED_CITIZENSHIP_OPTIONS_PRIORITIZED =
  getCountryOptionsFromCountryCodes(
    _.omit(COUNTRY_CODE_WITH_NAMES, SANCTIONED_CITIZENSHIP_COUNTRY_CODE),
  );

export const COUNTRY_OPTIONS = getCountryOptionsFromCountryCodes(
  COUNTRY_CODE_WITH_NAMES,
);

export const countryOptionsWithPriority = (priority = []) => {
  const priorityLookupOptions = !priority.length
    ? priorityLookup
    : priority.reduce((acc, val, idx) => {
        acc[val] = idx + 1;
        return acc;
      }, {});

  const countriesUnsorted = Object.keys(COUNTRY_CODE_WITH_NAMES).reduce(
    (acc, key, idx) =>
      acc.concat({
        key: idx,
        value: key,
        name: COUNTRY_CODE_WITH_NAMES[key],
        priority: priorityLookupOptions[key],
      }),
    [],
  );

  const prioritizedCountries = countriesUnsorted
    .filter((country) => country.priority)
    .sort((a, b) => (a.priority > b.priority ? 1 : -1));
  const unprioritizedCountries = countriesUnsorted
    .filter((country) => !country.priority)
    .sort((a, b) => a.name().localeCompare(b.name()));
  return [...prioritizedCountries, ...unprioritizedCountries];
};

export const unsanctionedCountryOptionsWithPriority =
  countryOptionsWithPriority().filter(
    ({ value }) => !SANCTIONED_CITIZENSHIP_COUNTRY_CODE.includes(value),
  );

const opperationalNorthAmericanCountries = ['US', 'CA'];
export const isQualifiedNorthAmericanCountry = (countryCode) => {
  return opperationalNorthAmericanCountries.includes(countryCode);
};
