<template>
  <div :class="$style['bank-accounts__item']">
    <label
      :class="$style['bank-accounts__item__left']"
      @click.prevent="$emit('bankAccountClicked', accountId)"
    >
      <div :class="$style['radio-wrapper']">
        <input
          class="fullstory-element-whitelist"
          :class="$style['radio-wrapper__default']"
          type="radio"
          name="bankAccounts"
          :value="sanitizeName(`${bankName}-${accountName}-${accountNumber}`)"
          :checked="isPrimary"
        />
        <div
          :class="[
            $style['radio-wrapper__custom'],
            { [$style['radio-wrapper__custom--active']]: isPrimary },
          ]"
        ></div>
      </div>
      <div>
        <h3 v-if="bankName" :class="$style['title']">{{ bankName }}</h3>
        <p :class="$style['description']">
          <span>{{ accountName }}</span>
          <span>{{ accountNumber }}</span>
        </p>
      </div>
    </label>
    <div :class="$style['bank-accounts__item__right']">
      <div
        v-if="
          [
            BANK_CONNECTION_STATUS_SUMMARY.CONNECTING,
            BANK_CONNECTION_STATUS_SUMMARY.INITIAL_SCRAPE,
            BANK_CONNECTION_STATUS_SUMMARY.SCRAPING,
          ].includes(status)
        "
        :class="$style['status-container']"
      >
        <div :class="$style['status-container__text']">Importing Account</div>
        <div :class="$style['status-container__icon']">
          <BaseIcon
            icon-name="Loading"
            :class="$style['icon-loading']"
            :width="16"
            :height="16"
            stroke-color="none"
          />
        </div>
      </div>
      <div
        v-else-if="
          ([BANK_CONNECTION_STATUS_SUMMARY.CONNECTED].includes(status) &&
            plaidChecks?.allChecksPass) ||
          businessOnPlaidBypass
        "
        :class="$style['status-container']"
      >
        <div :class="$style['status-container__text']">Account Linked</div>
        <div :class="$style['status-container__icon']">
          <BaseIcon
            icon-name="Checkmark"
            :width="16"
            :height="16"
            stroke-color="none"
          />
        </div>
      </div>
      <div
        v-else-if="
          BANK_ERROR_STATUSES.includes(status) || !plaidChecks?.allChecksPass
        "
        :class="$style['status-container']"
      >
        <div :class="$style['status-container__text']">
          Account Connection Issue
        </div>
        <div :class="$style['status-container__icon']">
          <BaseIcon
            icon-name="Warning"
            :width="16"
            :height="16"
            stroke-color="none"
          />
        </div>
      </div>
      <div
        v-if="isReorderBankingStepsEnabled && contractSigned"
        :class="$style['status-container']"
      >
        <div :class="$style['status-container__text']">ACH Signed</div>
        <div :class="$style['status-container__icon']">
          <BaseIcon
            icon-name="Checkmark"
            :width="16"
            :height="16"
            stroke-color="none"
          />
        </div>
      </div>
      <div
        v-if="
          [
            BANK_CONNECTION_ACTIONS.RELINK,
            BANK_CONNECTION_ACTIONS.CONNECT_NEW,
          ].includes(action) && !businessOnPlaidBypass
        "
        :class="$style['status-container']"
      >
        <div :class="$style['status-container__text']">
          <button :class="$style['link']" @click="reconnectClick">
            Relink account
          </button>
        </div>
        <div :class="$style['status-container__icon']" />
      </div>
      <div
        v-else-if="
          isReorderBankingStepsEnabled && !contractSigned && canSignContract
        "
        :class="$style['status-container']"
      >
        <div :class="$style['status-container__text']">
          <div v-if="!isAuthorizedSignatory && hasContract">
            ACH emailed to signatory
          </div>
          <button v-else :class="$style['link']" @click.once="signContract">
            Sign ACH Agreement
          </button>
        </div>
        <div :class="$style['status-container__icon']" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BANK_CONNECTION_STATUS_SUMMARY,
  BANK_ERROR_STATUSES,
  BANK_CONNECTION_ACTIONS,
} from '@/data/bank-connection';
import BaseIcon from '@/components/icons/base-icon';

export default {
  emits: ['relink', 'connectNew', 'signContract'],
  components: {
    BaseIcon,
  },
  props: {
    accountId: { type: String, required: true },
    bankName: { type: String, required: true },
    accountName: { type: String, required: true },
    accountNumber: { type: String, required: true },
    status: {
      type: String,
      required: true,
      validator: (value) => {
        return ['importing', 'linked', 'issue'].indexOf(value) !== -1;
      },
    },
    action: {
      type: String,
      default: null,
    },
    isPrimary: { type: Boolean, default: false },
    plaidChecks: { type: Object || undefined },
    contractSigned: { type: Boolean, default: false },
    canSignContract: { type: Boolean, default: false },
    hasContract: { type: Boolean, default: false },
  },
  data() {
    return {
      BANK_CONNECTION_STATUS_SUMMARY,
      BANK_ERROR_STATUSES,
      BANK_CONNECTION_ACTIONS,
    };
  },
  computed: {
    ...mapGetters([
      'businessOnPlaidBypass',
      'bankAccountContracts',
      'isReorderBankingStepsEnabled',
      'isAuthorizedSignatory',
    ]),
  },
  methods: {
    sanitizeName(text) {
      return text
        .trim()
        .replace(/\s+/g, '_') // swap spaces for underscores
        .replace(/[^\w-]/g, ''); // remove special chars
    },
    reconnectClick() {
      if (this.action === BANK_CONNECTION_ACTIONS.RELINK) {
        this.$emit('relink');
      } else if (this.action === BANK_CONNECTION_ACTIONS.CONNECT_NEW) {
        this.$emit('connectNew');
      }
    },
    signContract() {
      this.$emit('signContract');
    },
  },
};
</script>

<style lang="less" module>
.bank-accounts__item {
  border-bottom: 1px solid #e8e8ea;
  padding: 30px 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bank-accounts__item:first-of-type {
  border-top: 1px solid #e8e8ea;
}

.bank-accounts__item__left {
  display: flex;
  align-items: center;
}

label.bank-accounts__item__left {
  cursor: pointer;
}

.bank-accounts__item__right {
  margin: 6px 0 0 36px;
  text-align: left;
  width: 100%;
}

@media (min-width: 900px) {
  .bank-accounts__item__right {
    margin-left: auto;
    width: auto;
    text-align: right;
  }
}

.radio-wrapper {
  position: relative;
  margin-right: 16px;
  pointer-events: none;
}

.radio-wrapper__default {
  background: green;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-25%, -25%);
  width: 200%;
  height: 200%;
  margin: 0;
  opacity: 0;
}

.radio-wrapper__custom {
  width: 20px;
  height: 20px;
  border: 1px solid #ceced0;
  border-radius: 50%;
  transition: background 0.3s, border 0.3s;
}

.radio-wrapper__custom:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 50%;
  height: 50%;
  background: white;
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.3s cubic-bezier(0.85, 0, 0.15, 1);
}

.radio-wrapper__default:checked + .radio-wrapper__custom,
.radio-wrapper__custom--active {
  background: #5b00f0;
  border-color: #5b00f0;
}

.radio-wrapper__default:focus + .radio-wrapper__custom {
  border-color: #853bfb;
  background: #853bfb;
}

.radio-wrapper__default:checked + .radio-wrapper__custom:after,
.radio-wrapper__custom--active:after {
  transform: translate(-50%, -50%) scale(1);
}

.bank-accounts__item h3.title {
  margin: 0 0 5px 0;
  font-family: @gerstner-font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #000000;
  text-align: left;
}

.description {
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}

@media (min-width: 900px) {
  .description {
    display: block;
  }

  .description span + span {
    margin-left: 20px;
  }
}

.icon-loading {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.status-container {
  display: flex;
  align-items: center;
}

@media (min-width: 900px) {
  .status-container {
    justify-content: flex-end;
  }

  .description span + span {
    margin-left: 20px;
  }
}

.status-container__text {
  font-family: @gerstner-font;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 1;
  color: #000000;
}

.status-container + .status-container {
  margin-top: 8px;
}

.status-container__icon {
  width: 25px;
  display: flex;
  justify-content: flex-end;
}

.link {
  font-family: @gerstner-font;
  font-weight: 300;
  font-size: 16px;
  color: @color-info-400;
  text-decoration: underline;
}
</style>
