import { ExperimentNames } from '@clearbanc/data-common-types';
import { buildApiActions } from '@/utils/vuex-api-utils';
import { bausRequest } from '../../utils/baus-request';

const bausIntegration = !!process.env.BAUS_INTEGRATION;

export default {
  state: {
    featureFlags: {
      [ExperimentNames.PERSONA]: null,
      [ExperimentNames.DYNAMIC_VENDOR_DETAILS]: null,
      [ExperimentNames.PLAID_REQUIRED]: null,
      [ExperimentNames.DILIGENCE_SERVICE_WRITE_DATA]: null,
      [ExperimentNames.DILIGENCE_SERVICE_READ_DATA]: null,
      [ExperimentNames.DILIGENCE_SERVICE_WRITE_DATA_EXCLUSIVELY]: null,
      enable_bypass_plaid_mandate: null,
      reorder_banking_step: null,
      heron_pdf_automation: null,
      heron_diligence_checks: null,
      document_uploads: null,
    },
    globalFeatureFlags: null,
  },
  getters: {
    globalFeatureFlags: (state) => state.globalFeatureFlags,
    featureFlagsToFetch: (state) => {
      const flags = [];

      Object.keys(state.featureFlags).forEach((featureFlag) => {
        if (state.featureFlags[featureFlag] === null) {
          flags.push(featureFlag);
        }
      });
      return flags;
    },
    isDiligenceServiceWriteDataEnabled: (state) =>
      state.featureFlags[ExperimentNames.DILIGENCE_SERVICE_WRITE_DATA],

    // When enabled, services will fetch document uploads from diligence service
    isDiligenceServiceReadDataEnabled: (state) =>
      state.featureFlags[ExperimentNames.DILIGENCE_SERVICE_READ_DATA],

    // When enabled, services will write document upload data to diligence service exclusively
    isDiligenceServiceWriteDataExclusivelyEnabled: (state) =>
      state.featureFlags[
        ExperimentNames.DILIGENCE_SERVICE_WRITE_DATA_EXCLUSIVELY
      ],

    isPersonaEnabled: (state) => state.featureFlags[ExperimentNames.PERSONA],

    isBypassPlaidEnabled: (state) =>
      state.featureFlags.enable_bypass_plaid_mandate,

    isReorderBankingStepsEnabled: (state, _getters, _rootState, rootGetters) =>
      state.featureFlags.reorder_banking_step &&
      rootGetters.userHasEverHadActiveAdvance,

    isDeprecateV0BeneficiariesEnabled: (state) =>
      state.globalFeatureFlags.deprecate_v0_beneficiaries,

    isHeronPdfAutomationEnabled: (state) =>
      state.featureFlags.heron_pdf_automation,

    isHeronDiligenceChecksEnabled: (state) =>
      state.featureFlags.heron_diligence_checks,

    isDocumentUploadsEnabled: (state) => state.featureFlags.document_uploads,

    isBankConnectionManagementEnabled: (state) =>
      state.globalFeatureFlags.bank_connection_management,

    isEmailVerificationEnabled: (state) =>
      state.globalFeatureFlags.email_verification,

    isProcessDocumentOcrEnabled: (state) =>
      state.globalFeatureFlags.process_document_ocr,

    isDocumentRequestsEnabled: (state) =>
      state.globalFeatureFlags.document_requests,

    isMaintenancePageEnabled: (state) =>
      state.globalFeatureFlags.maintenance_page,

    isBausIntegrationEnabled: () => bausIntegration,

    getAllEnabledExperiments: (state) => {
      return Object.keys(state.featureFlags).filter(
        (flag) => state.featureFlags[flag],
      );
    },
  },
  ...buildApiActions({
    FETCH_USER_FEATURE_FLAGS: {
      action: () => ({
        method: 'get',
        url: `/users/feature-flags`,
        interceptor: bausRequest,
      }),
      mutation: (state, { response }) => {
        Object.keys(state.featureFlags).forEach((flag) => {
          state.featureFlags[flag] = response[flag];
        });
      },
    },
    FETCH_GLOBAL_FEATURE_FLAGS: {
      action: () => ({
        method: 'get',
        url: `/feature-flags/global`,
        interceptor: bausRequest,
      }),
      mutation: (state, { response }) => {
        state.globalFeatureFlags = response.reduce(
          (acc, flag) => ({
            [flag.name]: flag.enabled,
            ...acc,
          }),
          {},
        );
      },
    },
    SEND_EMAIL_VERIFICATION: {
      action: () => ({
        method: 'post',
        url: `/users/verification`,
        interceptor: bausRequest,
        returnResponse: true,
      }),
    },
    GET_EMAIL_VERIFICATION: {
      action: () => ({
        method: 'get',
        url: `/users/verification`,
        interceptor: bausRequest,
        returnResponse: true,
      }),
    },
    SEND_UTM_DATA: {
      action: (_ctx, payload) => ({
        method: 'post',
        url: `/users/utm-data`,
        interceptor: bausRequest,
        params: payload,
      }),
    },
  }),
};
