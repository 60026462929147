<template>
  <div v-if="getUserAdvancesRequest.isPending">
    <icon name="spinner" scale="1" />
  </div>
  <div v-else>
    <header v-if="!section" :class="$style['page-header']">
      <h2 :class="$style['title']">{{ headerText }}</h2>
    </header>

    <article
      v-if="
        !isChargeCardUser &&
        Object.values(advances).some(hasOffers) &&
        displaySection('offers')
      "
      :class="$style['application-offers']"
    >
      <header
        v-if="userHasValidActiveAdvance && !section"
        :class="$style['article-header']"
      >
        <h3 :class="$style['title']">
          {{ $t('applicationPage.myApplication') }}
        </h3>
      </header>
      <ui-tabs
        data-cy="advance-offers-tabs"
        :class="$style['advance-offers-tabs']"
        :tabs="computedAdvanceTabs"
        :active-tab="activeTabIndex"
        @click="activeTabIndex = $event"
      />
      <bnpl-advance-status v-if="bnplActiveTab === activeTabIndex" />
      <div v-else>
        <select-offer-section
          v-if="!isIndiegogoMerchant"
          data-cy="select-offer-section"
          :advance="focusedAdvanceWithOffers"
        />
        <sign-contract-section
          v-if="!isManualContract"
          :advance="focusedAdvanceWithOffers"
        />
      </div>
    </article>

    <article>
      <header v-if="!section" :class="$style['article-header']">
        <h3 :class="$style['title']">
          {{ $t('applicationPage.myAccountDetails') }}
        </h3>
        <rejection-notice :advance="focusedAdvanceWithOffers" />
        <div
          v-if="applicationStage === 'active'"
          data-cy="active-application-stage"
          :class="$style['notice-text']"
        >
          {{ $t('applicationPage.noNewOffers') }}
          <br />{{ $t('applicationPage.ifYouWouldLikeToDiscuss') }}
        </div>
        <div
          v-if="applicationStage === 'awaiting_data'"
          data-cy="awaiting-data-application-stage"
          :class="$style['notice-text']"
        >
          {{ $t('applicationPage.toGiveExactOffer') }}
        </div>
      </header>
      <business-details-section
        v-if="displaySection('business-details') && business.id"
        data-cy="business-details-section"
        :business="business"
        :user="user"
        :open-dropdown="
          sectionToOpen ? sectionToOpen === 'business-details' : !!section
        "
      />
      <template
        v-if="
          applicationStage === 'adjudication' && displaySection('application')
        "
      >
        <div :class="[$style['notice-section'], $style['crunching-numbers']]">
          <h4>{{ $t('applicationPage.hangTight') }}</h4>
          <p>
            <i18n-t keypath="applicationPage.pleaseMakeSure">
              <template #allCaps>
                <u>
                  {{ $t('applicationPage.allCaps') }}
                </u>
              </template>
            </i18n-t>
            <br />
            <br />
            <span class="bold">
              {{ $t('applicationPage.weWillBeInTouch') }}</span
            >
          </p>
        </div>
      </template>
      <template
        v-if="
          applicationStage !== 'awaiting_data' ||
          section ||
          $route.query.showall ||
          isB2BMerchant
        "
      >
        <div
          v-if="!section"
          data-cy="application-stage-text"
          :class="[$style['notice-text'], 'm-t-50']"
        >
          {{ continueApplicationNoticeText[applicationStage] }}
        </div>
        <owner-details-section
          v-if="displaySection('business-details')"
          ref="owner-details-section"
          :business="business"
          :user="user"
          :open-dropdown="
            sectionToOpen ? sectionToOpen === 'owner-details' : !!section
          "
        />
        <DocUploadsSection
          v-if="displaySection('document-uploads')"
          data-cy="document-uploads-section"
          :open-dropdown="
            sectionToOpen ? sectionToOpen === 'document-uploads' : !!section
          "
        />
      </template>
      <save-bar
        ref="saveBar"
        data-cy="save-bar"
        :draft-value="{ user, business }"
        :store-value="{ user: storeUser, business: storeBusiness }"
        :update-request="updateUserAndBusinessRequest"
        :save-disabled="!!validationErrors.length"
        @save="saveButtonHandler"
        @reset="resetToStoreState"
      />
    </article>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { plaidOAuthMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import { ENVIRONMENTS } from '@/data/environments';

import BusinessDetailsSection from '@/components/BusinessDetailsSectionApplication';
import RejectionNotice from '@/components/RejectionNoticeApplication';
import stateModuleAware from '@/mixins/state-module-aware';
import DocUploadsSection from '@/components/deprecated/UploadDocsSectionApplication';

export default {
  components: {
    BusinessDetailsSection,
    RejectionNotice,
    DocUploadsSection,
  },
  mixins: [plaidOAuthMixin, stateModuleAware],
  props: {
    section: { type: String },
    sectionToOpen: { type: String, required: false },
  },
  setup() {
    const { hasError, validationErrors } = validateChildren();
    return {
      hasError,
      validationErrors,
    };
  },
  data: () => ({
    user: {
      address: {},
      shippingAddress: {},
    },
    business: {
      truliooSearchDetails: {},
      address: {},
      shippingAddress: {},
    },
    activeTabIndex: 0,
  }),
  computed: {
    ...mapGetters({
      salesAccountsConnected: 'salesAccountsConnected',
      storeUser: 'user',
      storeBusiness: 'business',
      advances: 'advancesBySubtype',
      isChargeCardUser: 'isChargeCardUser',
      isB2BMerchant: 'isB2BMerchant',
      billingOrManualAccountsConnected: 'billingOrManualAccountsConnected',
      isIndiegogoMerchant: 'isIndiegogoMerchant',
      isBnplMerchant: 'isBnplMerchant',
      userHasEverHadActiveAdvance: 'userHasEverHadActiveAdvance',
      productSegmentLabel: 'productSegmentLabel',
      validInactiveBnplAdvances: 'validInactiveBnplAdvances',
      advanceContracts: 'advanceContracts',
      activatedAdvancesBySubtype: 'activatedAdvancesBySubtype',
      userHasValidActiveAdvance: 'userHasValidActiveAdvance',
      isCoreUser: 'isCoreUser',
    }),
    ...mapRequestStatuses({
      getUserAdvancesRequest: ['GET_USER_ADVANCES'],
      updateUserAndBusinessRequest: ['UPDATE_USER', 'UPDATE_USER_BUSINESS'],
    }),
    ENVIRONMENTS: () => ENVIRONMENTS,
    subtypeIndex() {
      const existingAdvanceTypes = Object.keys(this.advances);
      return Object.fromEntries(
        [
          [0, 'unified'],
          [1, 'inventory'],
          [2, 'ventures'],
          [3, 'marketing_facebook'],
        ]
          .filter(
            ([_i, type]) =>
              existingAdvanceTypes.includes(type) &&
              this.hasOffers(this.advances[type]),
          )
          .map(([_i, type], index) => [index, type]),
      );
    },
    focusedAdvanceWithOffers() {
      return {
        ...this.advances[this.subtypeIndex[this.activeTabIndex]],
      };
    },
    isManualContract() {
      return (
        this.focusedAdvanceWithOffers.subtype === 'marketing_facebook' ||
        this.isB2BMerchant ||
        this.isIndiegogoMerchant
      );
    },
    continueApplicationNoticeText() {
      return {
        adjudication: this.$t('applicationPage.toExpedite'),
        awaiting_signature: this.$t('applicationPage.whileYouReviewAndSign'),
        negotiation: this.$t('applicationPage.whileWeWorkOutDetails'),
        diligence: this.$t('applicationPage.almostDone'),
      };
    },
    applicationStage() {
      if (!this.focusedAdvanceWithOffers.id) return 'active';
      if (this.focusedAdvanceWithOffers.rejectedAt) return 'rejected';
      if (this.advanceContracts[this.focusedAdvanceWithOffers.id]?.userSignedAt)
        return 'diligence'; // signed, not yet countersigned
      if (this.focusedAdvanceWithOffers.approvedAt) return 'awaiting_signature'; // approved, not signed by user
      if (
        Number(this.focusedAdvanceWithOffers.amountCents) ||
        !isEmpty(this.focusedAdvanceWithOffers.offerOptions)
      ) {
        return 'negotiation'; // offers available/selected, not yet approved
      }

      if (
        this.isB2BMerchant
          ? this.billingOrManualAccountsConnected
          : this.salesAccountsConnected
      ) {
        return 'adjudication'; // accounts connected, no offers
      }

      return 'awaiting_data'; // no accounts connected
    },
    computedAdvanceTabs() {
      return [
        this.hasOffers(this.advances.unified) && {
          label: 'Marketing',
        },
        this.hasOffers(this.advances.inventory) && {
          label: this.$t('applicationPage.inventory'),
        },
        this.hasOffers(this.advances.ventures) && {
          label: 'Ventures',
        },
        this.hasBnplOffers(this.validInactiveBnplAdvances) && {
          label: 'Buy Now Pay Later',
        },
        this.hasOffers(this.advances.marketing_facebook) && {
          label: 'Facebook',
        },
      ].filter(Boolean);
    },
    bnplActiveTab() {
      return this.computedAdvanceTabs.findIndex(
        (tab) => tab.label === 'Buy Now Pay Later',
      );
    },
    isFetchAdvancesRequestSuccessful() {
      return this.getUserAdvancesRequest.isSuccess;
    },
    headerText() {
      return !this.userHasValidActiveAdvance
        ? this.$t('data.wayfinding.wayfindingMenuTemplate.application')
        : this.$t('data.wayfinding.wayfindingMenuTemplate.getFunding');
    },
  },
  watch: {
    storeUser: {
      deep: true,
      handler() {
        this.user = cloneDeep(this.storeUser);
      },
    },
    storeBusiness: {
      deep: true,
      handler() {
        this.business = cloneDeep(this.storeBusiness);
        // if the business ID was not known yet, then we need to trigger loading everything else
        // TODO: remove this when we store biz ID in local storage alongside user id
        if (this.loadOnBusinessId) {
          this.loadEverything();
        }
      },
    },
    isFetchAdvancesRequestSuccessful: {
      handler() {
        // these query params get populated upon successful redirect from Docusign
        const { event, contractId, advanceId } = this.$route.query;

        if (event === 'signing_complete' && contractId) {
          if (advanceId) {
            // cannot rely on this.advance() computed prop
            // because upon redirect to clearcom from Docusign
            // this.advance() always defaults to subtype index 0 (Marketing)
            // instead use advanceId query parameter as source of truth

            // upon redirect from Docusign the app bundle reloads meaning
            // we must wait for vue actions to complete successfully before
            // sending data to segment

            let subtype;
            for (const [key, advance] of Object.entries(this.advances)) {
              if (advance.id === Number(advanceId)) subtype = key;
            }

            analytics.track('fe_advance_contract_signed', {
              userId: this.storeUser.id,
              businessId: this.storeBusiness.id,
              productSegment: this.productSegmentLabel,
              advanceId: Number(advanceId),
              contractId: Number(contractId),
              advanceType: subtype,
              numberAdvancesTaken: (
                this.activatedAdvancesBySubtype[subtype] || []
              ).length,
            });
          }
        }
      },
    },
  },
  updated() {
    if (this.userIsInPlaidOAuthFlow() && this.$refs.bankingSection) {
      this.$store.commit('TOGGLE_TODO_SECTION', {
        name: 'connectBankingSection',
        isOpen: true,
      });
      this.$refs.bankingSection.$el.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  },
  async mounted() {
    // these query params get populated upon successful redirect from Docusign
    const { event, contractId } = this.$route.query;

    if (event === 'signing_complete' && contractId) {
      await this.$store.dispatch('refreshContractStatus', {
        contractId,
      });
    }

    await this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');

    if (this.storeBusiness.id) {
      this.loadEverything();
    } else {
      this.loadOnBusinessId = true;
    }

    this.resetToStoreState();
  },
  methods: {
    isEmpty,
    loadEverything() {
      this.loadOnBusinessId = false;
      if (this.storeUser.id && this.storeBusiness.id) {
        this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
        this.$store.dispatchApiAction('FETCH_MANUAL_READ_ONLY_ACCOUNTS');
        this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
      }
    },
    resetToStoreState() {
      this.user = cloneDeep(this.storeUser);
      this.business = cloneDeep(this.storeBusiness);
      this.$store.commit('CLEAR_REQUEST_STATUS', 'UPDATE_USER_BUSINESS');
      this.$store.commit('CLEAR_REQUEST_STATUS', 'UPDATE_USER');
    },
    async saveButtonHandler() {
      const error = await this.hasError();
      if (error) {
        return;
      }
      if (this.business.corpTypeOther) {
        this.business.corpType = `other - ${this.business.corpTypeOther}`;
      }
      await this.$store.dispatchApiAction(
        'UPDATE_USER_BUSINESS',
        this.business,
      );
      await this.$store.dispatchApiAction('UPDATE_USER', this.user);
      await this.$store.dispatchApiAction('CHECK_SIGNATORIES');
    },
    hasOffers(advance) {
      if (advance?.subtype === 'bnpl') {
        return this.hasBnplOffers(this.validInactiveBnplAdvances);
      }
      // An advance can be approved yet have no offer_options
      return advance?.offerOptions?.length || advance?.approvedAt;
    },
    hasBnplOffers(advances) {
      return !!advances.filter(
        ({ offerOptions, approvedAt }) => !!offerOptions || !!approvedAt,
      ).length;
    },
    displaySection(section) {
      return !this.section || this.section === section;
    },
    isSectionOpen(sectionToOpen) {
      return !this.sectionToOpen || this.sectionToOpen === sectionToOpen;
    },
    warnAboutUnsavedChanges() {
      if (!this.isBnplMerchant) {
        if (this.$refs.saveBar?.warnAboutUnsavedChanges()) {
          return this.resetToStoreState();
        }
        return false;
      }
      return true;
    },
  },
  // Note: This component is sometimes referenced directly in router.js and other times it's
  // rendered as a child. The `beforeRouteLeave` hook only runs when it is referenced in
  // router.js. For cases where this component is not referenced in router.js directly,
  // the `warnAboutUnsavedChanges` method is called from the parent's `beforeRouteLeave`
  beforeRouteLeave(to, from, next) {
    return next(this.warnAboutUnsavedChanges());
  },
};
</script>

<style lang="less" module>
.advance-offers-tabs {
  margin-bottom: 0;
}

.application-offers {
  margin-bottom: 60px;
}

.application-page {
  div[class^='error-message'] {
    font-size: 14px;
  }

  .title {
    margin: 0;
    font-family: Gerstner-Programm;
    font-style: normal;
    font-weight: normal;
  }

  .page-header {
    .title {
      font-size: 26px;
      line-height: 32px;
    }
  }

  .article-header {
    margin: 50px 0 40px 0;
    .title {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .notice-text {
    font-weight: 500;
    margin-bottom: 20px;
  }

  .notice-section {
    padding: 20px;
    border-radius: 3px;
    background: white;
    margin: 20px 0;

    &.crunching-numbers {
      background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)),
        url(https://media0.giphy.com/media/4heseFMvObk9q/giphy.webp?cid=3640f6095c0182b066444936553b852a);
      color: white;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

      h4 {
        color: white;
      }
    }
  }
}
</style>
