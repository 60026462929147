<template>
  <footer v-if="!oauthSignup" class="site-footer">
    <div v-if="userIsLoggedIn" class="footer-account-block">
      <h3>Hello {{ bestName }}!</h3>
      <ul class="unstyled">
        <li>
          <router-link
            v-if="userHasEverHadActiveAdvance"
            :to="{ name: 'home' }"
          >
            My Dashboard
          </router-link>
        </li>
        <li>
          <router-link :to="applicationRoute"> My Application </router-link>
        </li>
        <li>
          <router-link
            v-if="userHasEverHadActiveAdvance"
            :to="{ name: 'payments' }"
          >
            Payments
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'vendors' }"> Vendors </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'account-settings' }">
            Account
          </router-link>
        </li>
      </ul>
    </div>
    <div v-else class="footer-blurb-block">
      <p>
        Clearco provides growth capital for web-enabled businesses using actual
        business data instead of a personal credit score.
      </p>
      <router-link :to="{ name: 'self-serve-capital-signup' }">
        <ui-button secondary text="Apply now" />
      </router-link>
    </div>
    <div class="footer-contact-block">
      <h3>{{ userIsLoggedIn ? 'Need help?' : 'Questions?' }}</h3>
      <ul class="unstyled">
        <li>
          <a
            class="link-with-icon"
            :href="`mailto:${supportEmail}`"
            alt="support email"
            target="_blank"
          >
            <icon name="info-circle" />&nbsp;{{ supportEmail }}</a
          >
        </li>
        <li>
          <a class="link-with-icon" :href="contactDetails.link" target="_blank">
            <icon name="phone" />&nbsp;{{ contactDetails.number }}</a
          >
        </li>
      </ul>
    </div>
    <nav class="footer-nav">
      <h3>Contents</h3>
      <ul class="unstyled">
        <li>
          <a :href="addLocaleParam(clearbancURL)"> Home </a>
        </li>
        <li>
          <a
            :href="addLocaleParam(clearbancURL + '/careers')"
            target="_blank"
            rel="noopener noreferrer"
            >Careers</a
          >
        </li>
        <li>
          <a :href="addLocaleParam(clearbancURL + '/faq')"> FAQ </a>
        </li>
        <li>
          <a :href="addLocaleParam(clearbancURL + '/partners')"> Partners </a>
        </li>
        <li>
          <a
            :href="addLocaleParam('https://blog.clearbanc.com/')"
            target="_blank"
            rel="noopener noreferrer"
            >Blog</a
          >
        </li>
        <li v-if="!userIsLoggedIn">
          <router-link :to="{ name: 'login' }"> Log in </router-link>
        </li>
      </ul>
    </nav>
    <div class="footer-appstore">
      <h3>Get the app</h3>
      <a
        href="https://apps.apple.com/ca/app/clearbanc-mobile/id1478026695"
        aria-label="app store download link"
      >
        <svg
          class="appstore-svg"
          v-html="require('@/assets/logos/download-on-the-app-store.svg')"
        />
      </a>
    </div>
    <div class="footer-social-block">
      <h3>Connect</h3>
      <ul class="social-links">
        <li class="social-link">
          <a
            class="icon-wrap"
            href="https://facebook.com/getclearco"
            target="_blank"
            aria-label="facebook share icon"
          >
            <icon name="brands/facebook-f"
          /></a>
        </li>
        <li class="social-link">
          <a
            class="icon-wrap"
            href="https://twitter.com/getclearco"
            target="_blank"
            aria-label="twitter share icon"
          >
            <icon name="brands/twitter"
          /></a>
        </li>
        <li class="social-link">
          <a
            class="icon-wrap"
            :href="addLocaleParam('https://blog.clearbanc.com/')"
            target="_blank"
            aria-label="blog share icon"
          >
            <icon name="brands/medium-m"
          /></a>
        </li>
        <li class="social-link">
          <a
            class="icon-wrap"
            href="https://ca.linkedin.com/company/getclearco"
            target="_blank"
            aria-label="linkedin share icon"
          >
            <icon name="brands/linkedin-in"
          /></a>
        </li>
      </ul>
    </div>
    <div class="legal">
      <div class="copyright">&copy; {{ new Date().getFullYear() }} Clearco</div>
      <ul class="unstyled">
        <li>
          <a :href="addLocaleParam(clearbancURL + '/terms')">
            Terms of service
          </a>
        </li>
        <li>
          <a :href="addLocaleParam(clearbancURL + '/privacy')">
            Privacy policy
          </a>
        </li>
        <li>
          <a :href="addLocaleParam(clearbancURL + '/cookie-policy')">
            Cookie policy
          </a>
        </li>
        <li>
          <a
            :href="addLocaleParam(clearbancURL + '/imprint')"
            @click="trackImprintClick($route)"
          >
            {{ $t('common.imprint') }}
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { UiButton } from 'ui-components';
import { mapGetters } from 'vuex';
import addLocaleParam from '@/utils/add-locale-param';
import { trackImprintClick } from '@/data/wayfinding';

export default {
  components: {
    UiButton,
  },
  computed: {
    clearbancURL() {
      return process.env.CLEARCOM_URL;
    },
    ...mapGetters([
      'applicationRoute',
      'userIsLoggedIn',
      'bestName',
      'userHasEverHadActiveAdvance',
      'oauthSignup',
      'businessCorpCountry',
      'isUKBusiness',
      'contactDetails',
    ]),
    supportEmail() {
      return this.userIsLoggedIn ? 'support@clear.co' : 'info@clear.co';
    },
    isLoggedInUKBusiness() {
      return this.userIsLoggedIn && this.isUKBusiness;
    },
  },
  methods: {
    addLocaleParam,
    trackImprintClick,
  },
};
</script>

<style lang="less">
.site-footer {
  background: white;
  color: black;

  @media @mq-small-only {
    padding: 20px 10px 0;
  }

  @media @mq-medium {
    padding: 30px 15px 0;
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 3fr 3fr 3fr 4fr;
    grid-gap: 1rem;
  }

  .button {
    font-size: 13px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  li > a {
    color: black;
    text-decoration: none;

    &:hover {
      color: @cta-blue;
      transition: 0.2s all;
    }
  }

  h3 {
    margin-top: 0;
    font-size: 20px;
    line-height: 1.4em;
    margin-bottom: 0.5rem;
    color: currentColor;
  }

  .footer-social-block {
    grid-column: 1;
    grid-row: 2;

    @media @mq-small-only {
      display: none;
    }
  }

  .footer-contact-block {
    grid-column: 1;
    grid-row: 1;

    a {
      display: inline-block;
      margin-bottom: 8px;
    }

    @media @mq-small-only {
      float: left;
      padding-right: 30px;
      min-width: 50%;
    }
  }

  .footer-blurb-block,
  .footer-account-block {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    p {
      margin-top: 0;
    }

    @media @mq-small-only {
      padding-bottom: 20px;

      .button {
        width: 100%;
      }
    }
  }

  .footer-nav {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;

    @media @mq-small-only {
      float: left;
      padding-left: 15px;
      padding-bottom: 20px;
    }
  }
  .footer-appstore {
    grid-column: 4;
    grid-row: 1;
    .appstore-svg {
      height: 50px;
      width: 150px;
    }
    @media @mq-small-only {
      float: left;
      padding-right: 30px;
      min-width: 60%;
    }
  }

  .legal {
    grid-column: span 3;
    grid-row: 3;
    border-top: 1px solid fade(#fff, 30);
    display: flex;
    line-height: 1.4em;
    padding: 0.5em 0;

    @media @mq-small-only {
      font-size: 10px;
      margin-top: 20px;
      clear: both;
    }

    @media @mq-medium {
      font-size: 12px;
    }

    li {
      display: inline-block;
    }

    a {
      padding-left: 1rem;
    }

    .copyright {
      margin-right: auto;
    }
  }

  p.small {
    font-size: 11px;
  }
}

// Social links
.social-links {
  .unstyled-list();
}

.social-link {
  line-height: 36px;
  display: inline-block;
  margin-right: 10px;

  .icon-wrap {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    color: white;
    background: #333;
  }

  .icon {
    width: 100%;
    height: 100%;
    padding: 9px;
  }

  .label {
    display: none;
  }

  &:hover {
    .icon-wrap {
      background: @cta-blue;
      color: white;
    }
  }
}
</style>
