import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  ...buildApiActions({
    POST_PLAID_PROXY: {
      action: (ctx, payload) => {
        return {
          method: 'post',
          url: `/plaid-proxy`,
          params: { ...payload, country_codes: ['CA', 'US', 'GB'] },
          returnResponse: true,
        };
      },
    },
  }),
};
