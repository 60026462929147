<template>
  <Modal
    :visible="true"
    :close-on-escape="false"
    :modal="true"
    :closable="false"
    :show-header="false"
  >
    <div :class="$style.wrapper">
      <h2>Whoops!</h2>
      <p>
        {{ errorMessage }}
      </p>
      <p>
        Please <router-link to="/logout">Logout</router-link> in order to
        reattempt login or you will be redirected shortly.
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';

export default {
  components: {
    Modal,
  },
  props: {
    errorMessage: {
      type: String,
      default: 'An unexpected error occurred.',
    },
  },
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: 'logout' });
    }, 10000);
  },
};
</script>

<style lang="less" module>
.wrapper {
  padding: 20px 20px 0;
  color: #000;
  h2 {
    font-size: 1.75rem;
    font-weight: 500;
  }
}
</style>
